import styles from './style.module.css';

import {useEffect, useMemo, useState} from 'react';
import {Navigate, NavLink, Route, Routes} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Submenu from '../../component/Submenu';
import CardDoctor from './Doctor';
import CardDiary from './Diary';
import CardAnalysis from './Analysis';
import CardStudy from './Study';
import CardDrug from './Drug';
import CardAnamnesis from './Anamnesis';

import {useAppDispatch, useAppSelector} from '../../module/redux/hooks';
import {showSnackbar} from '../../component/Snackbar/slice';
import {CardType, fetchCategories, fetchRecords, saveRecord, deleteRecords, resetCard, setType} from './slice';
import Modal from '../../component/Modal';
import SortTable from '../../component/SortTable';
import {SNACKBAR_TYPE_DANGER, SNACKBAR_TYPE_SUCCESS} from '../../component/Snackbar';
import Page from '../../component/Page';
import Panel from '../../component/Panel';
import PanelAction from '../../component/PanelAction';
import CardAnalysisForm from '../../component/form/CardAnalysis';
import CardDiaryForm from '../../component/form/CardDiary';
import CardDoctorForm from '../../component/form/CardDoctor';

const Card = () => {
    const { t } = useTranslation();

    return (
        <>
            <Submenu title={t('data_entry')}>
                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'anamnesis'}>{t('anamnesis')}</NavLink>

                <hr/>

                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'doctor'}>{t('visit_a_doctor')}</NavLink>

                <hr/>

                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'diary'}>{t('diary')}</NavLink>
                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'drug'}>{t('medication')}</NavLink>

                <hr/>

                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'analysis'}>{t('analyses')}</NavLink>
                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'study'}>{t('diagnostic_tests')}</NavLink>
            </Submenu>

            <Routes>
                <Route index
                    element={<Navigate to="diary" replace />}
                />
                <Route path="doctor" element={<CardDoctor />} />
                <Route path="anamnesis" element={<CardAnamnesis />} />
                <Route path="diary" element={<CardDiary />} />
                <Route path="analysis" element={<CardAnalysis />} />
                <Route path="study" element={<CardStudy />} />
                <Route path="drug" element={<CardDrug />} />
            </Routes>
        </>
    );
};

export default Card;


type Props = {
    type: CardType,
    columns: FixType,
    modalSize?: 'large' | 'extra-large'
    filteredRecords?: CommonRecord[],
};

const formComponents = {
    analysis: CardAnalysisForm,
    diary: CardDiaryForm,
    doctor: CardDoctorForm
};

export const CommonCard = ({type, columns, modalSize, filteredRecords}: Props) => {
    const { t} = useTranslation();
    const dispatch = useAppDispatch();
    const {loading, records} = useAppSelector(state => state.cardPage);

    const [openedRecord, setOpenedRecord] = useState(undefined);
    const [selectedRecords, setSelectedRecords] = useState<CommonRecord[]>([]);

    async function onSaveRecord(record: CommonRecord, delFiles?: RecordAttachment[], newFiles?: RecordAttachment[]): Promise<boolean> {
        try {
            await dispatch(saveRecord({record, delFiles, newFiles}));

            setOpenedRecord(undefined);

            dispatch(showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t(`${record.id ? 'messages.success.update' : 'messages.success.create'}`)}));

            return true;
        } catch (e) {
            console.log(e);
            dispatch(showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));

            return false;
        }
    }

    async function deleteSelectedRecords(): Promise<void> {
        try {
            const ids: number[] = selectedRecords.reduce((a: number[], i) => i.id ? [...a, i.id] : a, []);
            await dispatch(deleteRecords(ids));

            dispatch(showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t('messages.success.delete')}));
        } catch (e) {
            console.log(e);
            dispatch(showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));
        }
    }

    useEffect(() => {
        (async () => {
            dispatch(setType(type));
            await dispatch(fetchCategories());
            await dispatch(fetchRecords());
        })();

        return () => {
            dispatch(resetCard());
        };
    }, []);

    const CardForm = formComponents[type];

    const title = useMemo(() => {
        if (type === 'doctor') return 'visit_a_doctor';
        if (type === 'analysis') return 'analyses';
        return type;
    }, [type]);

    return <Page title={t('entering_values_medical_card')}>
        <Panel title={t(title)}
            loading={loading}
            actions={<>
                <PanelAction type={'add'} onClick={() => setOpenedRecord({} as FixType)} />
                <PanelAction type={'delete'} disabled={selectedRecords.length === 0}
                    onClick={() => confirm(t('confirm_delete')) && deleteSelectedRecords()}
                />
            </>}
        >
            <SortTable columns={columns}
                data={(filteredRecords ?? records) as FixType}
                onRowClick={setOpenedRecord}
                onRowSelect={setSelectedRecords}
                selectable={true}
            />
        </Panel>

        <Modal isOpen={!!openedRecord}
            onClose={() => setOpenedRecord(undefined)}
            title={t(title)}
            size={modalSize}
        >
            <CardForm record={openedRecord}
                onCancel={() => setOpenedRecord(undefined)}
                onSubmit={onSaveRecord}
            />
        </Modal>
    </Page>;
};
