import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import ApiClient from '../../../service/apiClient';
import {clone, sortBy} from '../../../service/util';

interface SliceType {
    loading: boolean;
    from: string;
    to: string;
    diary: WidgetDiaryData[];
    categories: KmrCategory[];
    mode: WidgetPlotType;
    activeCategories: {[key: number]: boolean};
    filledCategories: {[key: number]: boolean};
}

const initialState: SliceType = {
    loading: false,
    from: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
    diary: [],
    categories: [],
    mode: 'text+lines+markers',
    activeCategories: {},
    filledCategories: {},
};

const CATEGORY_PRESSURE_ID = 7;
const CATEGORY_PULSE_ID = 8;

const slice = createSlice({
    name: 'diaryWidget',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setDates: (state, action: PayloadAction<{from: string, to: string}>) => {
            state.from = action.payload.from;
            state.to = action.payload.to;
            state.mode = dayjs(state.to).diff(state.from, 'd') < 31 ? 'text+lines+markers' : 'box';
        },
        setDiary: (state, action: PayloadAction<WidgetDiaryData[]>) => {
            state.diary = sortBy(action.payload, 'datetime_at');
        },
        setCategories: (state, action: PayloadAction<KmrCategory[]>) => {
            state.categories = action.payload;
        },
        toggleCategory: (state, action: PayloadAction<number>) => {
            const id = action.payload;
            const tmp = clone(state.activeCategories);

            if (tmp[id]) {
                delete tmp[id];
            } else {
                tmp[id] = true;
            }

            state.activeCategories = tmp;
        },
        setFilledCategories: (state) => {
            const tmp = clone(state.categories)
                .map((c) => {
                    c.features.push(...c.children.map((ch) => ch.features).flat());
                    return c;
                });

            state.filledCategories = tmp.filter(
                (c: KmrCategory) => c.features.some(
                    (f) => state.diary.some(
                        (d: DiaryRecord) => d.values.some(
                            (v: RecordValue) => v.feature_id === f.id)
                    )
                )
            ).reduce(
                (a, i) => ({...a, [i.id]: true}), {}
            );

            for (const categoryId in state.filledCategories) {
                if ([CATEGORY_PRESSURE_ID, CATEGORY_PULSE_ID].includes(Number(categoryId))) {
                    state.activeCategories[categoryId] = state.filledCategories[categoryId];
                }
            }
        },
    },
});

export const actions = slice.actions;

export const init = (from: string, to: string) => (dispatch: FixType) => {
    dispatch(actions.setLoading(true));

    const data = ApiClient.getDiaryWidgetData(from, to)
        .then((response) => dispatch(actions.setDiary(response)))
        .catch(() => dispatch(actions.setDiary([])));

    const cats = ApiClient.getDiaries()
        .then((response) => dispatch(actions.setCategories(response[0].children)))
        .catch(() => dispatch(actions.setCategories([])));

    Promise.all([data, cats]).then(() => dispatch(actions.setLoading(false)));
};

export default slice.reducer;