import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

import {getLastWeek, getLastMonth, getLast6Months, getLastYear, getLast5Years} from '../../service/util';
import Input from '../Input';
import Button from '../Button';


type ThisComponentProps = {
    type: string,
    range: string[],
    onChangeRange(from: string, to: string): void,
    /** deprecated, use quickRangeButtons */
    hasWeekMonthOptions?: boolean,
    quickRangeButtons?: WidgetPeriod[]
}

const DateRangePicker = ({type, range, onChangeRange, hasWeekMonthOptions, quickRangeButtons} : ThisComponentProps) => {
    const { t } = useTranslation();
    const [from, to] = range;
    const [period, setPeriod] = useState<WidgetPeriod>('custom');

    if (hasWeekMonthOptions && type !== 'month') quickRangeButtons = ['week', 'month', 'six_months'];

    const onChangePeriod = (p: WidgetPeriod) => {
        let [newFrom, newTo] = [from, to];
        switch (p) {
            case 'week':       { [newFrom, newTo] = getLastWeek(); break; }
            case 'month':      { [newFrom, newTo] = getLastMonth(); break; }
            case 'six_months': { [newFrom, newTo] = getLast6Months(); break; }
            case 'year':       { [newFrom, newTo] = getLastYear(); break; }
            case 'five_years': { [newFrom, newTo] = getLast5Years(); break; }
        }

        onChangeRange(newFrom, newTo);
    };

    useEffect(() => {
        let newPeriod: WidgetPeriod = 'custom';


        if (dayjs().isSame(to, 'day')) {
            const days = dayjs(to).diff(from, 'day');
            const months = dayjs(to).diff(from, 'month', true);
            const years = dayjs(to).diff(from, 'year', true);

            if (days === 7) newPeriod = 'week';
            else if (months === 1) newPeriod = 'month';
            else if (months === 6) newPeriod = 'six_months';
            else if (years  === 1) newPeriod = 'year';
            else if (years  === 5) newPeriod = 'five_years';
        }

        setPeriod(newPeriod);
    }, [from, to]);

    return (<>
        <div>
            <Input>
                <input
                    type={type}
                    required={true}
                    placeholder={t('yyyy_mm')}
                    value={from}
                    onChange={(e) => onChangeRange(e.target.value, to)}
                />
            </Input>

            <span>-</span>

            <Input>
                <input
                    type={type}
                    required={true}
                    placeholder={t('yyyy_mm')}
                    value={to}
                    onChange={(e) => onChangeRange(from, e.target.value)}
                />
            </Input>
        </div>
        {quickRangeButtons?.map((p, i) =>
            <Button
                key={i}
                isSelected={period === p}
                onClick={() => onChangePeriod(p)}
                type={'button'}
            >{t(`quick_button.${p}`)}</Button>
        )}
    </>);
};

export default DateRangePicker;