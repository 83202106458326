import styles from '../../module/login/styles.module.css';

import {useEffect, useRef, useState} from 'react';
import Page from '../../component/Page';
import Panel from '../../component/Panel';
import { useAuth } from '../../module/auth/';
import {useNavigate} from 'react-router-dom';
import Input from '../../component/Input';
import Button from '../../component/Button';
import apiClient from '../../service/apiClient';
import LoginSidePanel from '../../component/LoginSidePanel';
import {useTranslation} from 'react-i18next';
import {actions} from '../../component/Snackbar/slice';
import {SNACKBAR_TYPE_DANGER} from '../../component/Snackbar';
import {useAppDispatch} from '../../module/redux/hooks';

const Register = () => {
    const { setAuth } = useAuth();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const loginRef = useRef<HTMLInputElement>(null);

    useEffect(() => loginRef.current?.focus(), []);

    const [login, setLogin] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleSubmit = async (e: FixType) => {
        e.preventDefault();

        try {
            const user = await apiClient.register(login, email, password);

            setAuth({
                user: null,
                roles: [user.role],
                accessToken: user.token,
            });

            setLogin('');
            setEmail('');
            setPassword('');
            navigate('/', { replace: true });
        } catch (err) {
            console.log(err);
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));
        }
    };

    return (
        <Page title={''}>
            <div className={styles.container}>
                <div className={styles.row}>
                    <LoginSidePanel />
                    <Panel title={t('registration')} isForm={true}>
                        <form onSubmit={handleSubmit}>
                            <Input className={`${styles.inputWrap} ${styles.inputWrapLogin}`}
                                label={t('username')}>
                                <input
                                    type="text"
                                    required
                                    ref={loginRef}
                                    onChange={(e) => setLogin(e.target.value)}
                                />
                            </Input>
                            <Input className={`${styles.inputWrap} ${styles.inputWrapEmail}`} label={t('email')}>
                                <input
                                    type="email"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Input>
                            <Input className={`${styles.inputWrap} ${styles.inputWrapPassword}`} label={t('password')}>
                                <input
                                    type="password"
                                    required={true}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Input>

                            <div className={`${styles.actions}`}>
                                <Button type="submit">{t('start')}</Button>
                            </div>
                        </form>
                    </Panel>
                </div>
            </div>
        </Page>
    );
};

export default Register;
