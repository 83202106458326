import styles from './style.module.css';

import {NavLink} from 'react-router-dom';
import {useAuth} from '../../module/auth';
import Localization from '../Localization';

const Menu = () => {
    const {auth} = useAuth();

    return (
        <aside className={styles.menu}>
            <a className={styles.logo} href="/" />

            {auth && <>
                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''} ${styles.iconHome}`} to="/" />

                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''} ${styles.iconVisit}`} to="/card" />

                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''} ${styles.iconDynamic}`} to="/dynamic" />

                <hr/>

                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''} ${styles.iconDoctor}`} to="/my-office" />

                <hr/>

                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''} ${styles.iconGear}`} to="/settings" />
            </>}

            {!auth && <>
                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''} ${styles.iconUser}`} to="/login" />

                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''} ${styles.iconUser}`} to="/register" />
            </>}

            <Localization />
        </aside>
    );
};

export default Menu;
