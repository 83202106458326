import styles from './style.module.css';

import Page from '../../../component/Page';
import Input, {InputStyles} from '../../../component/Input';
import Button from '../../../component/Button';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import apiClient from '../../../service/apiClient';
import {useAppDispatch} from '../../../module/redux/hooks';
import {actions as userActions} from './slice';
import {SNACKBAR_TYPE_DANGER, SNACKBAR_TYPE_SUCCESS} from '../../../component/Snackbar';
import {actions as snackbarActions} from '../../../component/Snackbar/slice';
import {Education} from '../../../enums';
import dayjs from 'dayjs';

const SettingsProfile = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<KmrUser>({
        id: '',
        code: '',
        cover: '',
        dob_year: 0,
        first_name: '',
        gender: '',
        last_name: '',
        country: '',
        state: '',
        city: '',
        email: '',
        education: '',
        smoking: false
    });

    const fetchUser = async () => {
        setLoading(true);

        try {
            const user = await apiClient.getUser();

            setUser({...user, dob_year: dayjs().year() - user.dob_year});
        } catch (err) {
            console.log(err);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchUser();
    }, []);

    const handleSubmit = async (e: FixType) => {
        e.preventDefault();

        setLoading(true);

        const data = {...user, dob_year: dayjs().year() - user.dob_year};

        try {
            await apiClient.updateUser(data);

            dispatch(userActions.setUser(data));
            dispatch(snackbarActions.showSnackbar({
                type: SNACKBAR_TYPE_SUCCESS,
                message: t('messages.success.profile_update'),
            }));
        } catch (err) {
            console.log(err);
            dispatch(snackbarActions.showSnackbar({
                type: SNACKBAR_TYPE_DANGER,
                message: t('messages.error'),
            }));
        }

        setLoading(false);
    };

    return (
        <Page title={t('personal_data')}>
            <div className={`${styles.panel} ${loading ? styles.loading : ''}`}>
                <form onSubmit={handleSubmit}>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Input label={t('email')}>
                                <input
                                    type={'email'}
                                    value={user.email}
                                    readOnly
                                    disabled
                                />
                            </Input>

                            <Input className={styles.inputWrap} label={t('name')}>
                                <input
                                    type={'text'}
                                    value={user.first_name || ''}
                                    onChange={(e) => setUser({...user, first_name: e.target.value})}
                                />
                            </Input>

                            <div className={styles.inlineInputs}>
                                <Input className={`${styles.inputWrap} ${styles.inlineInputWrap}`} label={t('gender')}>
                                    <select
                                        className={InputStyles.input}
                                        value={user.gender || ''}
                                        onChange={(e) => setUser({...user, gender: e.target.value})}
                                    >
                                        <option value=""/>
                                        <option value="male">{t('male')}</option>
                                        <option value="female">{t('female')}</option>
                                    </select>
                                </Input>

                                <Input className={`${styles.inputWrap} ${styles.inlineInputWrap}`} label={t('age')}>
                                    <input
                                        type={'number'}
                                        value={user.dob_year || ''}
                                        onChange={(e) => setUser({...user, dob_year: +e.target.value})}
                                    />
                                </Input>
                            </div>

                            <Input className={styles.inputWrap} label={t('education')}>
                                <select
                                    className={InputStyles.input}
                                    value={user.education || ''}
                                    onChange={(e) => setUser({...user, education: (e.target.value as Education)})}
                                >
                                    <option value=""/>
                                    {Object.values(Education).map((education: Education) => (<option key={education} value={education}>{t(education)}</option>))}
                                </select>
                            </Input>

                            <Input className={styles.inputWrap} label={t('smoking')}>
                                <select
                                    className={InputStyles.input}
                                    value={user.smoking?.toString()}
                                    onChange={(e) => setUser({...user, smoking: e.target.value === 'true'})}
                                >
                                    <option value={'true'}>{t('yes')}</option>
                                    <option value={'false'}>{t('no')}</option>
                                </select>
                            </Input>
                        </div>
                        <div className={styles.col}>
                            <Input className={styles.inputWrap} label={t('country')}>
                                <input
                                    type={'text'}
                                    value={user.country || ''}
                                    onChange={(e) => setUser({...user, country: e.target.value})}
                                />
                            </Input>

                            <Input className={styles.inputWrap} label={t('city')}>
                                <input
                                    type={'text'}
                                    value={user.city || ''}
                                    onChange={(e) => setUser({...user, city: e.target.value})}
                                />
                            </Input>
                        </div>
                    </div>

                    <div className={styles.actions}>
                        <Button type="submit">{t('save_changes')}</Button>
                    </div>
                </form>
            </div>
        </Page>
    );
};

export default SettingsProfile;
