import styles from './style.module.css';

import Page from '../../../component/Page';
import {useTranslation} from 'react-i18next';
import Input from '../../../component/Input';
import Button from '../../../component/Button';
import {useState} from 'react';
import apiClient from '../../../service/apiClient';
import {actions} from '../../../component/Snackbar/slice';
import {SNACKBAR_TYPE_WARNING} from '../../../component/Snackbar';
import {useAppDispatch} from '../../../module/redux/hooks';

interface SecurityFormData {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

const initialFormData = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
};

const SettingsSecurity = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<SecurityFormData>(initialFormData);

    const handleSubmit = async (e: FixType) => {
        e.preventDefault();

        if (formData.oldPassword && (formData.newPassword === formData.confirmNewPassword)) {
            setLoading(true);

            try {
                const response = await apiClient.changePassword(formData.oldPassword, formData.newPassword);

                if (response.complete) await apiClient.logout();
            } catch (err) {
                console.log(err);

                dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_WARNING, message: t('data_correct')}));
            }

            setLoading(false);
        }
    };

    return (
        <Page title={t('security')}>
            <div className={`${styles.panel} ${loading ? styles.loading : ''}`}>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <Input className={styles.inputWrap} label={t('current_password')}>
                        <input
                            required
                            type={'password'}
                            name={'oldPassword'}
                            value={formData.oldPassword}
                            placeholder={'**********************'}
                            onChange={(e) => setFormData({...formData, oldPassword: e.target.value})}
                        />
                    </Input>

                    <Input className={styles.inputWrap} label={t('new_password')}>
                        <input
                            required
                            type={'password'}
                            name={'newPassword'}
                            placeholder={'**********************'}
                            value={formData.newPassword}
                            onChange={(e) => setFormData({...formData, newPassword: e.target.value})}
                        />
                    </Input>

                    <Input className={styles.inputWrap} label={t('confirm_new_password')}>
                        <input
                            required
                            className={formData.newPassword && formData.newPassword !== formData.confirmNewPassword ? styles.error : ''}
                            type={'password'}
                            name={'confirmNewPassword'}
                            placeholder={'**********************'}
                            value={formData.confirmNewPassword}
                            onChange={(e) => setFormData({...formData, confirmNewPassword: e.target.value})}
                        />
                    </Input>

                    <div className={styles.actions}>
                        <Button type="submit">{t('save_changes')}</Button>
                    </div>
                </form>
            </div>
        </Page>
    );
};

export default SettingsSecurity;
