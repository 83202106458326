import styles from './style.module.css';

import {useTranslation} from 'react-i18next';
import Button from '../Button';

type ThisComponentProps = {
    title: string,
    value: number
}

const risks = ['low', 'below_average', 'average', 'above_average', 'high'];

const HomeRisk = ({title, value} : ThisComponentProps) => {
    const { t } = useTranslation();
    /** Convert range [0, 1] to one of [0, 1, 2, 3, 4] and to one of [risks] */
    const risk = risks[Math.min(Math.trunc(value * risks.length), risks.length - 1)];

    return (<>
        <div className={styles.title}>{t(title) + ':'}</div>

        <div className={styles.scaleWrap}>
            <div>
                <div className={styles.whiteLine}></div>
                <div className={styles.whiteLine}></div>
                <div className={styles.whiteLine}></div>
                <div className={styles.whiteLine}></div>
            </div>

            <div className={`${styles.risk} ${styles[risk]}`}>
                <div className={styles.arrow}></div>
                <div className={styles.text}>{t(`home.risks.${risk}`)}</div>
            </div>
        </div>

        <Button type={'submit'}>{t('home.risks.why')}</Button>
    </>);
};

export default HomeRisk;
