import styles from './style.module.css';

import useAuth from '../../module/auth/useAuth';
import apiClient from '../../service/apiClient';
import {useAppSelector} from '../../module/redux/hooks';
import {useTranslation} from 'react-i18next';

const UserPanel = () => {
    const { auth } = useAuth();
    const { t } = useTranslation();
    const state = useAppSelector((state) => state.user);

    return !auth ? null : (
        <div className={styles.wrap}>
            <div className={styles.card}>
                <div className={`${styles.icon} ${styles.iconUser}`} />
                <div>
                    <b>{t('welcome_user', { user: state.user.first_name || 'Anon'})}</b>
                </div>
            </div>
            <div className={`${styles.card} ${styles.cardLogout}`} onClick={() => apiClient.logout()}>
                <div>
                    <b>{t('logout')}</b>
                </div>
                <div className={`${styles.icon} ${styles.iconLogout}`} />
            </div>
        </div>
    );
};

export default UserPanel;
