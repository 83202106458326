import styles from './style.module.css';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import apiClient from '../../service/apiClient';
import { NavLink, useParams } from 'react-router-dom';
import Plot from 'react-plotly.js';
import { commonPlotAxis } from '../../service/plotChunks';
import { getFeelingColorByValue, getFeelingGlyphByValue } from '../../service/plotUtils';

dayjs.extend(customParseFormat);

const Report = () => {
    const { t } = useTranslation();
    const { uri } = useParams();

    const [isReportActive, setIsReportActive] = useState<boolean>(false);
    const [reportError, setReportError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [reportData, setReportData] = useState<KmrReportData>();

    const init = async () => {
        if (uri) {
            setLoading(true);

            try {
                const data = await apiClient.getReport(uri);

                setIsReportActive(true);
                setReportData({
                    ...data,
                    start_date: dayjs(data.start_date).format('DD.MM.YYYY'),
                    end_date: dayjs(data.end_date).format('DD.MM.YYYY')
                });
            } catch (err: FixType) {
                console.log(err);
                if (err?.response?.status === 403) {
                    setReportError('link_expired');
                } else if (err?.response?.status === 500) {
                    setReportError('link_invalid');
                }
            }

            setLoading(false);
        }
    };

    const transformReportToArray = (): FixType[] => {
        const data: FixType = {};

        /** Union data to one Object */
        reportData?.pressure.forEach(({record_at, diastolic, systolic}) => {
            const rec_at = dayjs(record_at);
            const [date, time] = [rec_at.format('DD.MM.YYYY'), rec_at.format('HH:mm')];

            if (!data[date]) data[date] = {};
            if (!data[date][time]) data[date][time] = [];

            data[date][time].push({
                diastolic,
                systolic
            });
        });

        reportData?.pulse.forEach(({record_at, pulse, arrhythmia}) => {
            const rec_at = dayjs(record_at);
            const [date, time] = [rec_at.format('DD.MM.YYYY'), rec_at.format('HH:mm')];

            if (!data[date]) data[date] = {};
            if (!data[date][time]) data[date][time] = [];

            data[date][time].push({pulse, arrhythmia});
        });

        reportData?.mood.forEach(({record_at, value}) => {
            const rec_at = dayjs(record_at);
            const [date, time] = [rec_at.format('DD.MM.YYYY'), rec_at.format('HH:mm')];

            if (!data[date]) data[date] = {};
            if (!data[date][time]) data[date][time] = [];

            data[date][time].push({mood: value, moodSymbol: getFeelingGlyphByValue(+value), moodColor: getFeelingColorByValue(+value)});
        });

        /** Convert Object to Array and sort by date/time
         * Object {date: {time: [{pulse}, {mood}, ...]}}
         * to
         * Array [[date, [time, {pulse, mood, ...}]]]
         */
        return Object.entries(data).sort((a, b) => dayjs(b[0]).diff(a[0], 'day'))
            .map((d: FixType) =>
                [d[0], Object.entries(d[1])
                    .sort((c, d) => dayjs(d[0], 'HH:mm').diff(dayjs(c[0], 'HH:mm'), 'minute'))
                    .map((t: FixType[]) =>
                        [t[0], t[1]
                            .reduce((prev: FixType, curr: FixType) => ({...prev, ...curr}), {})
                        ])
                ]);
    };

    useEffect(() => {
        init();
    }, [uri]);

    const tbody = useMemo(() => {
        if (reportData) {
            const tableData = transformReportToArray();
            return tableData.map(([date, rows]: FixType) =>
                rows.map(([time, cells]: FixType, i: number) => {
                    return (
                        <tr key={date + time}>
                            {i === 0 ? <td rowSpan={rows.length}>{date}</td> : null}
                            <td>{time}</td>
                            <td>{`${cells.systolic || '-'}/${cells.diastolic || '-'}`}</td>
                            <td>{cells.pulse || '-'}</td>
                            <td className={cells.arrhythmia ? styles.bgRed : ''}>{cells.arrhythmia ? t('arrhythmia') : '-'}</td>
                            <td className={styles.feeling} style={{color: cells.moodColor}}>{cells.moodSymbol || '-'}</td>
                        </tr>
                    );
                })
            );
        }

        return [];
    }, [reportData]);

    const xRange = useMemo(() => [
        dayjs(reportData?.start_date, 'DD.MM.YYYY')
            .startOf('day').add(-3, 'h')
            .format('YYYY-MM-DD HH:mm'),
        dayjs(reportData?.end_date, 'DD.MM.YYYY')
            .endOf('day').add(2, 'h')
            .format('YYYY-MM-DD HH:mm')
    ], [reportData]);

    const xRangeMood = useMemo(() => [
        dayjs(xRange[0]).add(-4, 'h').format('YYYY-MM-DD HH:mm'),
        xRange[1]
    ], [xRange]);

    const xRangeMedication = useMemo(() => [
        dayjs(xRange[0]).endOf('day').format(),
        dayjs(xRange[1]).startOf('day').add(40, 'm').format()
    ], [xRange]);

    const commonAxisX = useMemo(() => {
        /** count days between start_date and end_date + 2 */
        const nticks = 2 + dayjs(reportData?.end_date, 'DD.MM.YYYY').diff(
            dayjs(reportData?.start_date, 'DD.MM.YYYY'),
            'days'
        );
        return {
            type: 'date',
            autorange: false,
            range: xRange,
            fixedrange: true,
            nticks: nticks < 21 ? nticks : undefined,
            ticklabelmode: 'period',
            tickformat: nticks < 21 ? '%e' : undefined,
            tickfont: {
                color: 'rgb(90, 90, 90)',
                family: 'Roboto, sans-serif',
                size: 14
            }
        };
    }, [reportData]);

    const _drugColors: FixType = [
        ['rgba(255, 150, 128, 1)', 'rgba(219, 125, 105, .8)', 'rgba(219, 125, 105, 1)'],
        ['rgba( 91, 217, 160, 1)', 'rgba(  0, 169,  93, .8)', 'rgba(  0, 169,  93, 1)'],
        ['rgba(213, 123, 255, 1)', 'rgba(173,   0, 255, .8)', 'rgba(173,   0, 255, 1)'],
        ['rgba(101, 181, 255, 1)', 'rgba( 66, 123, 176, .8)', 'rgba( 66, 123, 176, 1)'],
        ['rgba(146, 170, 255, 1)', 'rgba(114, 105, 219, .8)', 'rgba(114, 105, 219, 1)'],
        ['rgba(249, 236, 120, 1)', 'rgba(216, 218, 105, .8)', 'rgba(216, 218, 105, 1)'],
    ];

    const _genRandomDrugColors = (): string[] => {
        const color = Array(3).fill('').map(() => Math.floor(Math.random() * 256) + '');
        return [
            `rgb(${color.join(', ')})`,
            `rgba(${color.map((c: FixType) => Math.max(0, c - 45)).join(',')}, .8)`,
            `rgb(${color.map((c: FixType) => Math.max(0, c - 45)).join(',')})`
        ];
    };

    const _getDrugColors = (d: FixType) => {
        const color = _drugColors.find((c: FixType) => c[3] === d.name);
        if (!color) {
            let i = _drugColors.findIndex((c: FixType) => c.length === 3);
            if (i === -1) {
                _drugColors.push(_genRandomDrugColors());
                i = _drugColors.length - 1;
            }
            _drugColors[i].push(d.name);
            return _drugColors[i];
        }
        return color;
    };

    const _toUpperFirstLetter = (str: string): string =>
        str.split('').map((c, i) => i === 0 ? c.toUpperCase() : c).join('');



    const pressurePulsePlotData = useMemo(() => {
        const traces: FixType[] = [];
        const shapes: FixType = [];

        if (reportData) {
            const {pressure, pulse} = reportData;

            if (pressure.length) {
                pressure.sort((a, b) => dayjs(b.record_at).diff(a.record_at, 'minute'));

                const systolic = pressure.reduce((result: FixType, data: FixType) => {
                    result.x.push(data.record_at);
                    result.y.push(data.systolic);
                    return result;
                }, {x: [], y: []});
                const diastolic = pressure.reduce((result: FixType, data: FixType) => {
                    result.x.push(data.record_at);
                    result.y.push(data.diastolic);
                    return result;
                }, {x: [], y: []});

                traces.push({
                    ...systolic,
                    mode: 'lines+markers',
                    line: {
                        shape: 'spline',
                        color: 'rgb(89, 186, 174)'
                    },
                    marker: {
                        color: 'rgb(255, 255, 255)',
                        size: 14
                    },
                    hoverinfo: 'none'
                },
                {
                    name: t('systolic'),
                    ...systolic,
                    mode: 'markers',
                    marker: {
                        color: 'rgb(89, 186, 174)',
                        size: 8.4
                    }
                },
                {
                    ...diastolic,
                    mode: 'lines+markers',
                    line: {
                        shape: 'spline',
                        color: 'rgb(133, 163, 255)'
                    },
                    marker: {
                        color: 'rgb(255, 255, 255)',
                        size: 14
                    },
                    hoverinfo: 'none'
                },
                {
                    name: t('diastolic'),
                    ...diastolic,
                    mode: 'markers',
                    marker: {
                        color: 'rgb(133, 163, 255)',
                        size: 8.4
                    }
                });
            }

            if (pulse.length) {
                pulse.sort((a, b) => dayjs(b.record_at).diff(a.record_at, 'minute'));

                const pulseData = pulse.reduce((result: FixType, data: FixType) => {
                    result.x.push(data.record_at);
                    result.y.push(data.pulse);
                    return result;
                }, {x: [], y: []});

                traces.push({
                    ...pulseData,
                    yaxis: 'y2',
                    mode: 'lines+markers',
                    line: {
                        shape: 'spline',
                        color: 'rgb(255, 99, 108)'
                    },
                    marker: {
                        color: 'rgb(255, 255, 255)',
                        size: 14
                    },
                    hoverinfo: 'none'
                },
                {
                    name: t('pulse'),
                    ...pulseData,
                    yaxis: 'y2',
                    mode: 'markers',
                    marker: {
                        color: 'rgb(255, 99, 108)',
                        size: 8.4
                    }
                });

                pulse.filter(d => d.arrhythmia).forEach((d: FixType) => {
                    shapes.push({
                        type: 'path',
                        yref: 'y2 domain',
                        path: `M${+dayjs(d.record_at)},1H${+dayjs(d.record_at)}V.975H${+dayjs(d.record_at)}Z`,
                        xsizemode: 'scaled',
                        ysizemode: 'scaled',
                        line:{
                            color: 'rgb(183, 51, 51)',
                            width: 3
                        }
                    });
                });
            }
        }

        return {traces, shapes};
    }, [reportData]);

    const drawPressurePulsePlot = useMemo(() => {
        const {traces, shapes} = pressurePulsePlotData;

        if (traces.length) {
            const yAxis = {
                titlefont: {
                    color: 'rgb(0, 0, 0)',
                    family: 'Roboto, sans-serif',
                    size: 16,
                },
                linecolor: 'rgba(135, 135, 135, .3)',
                gridcolor: 'rgba(135, 135, 135, .15)',
                ticklen: 3,
                tickcolor: 'transparent',
                tickfont: {
                    color: 'rgb(90, 90, 90)',
                    family: 'Roboto, sans-serif',
                    size: 14
                },
                fixedrange: true
            };

            const layout: FixType = {
                hovermode: 'x unified',
                dragmode: false,
                showlegend: false,
                grid: {
                    rows: 2,
                    columns: 1,
                    subplots:['xy', 'xy2'],
                    roworder: 'top to bottom'
                },
                xaxis: {
                    ...commonAxisX,
                    showgrid: false,
                    linecolor: 'rgba(135, 135, 135, .15)'
                },
                yaxis: {
                    ...commonPlotAxis(t('pressure')),
                    ...yAxis
                },
                yaxis2: {
                    ...commonPlotAxis(t('pulse_and_arrhythmia')),
                    ...yAxis
                },
                height: 400,
                margin: { t: 20, r: 0, b: 30, l: 60 },
                shapes
            };

            return <div className={styles.charts}>
                <Plot
                    data={traces} layout={layout}
                    config={{displayModeBar: false}}
                    useResizeHandler={true}
                    className={styles.chart}
                />
            </div>;
        }

        return <div style={{margin: '2.5rem 0'}}><b>{t('pulse')} / {t('pressure')} / {t('arrhythmia')}</b> - {t('no_data')}</div>;
    }, [pressurePulsePlotData]);

    const moodPlotData = useMemo(() => {
        const traces: FixType[] = [];
        const images: FixType = [];

        if (reportData?.mood.length) {
            const x: FixType[] = [];
            const y: FixType[] = [];
            const c: FixType[] = [];

            reportData?.mood.forEach(({record_at, value}) => {
                x.push(record_at);

                switch (getFeelingGlyphByValue(+value)) {
                    case 'T': y.push(-1);  break;
                    case 'B': y.push(-.5); break;
                    case 'G': y.push(.5);  break;
                    case 'E': y.push(1);   break;
                    case 'N':
                    default: y.push(0);
                }

                c.push(getFeelingColorByValue(+value));
            });

            traces.push({
                x, y,
                mode: 'markers',
                marker: {
                    size: 13,
                    symbol: 'line-ns',
                    line: {
                        width: 5,
                        color: c
                    },
                    color: c
                }
            });

            images.push({
                source: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTczIiB2aWV3Qm94PSIwIDAgMTUgMTczIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHJlY3Qgd2lkdGg9IjE1IiBoZWlnaHQ9IjE3MyIgcng9IjcuNSIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzJfMikiLz4KICAgIDxyZWN0IHk9IjEzNSIgd2lkdGg9IjE1IiBoZWlnaHQ9IjEuNjciIGZpbGw9IndoaXRlIi8+CiAgICA8cmVjdCB5PSIxMDEuNSIgd2lkdGg9IjE1IiBoZWlnaHQ9IjEuNjciIGZpbGw9IndoaXRlIi8+CiAgICA8cmVjdCB5PSI2OC41IiB3aWR0aD0iMTUiIGhlaWdodD0iMS42NyIgZmlsbD0id2hpdGUiLz4KICAgIDxyZWN0IHk9IjM1IiB3aWR0aD0iMTUiIGhlaWdodD0iMS42NyIgZmlsbD0id2hpdGUiLz4KICAgIDxkZWZzPgogICAgICAgIDxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl8yXzIiIHgxPSI3LjIzMTk1IiB5MT0iMTczIiB4Mj0iNy4yMzE5NSIgeTI9IjEuNDQwNDFlLTEwIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgICAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiNGRjAwMDAiLz4KICAgICAgICAgICAgPHN0b3Agb2Zmc2V0PSIwLjUyNjA0MiIgc3RvcC1jb2xvcj0iI0U2RTkzOSIvPgogICAgICAgICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMwMEEzNUUiLz4KICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgPC9kZWZzPgo8L3N2Zz4K',
                xref: 'paper',
                yref: 'domain',
                x: 0,
                y: .5,
                sizex: 0.07,
                sizey: 1,
                xanchor: 'center',
                yanchor: 'middle'
            });
        }

        return {traces, images};
    }, [reportData]);

    const drawMoodPlot = useMemo(() => {
        const {traces, images} = moodPlotData;

        if (traces.length) {
            const layout: FixType = {
                hovermode: 'closest',
                dragmode: false,
                showlegend: false,
                xaxis: {
                    ...commonAxisX,
                    showgrid: false,
                    range: xRangeMood
                },
                yaxis: {
                    ...commonPlotAxis(t('mood')),
                    titlefont: {
                        color: 'rgb(0, 0, 0)',
                        family: 'Roboto, sans-serif',
                        size: 16,
                    },
                    zeroline: false,
                    linecolor: 'transparent',
                    autotick: false,
                    tick0: .25,
                    dtick: .5,
                    gridcolor: 'rgba(135, 135, 135, .15)',
                    range: [-1.27, 1.27],
                    autorange: false,
                    fixedrange: true,
                    ticks: '',
                    tickfont: { color: 'transparent' }
                },
                height: 235,
                margin: { t: 20, r: 0, b: 30, l: 50 },
                images
            };

            return <div className={styles.charts}>
                <Plot
                    data={traces} layout={layout}
                    config={{displayModeBar: false}}
                    useResizeHandler={true}
                    className={styles.chart}
                />
            </div>;
        }

        return <div style={{margin: '2.5rem 0'}}><b>{t('mood')}</b> - {t('no_data')}</div>;
    }, [moodPlotData]);

    const medicationPlotData = useMemo(() => {
        const traces: FixType[] = [];

        const drugs: FixType = {};
        reportData?.drug.forEach((d) => {
            const recDate = dayjs(d.record_in),
                hour = recDate.hour(),
                date = recDate.format('YYYY-MM-DD');

            if (!drugs[date]) drugs[date] = [[], [], [], []];

            let i = 2;
            if (hour < 6 || hour > 21) { // night
                i = 3;
            } else if (hour < 12) { // morning
                i = 0;
            } else if (hour < 18) { // afternoon
                i = 1;
            } else if (hour < 22) { // evening
                i = 2;
            }

            const drug = drugs[date][i].find((dr: FixType) => dr.name === d.name && dr.dosage_id === d.dosage_id);
            if (drug)
                drug.value += d.value;
            else
                drugs[date][i].push({...d});
        });

        const drugTraces: FixType = {};
        const drugLineTraces: FixType = {};

        Object.entries(drugs).forEach(([date, arr]: FixType) => {
            arr.forEach((recs: [], p: number) => {
                if (!recs.length) return;

                const step = 1 / Math.max(4, (recs.length + 1));

                recs.forEach((d: FixType, i: number) => {
                    if (!drugTraces[d.name]) {
                        const color = _getDrugColors(d);

                        drugTraces[d.name] = {
                            name: d.name,
                            mode: 'markers+text',
                            x: [],
                            y: [],
                            marker: {
                                size: 14.5,
                                color: color[0],
                                gradient: {
                                    type: 'vertical',
                                    color: color[1]
                                }
                            },
                            legendgroup: d.name,
                            text: [],
                            textposition: 'middle right',
                            textfont: {
                                color: 'rgb(135, 135, 135)',
                                family: 'Roboto, sans-serif',
                                size: 14
                            },
                            hoverinfo: 'text',
                            hovertext: []
                        };
                        drugLineTraces[d.name] = {
                            mode: 'markers',
                            x: [],
                            y: [],
                            marker: {
                                symbol: 'line-ns',
                                size: 9,
                                color: color[0],
                                angle: 55,
                                line: {
                                    width: 3.2,
                                    color: color[2]
                                }
                            },
                            hoverinfo: 'text',
                            hovertext: [],
                            legendgroup: d.name,
                            showlegend: false
                        };
                    }
                    const trace = drugTraces[d.name];
                    const line  = drugLineTraces[d.name];

                    trace.x.push(date + 'T08:30:00');
                    trace.y.push(p + step * (i + 1));
                    trace.text.push(d.value > 1 ? d.value : '');
                    trace.hovertext.push(`${d.name} - ${d.dose} ${t(d.measure)} - ${d.value} (${t(d.form + '_interval', { postProcess: 'interval', count: d.value})})`);

                    line.x.push(date + 'T08:30:00');
                    line.y.push(p + step * (i + 1));
                    line.hovertext.push(`${d.name} - ${d.dose} ${t(d.measure)} - ${d.value} (${t(d.form + '_interval', { postProcess: 'interval', count: d.value})})`);
                });
            });
        });

        traces.push(
            ...Object.entries(drugTraces).map(([d, data]) => data),
            ...Object.entries(drugLineTraces).map(([d, data]) => data)
        );

        return {traces};
    }, [reportData]);

    const drawMedicationPlot = useMemo(() => {
        const {traces} = medicationPlotData;

        if (traces.length) {
            const layout: FixType = {
                hovermode: 'closest',
                dragmode: false,
                showlegend: true,
                legend: { orientation: 'h' },
                xaxis: {
                    ...commonAxisX,
                    range: xRangeMedication
                },
                yaxis: {
                    ...commonPlotAxis(t('medication')),
                    titlefont: {
                        color: 'rgb(0, 0, 0)',
                        family: 'Roboto, sans-serif',
                        size: 16
                    },
                    zeroline: false,
                    linecolor: 'rgba(135, 135, 135, .15)',
                    range: [-.03, 4.03],
                    autorange: false,
                    fixedrange: true,
                    autotick: false,
                    ticks: '',
                    showgrid: false,
                    tick0: .5,
                    dtick: 1,
                    tickangle: -90,
                    tickfont: {
                        color: 'rgb(135, 135, 135)',
                        family: 'Roboto, sans-serif',
                        size: 13
                    },
                    minor: {
                        tick0: 0,
                        dtick: 1,
                        gridcolor: 'rgba(135, 135, 135, .15)',
                        showgrid: true
                    },
                    labelalias: {
                        '0.5': _toUpperFirstLetter(t('morning')),
                        '1.5': _toUpperFirstLetter(t('afternoon')),
                        '2.5': _toUpperFirstLetter(t('evening')),
                        '3.5': _toUpperFirstLetter(t('night'))
                    }
                },
                height: 350,
                margin: { t: 15, r: 0, b: 40, l: 60 }
            };

            return <div className={styles.charts}>
                <Plot
                    data={traces} layout={layout}
                    config={{displayModeBar: false}}
                    useResizeHandler={true}
                    className={styles.chart}
                />
            </div>;
        }

        return <div style={{margin: '2.5rem 0'}}><b>{t('medication')}</b> - {t('no_data')}</div>;
    }, [medicationPlotData]);

    return (
        <div className={`${styles.reportPage} ${loading ? styles.loading : ''}`}>
            <div className={styles.header}>
                <div className={styles.logo} />
                <div className={styles.title}>{t('report_header_title')}</div>
            </div>
            <div className={styles.report}>
                <h1>{t('statistics')}</h1>

                {isReportActive ? (
                    <>
                        <p>{reportData?.start_date || '--'} - {reportData?.end_date || '--'}</p>

                        {tbody.length || reportData?.drug.length ? (
                            <>
                                <div className={styles.tableWrap}>
                                    {tbody.length ? (
                                        <table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>{t('date')}</th>
                                                    <th>{t('time')}</th>
                                                    <th>{t('pressure')}</th>
                                                    <th>{t('pulse')}</th>
                                                    <th>{t('arrhythmia')}</th>
                                                    <th>{t('mood')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tbody}
                                            </tbody>
                                        </table>
                                    ) : null}
                                </div>

                                <div className={styles.separator} />

                                {drawPressurePulsePlot}

                                <div className={styles.separator} />

                                {drawMoodPlot}

                                <div className={styles.separator} />

                                {drawMedicationPlot}

                            </>
                        ) : <div style={{marginBottom: '10rem'}}><b>{t('pressure')} / {t('pulse')} / {t('arrhythmia')} / {t('mood')} / {t('medication')}</b> - {t('no_data')}</div>}

                        <NavLink className={styles.link} to="/">{t('click_to_more')}</NavLink>
                    </>
                ) : (reportError ? (
                    <>
                        <p style={{marginTop: '2rem'}}>{t(reportError)}</p>
                        <p>{t('link_try_again')}</p>
                    </>
                ) : <p>{t('data_not_available')}</p>)}
            </div>
        </div>
    );
};

export default Report;