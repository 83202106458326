import styles from './style.module.css';

import {ReactNode} from 'react';
import Snackbar from '../Snackbar';

type ThisComponentProps = {
    title: ReactNode,
    children: ReactNode,
    titleClass?: string
}

const Page = ({title, children, titleClass} : ThisComponentProps) => {
    return (
        <>
            {title && (
                <div className={`${styles.pageName} ${titleClass ? styles[titleClass] : ''}`}>
                    {title}
                </div>
            )}

            <div className={styles.pageCont}>
                {children}

                <Snackbar/>
            </div>
        </>
    );
};

export default Page;
