import Page from '../../../component/Page';
import Panel from '../../../component/Panel';
import {useTranslation} from 'react-i18next';

const CardStudy = () => {
    const { t } = useTranslation();

    return (
        <>
            <Page title={t('entering_values_medical_card')}>
                <Panel title={t('visit_a_doctor')}>
                    Текущее состояние здоровья Текущее состояние здоровья Текущее состояние здоровья
                </Panel>
            </Page>
        </>
    );
};

export default CardStudy;
