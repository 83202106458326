import styles from './style.module.css';

import {ChangeEvent, useEffect, useRef, useState} from 'react';
import apiClient from '../../service/apiClient';

const b2kb = (b: number) => Math.round(b / 1024);

type ThisComponentProps = {
    existingFiles?: RecordAttachment[],
    onChange(files: RecordAttachment[]): void,
}

const FileUpload = ({onChange, existingFiles = []}: ThisComponentProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [currentFile, setCurrentFile] = useState<number>(0);
    const [preview, setPreview] = useState<FixType>(null);
    const [files, setFiles] = useState<RecordAttachment[]>(existingFiles);

    const handleUploadBtnClick = () => inputRef.current?.click();

    const addNewFiles = (newFiles: FileList) => [...files, ...newFiles];

    const handleNewFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const {files: newFiles} = e.target;

        if (newFiles?.length) {
            const updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            onChange(updatedFiles);
        }
    };

    const removeFile = () => {
        const file = files[currentFile];

        if (file) {
            const data = files.filter((i, k) => k !== currentFile);

            if (currentFile === files.length - 1) {
                setCurrentFile(Math.max(currentFile - 1, 0));
            }

            setFiles(data);
            onChange(data);
        }
    };

    const updatePreview = async (file: RecordAttachment): Promise<void> => {
        URL.revokeObjectURL(preview);

        const [type] = file.type.split('/');
        const isImage = type === 'image';

        if (isImage) {
            try {
                let href;
                if (file.fid) {
                    const response = await apiClient.downloadDocument(file.fid, true);
                    href = URL.createObjectURL(new Blob([response], {type: file.type}));
                } else {
                    href = URL.createObjectURL(file as FixType);
                }

                setPreview(href);
            } catch (e) {
                setPreview(null);
            }
        } else {
            setPreview(null);
        }
    };

    const showFile = async (e: FixType, file: RecordAttachment) => {
        e.preventDefault();

        try {
            let href;

            if (file.fid) {
                const response = await apiClient.downloadDocument(file.fid);
                href = URL.createObjectURL(new Blob([response], {type: file.type}));
            } else {
                href = URL.createObjectURL(file as FixType);
            }

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const file = files[currentFile];

        if (file) {
            updatePreview(file);
        }
    }, [files, currentFile]);

    return (
        <div className={styles.wrap}>
            <div className={styles.files}>
                <button type="button"
                    disabled={currentFile === 0}
                    className={`${styles.slide} ${styles.prev}`}
                    onClick={() => setCurrentFile(currentFile - 1)}
                />
                {files.filter((i, k) => k === currentFile).map((file, key) => {
                    const ext = file.type.split('/').pop();

                    return <a key={key}
                        target="_blank"
                        rel="noreferrer"
                        href=""
                        className={`${styles.file} ${styles[ext || '']}`}
                        style={preview ? {backgroundImage: `url(${preview})`} : {}}
                        onClick={(e) => showFile(e, file)}
                    >
                        <span>{file.name}</span>
                        {/* {isImage && (*/}
                        {/*    <img src={URL.createObjectURL(file)} alt={`file preview ${key}`} />*/}
                        {/* )}*/}
                        <span>{b2kb(file.size)} kb</span>
                    </a>;
                })}
                <button type="button"
                    disabled={currentFile >= files.length - 1}
                    className={`${styles.slide} ${styles.next}`}
                    onClick={() => setCurrentFile(currentFile + 1)}
                />
            </div>

            <div className={styles.controls}>
                <input className={styles.upload}
                    type="file"
                    multiple={true}
                    ref={inputRef}
                    onChange={handleNewFileUpload}
                />
                <button type="button" disabled={true} className={`${styles.control} ${styles.manual}`} />
                <button type="button" disabled={true} className={`${styles.control} ${styles.ai}`} />

                <div className={styles.pages}>
                    {files.length > 0 ? `${currentFile + 1} / ${files.length}` : '0 / 0'}
                </div>

                <button type="button" className={`${styles.control} ${styles.add}`} onClick={handleUploadBtnClick} />
                <button type="button"
                    disabled={files.length === 0}
                    className={`${styles.control} ${styles.del}`}
                    onClick={removeFile}
                />
            </div>
        </div>
    );
};

export default FileUpload;