const config = {
    api: {
        get host(): string{
            return process.env.REACT_APP_API_HOST || 'http://localhost';
        },
        get port(): string{
            return process.env.REACT_APP_API_PORT || '8000';
        },
        get path(): string{
            return process.env.REACT_APP_API_PATH || '/api/v1';
        },
    },
};

export default config;