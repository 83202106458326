import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import apiClient from '../../../service/apiClient';
import dayjs from 'dayjs';

interface SliceType {
    loading: boolean;
    from: string,
    to: string,
    sharedCards: KmrShareRecord[];
    sharedData: WidgetHeartData[];
}

const initialState: SliceType = {
    loading: false,
    from: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
    sharedCards: [],
    sharedData: [],
};

const slice = createSlice({
    name: 'contactsPage',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setSharedCards: (state, action: PayloadAction<KmrShareRecord[]>) => {
            state.sharedCards = action.payload;
        },
        setSharedData: (state, action: PayloadAction<WidgetHeartData[]>) => {
            state.sharedData = action.payload;
        },
        setDates: (state, action: PayloadAction<{from: string, to: string}>) => {
            state.from = action.payload.from;
            state.to = action.payload.to;
        },
    },
});

export const actions = slice.actions;

export const fetchSharedCards = () => (dispatch: FixType) => {
    dispatch(actions.setLoading(true));

    apiClient.getAccessibleCards().then((result) => {
        dispatch(actions.setSharedCards(result));
    }).finally(() => dispatch(actions.setLoading(false)));
};

export const fetchSharedData = (uri: string, start_date: string, end_date: string) => (dispatch: FixType) => {
    dispatch(actions.setLoading(true));

    apiClient.getSharedData(uri, start_date, end_date).then((result) => {
        dispatch(actions.setSharedData(result));
    }).finally(() => dispatch(actions.setLoading(false)));
};

export default slice.reducer;