import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import ApiClient from '../../../service/apiClient';
import {sortBy} from '../../../service/util';

interface SliceType {
    loading: boolean;
    from: string,
    to: string,
    trends?: HeartTrendData,
    drugs: WidgetDrugsData[],
    moods: KmrMoodData[]
}

const initialState: SliceType = {
    loading: false,
    from: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
    drugs: [],
    moods: []
};

const slice = createSlice({
    name: 'pressureWidget',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setDates: (state, action: PayloadAction<{from: string, to: string}>) => {
            state.from = action.payload.from;
            state.to = action.payload.to;
        },
        setTrendsData: (state, action: PayloadAction<HeartTrendData | undefined>) => {
            state.trends = action.payload;
        },
        setDrugs: (state, action: PayloadAction<WidgetDrugsData[]>) => {
            state.drugs = sortBy(action.payload, 'date');
        },
        setMoods: (state, action: PayloadAction<KmrMoodData[]>) => {
            state.moods = action.payload;
        }
    },
});

export const actions = slice.actions;

export const init = (from: string, to: string) => (dispatch: FixType) => {
    dispatch(actions.setLoading(true));

    const trends = ApiClient.getTrendsData(from, to)
        .then((response) => dispatch(actions.setTrendsData(response)))
        .catch(() => dispatch(actions.setTrendsData(undefined)));

    const drugs = ApiClient.getDrugsWidgetData(from, to)
        .then((response) => dispatch(actions.setDrugs(response)))
        .catch(() => dispatch(actions.setDrugs([])));

    const moods = ApiClient.getMoods(from, to)
        .then(response => dispatch(actions.setMoods(response)))
        .catch(() => dispatch(actions.setMoods([])));

    Promise.all([trends, drugs, moods]).then(() => dispatch(actions.setLoading(false)));
};

export default slice.reducer;