import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
    // is filter mode (show selected filter instead of widgets)
    filterMode: false,

    // widgets
    showDiagnosis: false,
    showTreatment: false,
    showDiary: true,
    showAnalysis: false,

    // filters
    showPressure: false,
};

const slice = createSlice({
    name: 'dynamicPage',
    initialState,
    reducers: {
        showDiagnosis: (state) => {
            if (state.filterMode) {
                state.filterMode = false;
                state.showPressure = false;
            } else {
                state.showDiagnosis = !state.showDiagnosis;
            }
        },
        showTreatment: (state) => {
            if (state.filterMode) {
                state.filterMode = false;
                state.showPressure = false;
            } else {
                state.showTreatment = !state.showTreatment;
            }
        },
        showDiary: (state) => {
            if (state.filterMode) {
                state.filterMode = false;
                state.showPressure = false;
            } else {
                state.showDiary = !state.showDiary;
            }
        },
        showAnalysis: (state) => {
            if (state.filterMode) {
                state.filterMode = false;
                state.showPressure = false;
            } else {
                state.showAnalysis = !state.showAnalysis;
            }
        },

        showCondition: (state, action: PayloadAction<string>) => {
            if (action.payload === 'heart_condition') {
                state.showPressure = !state.showPressure;

                if (state.showPressure) {
                    state.filterMode = true;
                } else if (state.filterMode) {
                    state.filterMode = false;
                }
            }
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;