import styles from './style.module.css';

import Page from '../../component/Page';
import Panel from '../../component/Panel';
import HomeRisk from '../../component/HomeRisk';
import HomeProgram from '../../component/HomeProgram';
import {useTranslation} from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();

    return (
        <Page title={t('home.title')} titleClass={'homePage'}>
            <Panel title={t('home.risks.title') + ':'} isTransparentHead={true}>
                <div className={styles.risks}>
                    <HomeRisk
                        title={'home.risks.diabetes'}
                        value={Math.random()}
                    />
                    <HomeRisk
                        title={'home.risks.hypertension'}
                        value={Math.random()}
                    />
                </div>
            </Panel>

            <Panel title={t('home.programs.title') + ':'} isTransparentHead={true}>
                <div className={styles.programs}>
                    <HomeProgram
                        type={'diabetes'}
                        title={'home.programs.diabetes'}
                        text={'home.programs.diabetes_desc'}
                    />
                    <HomeProgram
                        type={'pressure'}
                        title={'home.programs.pressure'}
                        text={'home.programs.pressure_desc'}
                    />
                    <HomeProgram
                        type={'vision'}
                        title={'home.programs.vision'}
                        text={'home.programs.vision_desc'}
                    />
                    <HomeProgram
                        type={'stress'}
                        title={'home.programs.stress'}
                        text={'home.programs.stress_desc'}
                    />
                </div>
            </Panel>
        </Page>
    );
};

export default Home;
