import styles from './style.module.css';

import Submenu from '../../component/Submenu';
import {Navigate, NavLink, Route, Routes} from 'react-router-dom';
import Contacts from './Contacts';
import Recognition from './Recognition';
import {useTranslation} from 'react-i18next';

const MyOffice = () => {
    const { t } = useTranslation();

    return (
        <>
            <Submenu title={t('my_office')}>
                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'contacts'}>{t('working_with_contacts')}</NavLink>
                <NavLink className={({ isActive }) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'recognition'}>{t('digitization')}</NavLink>
            </Submenu>

            <Routes>
                <Route index element={<Navigate to="contacts" replace />}/>
                <Route path="contacts" element={<Contacts />} />
                <Route path="recognition" element={<Recognition />} />
            </Routes>
        </>
    );
};

export default MyOffice;
