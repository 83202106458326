import {useEffect} from 'react';
import Panel from '../../Panel';
import {useTranslation} from 'react-i18next';
import DrugsGrid from '../../DrugsGrid';
import DateRangePicker from '../../DateRangePicker';
import {useAppDispatch, useAppSelector} from '../../../module/redux/hooks';
import {actions, init} from './slice';

const DrugsWidget = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.drugsWidget);

    const onChangeRange = (from: string, to: string) => {
        dispatch(actions.setDates({from, to}));
    };

    useEffect(() => {
        dispatch(init(state.from, state.to));
    }, [state.from, state.to]);

    return <Panel
        title={t('treatment')}
        loading={state.loading}
        actionsAsList={true}
        actions={<DateRangePicker type={'date'} range={[state.from, state.to]} onChangeRange={onChangeRange} hasWeekMonthOptions={true} />}
    >
        <DrugsGrid
            data={state.drugs}
            from={state.from}
            to={state.to}
        />
    </Panel>;
};

export default DrugsWidget;
