import styles from './style.module.css';
import modalStyles from '../../Modal/style.module.css';

import {useEffect, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Input, {InputStyles} from '../../Input';
import Button from '../../Button';
import useAuth from '../../../module/auth/useAuth';
import FileUploadTable from '../../FileUploadTable';
import {clone, treeToList} from '../../../service/util';
import dayjs from 'dayjs';
import {actions} from '../../Snackbar/slice';
import {SNACKBAR_TYPE_WARNING} from '../../Snackbar';
import {useAppDispatch, useAppSelector} from '../../../module/redux/hooks';

type ThisComponentProps = {
    record?: AnalysisRecord,
    onSubmit(record: AnalysisRecord, delFiles: RecordAttachment[], newFiles: RecordAttachment[]): Promise<boolean>,
    onCancel(): void,
}

interface AnalysisBlock {
    type: number,
    typeName: string,
    feature: CategoryFeature|null,
    value: RecordValue
}

const emptyBlock: AnalysisBlock = {
    type: 0,
    typeName: '',
    feature: null,
    value: {
        feature_id: 0,
        value: undefined,
        measure: null
    }
};

const CardAnalysisForm = ({record, onSubmit, onCancel} : ThisComponentProps) => {
    const { auth } = useAuth();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {categories} = useAppSelector(state => state.cardPage);

    const [enableTypes, setEnableTypes] = useState<boolean>(false);
    const [categoryTypes, setCategoryTypes] = useState<AnalysisCategory[]>([]);
    const [usedTypes, setUsedTypes] = useState<AnalysisCategory['id'][]>([]);
    const [categoryFeatures, setCategoryFeatures] = useState<CategoryFeature[]>([]);
    const [usedFeatures, setUsedFeatures] = useState<CategoryFeature['id'][]>([]);
    const [search, setSearch] = useState<string|undefined>(undefined);
    const [searchOpened, setSearchOpened] = useState<number>(-1);
    const [searchWidth, setSearchWidth] = useState<number>(320);

    const [record_at, setRecordAt] = useState<string>(record?.record_at || dayjs().utc().format('YYYY-MM-DD'));
    const [time, setTime] = useState<string>(dayjs(record?.datetime_at).format('HH:mm'));
    const [category_id, setCategoryId] = useState<Undef<number>>(record?.category_id);
    const [blocks, setBlocks] = useState<AnalysisBlock[]>([emptyBlock]);
    const [tmpFiles, setTmpFiles] = useState<RecordAttachment[]>(record?.documents || []);

    async function handleSubmit(e: FixType) {
        e.preventDefault();

        const values = blocks.map(b => b.value);
        const documents = record?.documents || [];

        if (values.length || tmpFiles.length) {
            const [hours, minutes] = time.split(':').map((i) => +i);

            const result = await onSubmit(
                {
                    id: record?.id,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    category_id: category_id!,
                    documents,
                    values,
                    record_at,
                    datetime_at: dayjs(record_at).set('hour', hours).set('minute', minutes).utc().format('YYYY-MM-DDTHH:mm:ss'),
                    local_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
                },
                documents.filter((d) => !tmpFiles.find((f) => f.id === d.id)),
                tmpFiles.filter((f) => !f.id)
            );

            if (result) {
                console.log('handleSubmit OK');
            } else {
                console.log('handleSubmit BAD');
            }
        } else {
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_WARNING, message: t('Для сохранения необходим минимум один тест!')}));
        }
    }

    const getStandard = (standards: CategoryFeatureStandard[]): string => {
        if (auth?.user && standards.length) {
            const userAge = dayjs().year() - auth.user.dob_year;
            const s = standards.find((i) => i.gender === auth.user.gender && (i.age_min <= userAge && userAge <= i.age_max));

            return s?.value_min && s?.value_max ? `${s.value_min} - ${s.value_max}` : '';
        }

        return '';
    };

    useEffect(() => {
        const category = categories.find((i) => i.id === category_id);

        if (category) {
            setCategoryTypes(category.children);
            setEnableTypes(category.children.length !== 0);

            const features: CategoryFeature[] = [...category.features];
            if (category.children.length) {
                features.push(
                    ...treeToList(category).flatMap(c => c.features)
                );
            }
            setCategoryFeatures(features);

            const blocks: AnalysisBlock[] = [];
            record?.values?.forEach(value => {
                const feature = features.find(f => f.id === value.feature_id) ?? null;
                const type = category.children.find(c => c.id === feature?.category_id);

                blocks.push({
                    type: type?.id ?? 0,
                    typeName: type?.name ?? '',
                    feature: feature,
                    value
                });
            });
            setBlocks(blocks);
        }
    }, [category_id, categories]);

    useEffect(() => {
        const usedFeatureIds: number[] = blocks?.map(b => b.value.feature_id)
            .filter((id, i, arr) => arr.indexOf(id) === i) ?? [];
        setUsedFeatures(usedFeatureIds);
        setUsedTypes(categoryTypes.filter(c =>
            c.features.every(f => usedFeatureIds.includes(f.id))
        ).map(c => c.id));
    }, [categoryTypes, blocks]);

    const filteredFeatures = useMemo(() => {
        const features: {[key: string]: { id: number, name: string, type: string }[]} = {};
        categoryFeatures.filter(f => {
            return !search || t(f.name).toLowerCase().includes(search.toLowerCase())
                || f.name.toLowerCase().includes(search.toLowerCase());
        }).sort((a, b) => {
            const aName = t(a.name).toLowerCase();
            const bName = t(b.name).toLowerCase();

            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
        }).forEach(f => {
            const type = categoryTypes.find(t => t.id === f.category_id)?.name || '';
            const letter = t(f.name).charAt(0);
            if (!features[letter]) features[letter] = [];
            features[letter].push({
                id: f.id,
                name: t(f.name),
                type
            });
        });

        return features;
    }, [search, categoryFeatures]);

    const addBlock = () => setBlocks([...blocks, clone(emptyBlock)]);

    const deleteBlock = (blockIndex: number) => {
        setBlocks(blocks.filter((b, i) => i !== blockIndex));
    };

    const focusValueInput = (blockIndex: number) => {
        setTimeout(() => {
            const els = [...document.getElementsByName('valueInput' + blockIndex)];

            if (els.length) {
                els[els.length - 1].focus();
            }
        });
    };

    const onCategoryChange = (id: number) => {
        setBlocks([emptyBlock]);
        setCategoryId(id);
    };

    const onTypeChange = (blockIndex: number, typeId: number) => {
        const features = categoryTypes.find(c => c.id === typeId)?.features.filter(f => !usedFeatures.includes(f.id));
        const feature_id = features?.length === 1 ? features[0].id : 0;

        setBlocks(blocks.map((b, i) =>
            i !== blockIndex ? b : {
                ...b,
                type: typeId,
                value: {
                    ...b.value,
                    feature_id
                }
            }
        ));
        if (feature_id) focusValueInput(blockIndex);
    };

    const onFeatureChange = (blockIndex: number, feature_id: number) => {
        setBlocks(blocks.map((b, i) =>
            i !== blockIndex ? b : {
                ...b,
                value: {
                    ...b.value,
                    feature_id
                }
            }
        ));
        if (feature_id) focusValueInput(blockIndex);
    };

    const onTypeFeatureChange = (blockIndex: number, feature_id : number) => {
        const feature = categoryFeatures.find(f => f.id === feature_id);
        if (feature) {
            setBlocks(blocks.map((b, i) =>
                i !== blockIndex ? b : {
                    ...b,
                    type: enableTypes ? feature.category_id : b.type,
                    value: {
                        ...b.value,
                        feature_id: feature.id
                    }
                }
            ));
            focusValueInput(blockIndex);
        }
    };

    const onValueChange = (blockIndex: number, value: FixType) => {
        setBlocks(blocks.map((b, i) =>
            i !== blockIndex ? b : {
                ...b,
                value: {
                    ...b.value,
                    ...value
                }
            }
        ));
    };

    const onSearchOpen = (blockIndex: number) => {
        setSearchOpened(blockIndex);
        setSearch(undefined);
        setTimeout(() => {
            const el = document.getElementById('search' + blockIndex);
            if (el) {
                el?.focus();

                const tds = el?.closest('tr')?.children;
                const width = tds ? tds[1].clientWidth + (enableTypes ? tds[2].clientWidth : 0) : 0;
                setSearchWidth(width || 320);
            }
        });
    };

    const onSearchClose = () => {
        setTimeout(() => {
            setSearchOpened(-1);
            setSearch(undefined);
        }, 100);
    };

    const Types = ({block, blockIndex}: { block: AnalysisBlock, blockIndex: number }) => {
        if (!block.value.record_id) {
            return (
                <select className={styles.plainInput}
                    onChange={(e) => onTypeChange(blockIndex, +e.target.value)}
                    value={block.type}>
                    <option value="0">{t('choose_a_type')}</option>
                    {categoryTypes.filter(c =>
                        block.type === c.id || !usedTypes.includes(c.id)
                    ).map(c =>
                        <option key={c.id} value={c.id}>{t(c.name)}</option>
                    )}
                </select>
            );
        }

        return <div>{t(block.typeName)}</div>;
    };

    const Features = ({block, blockIndex}: {block: AnalysisBlock, blockIndex: number}) => {
        if (!block.value.record_id) {
            return (
                <select className={styles.plainInput}
                    disabled={enableTypes && !block.type}
                    onChange={(e) => onFeatureChange(blockIndex, +e.target.value)}
                    value={block.value.feature_id}>
                    <option value="0">{t('choose_a_test')}</option>
                    {categoryFeatures.filter(f =>
                        (!enableTypes || block.type === f.category_id) && (block.value.feature_id === f.id || !usedFeatures.includes(f.id))
                    ).map(f => <option key={f.id} value={f.id}>{t(f.name)}</option>)}
                </select>
            );
        }

        return <span>{t(categoryFeatures.find(f => f.id === block.value.feature_id)?.name ?? '')}</span>;
    };

    const Measure = ({feature, value, blockIndex}: FixType) => {
        const [measure, setMeasure] = useState(value ?? feature?.measure);
        const bloodCategory = categories.find(category => category.name === 'blood' && category.id === category_id);

        // TODO change '.../l' to 'nmol_l', 'pmol_l', 'mu_l'
        const measures = ['mg_dl', 'mmol_l', 'nmol/l', 'pmol/l', 'mU/l'];
        if (measure && !measures.includes(measure)) measures.push(measure);

        const onMeasureChange = (value: string) => {
            setMeasure(value);

            // Set value if measure is not standard feature measure
            onValueChange(blockIndex, {measure: value !== feature?.measure ? value : null});
        };

        if (bloodCategory) {
            return (
                <select value={measure ?? 'mg_dl'} className={styles.plainInput}
                    onChange={e => onMeasureChange(e.target.value)}
                >
                    {measures.map((m, i) => <option key={i} value={m}>{t(m)}</option>)}
                </select>
            );
        }

        return (<span>{t(value?.measure || (feature?.measure || ''))}</span>);
    };

    return (
        <form className={styles.form} onSubmit={(e) => handleSubmit(e)}>
            <div className={`${InputStyles.inlineInputs} ${styles.inputs}`}>
                <Input label={t('analysis')}>
                    <select
                        className={InputStyles.input}
                        required={true}
                        disabled={!!record?.id}
                        value={category_id || ''}
                        onChange={(e) => onCategoryChange(+e.target.value)}
                    >
                        <option value={undefined}/>
                        {categories.map((i) => <option key={i.id} value={i.id}>{t(i.name)}</option>)}

                    </select>
                </Input>

                <Input label={t('date')}>
                    <input
                        type={'date'}
                        required={true}
                        value={record_at}
                        onChange={(e) => setRecordAt(e.target.value)}
                    />
                </Input>

                <Input label={t('time')}>
                    <input
                        type={'time'}
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                    />
                </Input>
            </div>

            {(!!category_id) && <div className={styles.noPaddingBlock}>
                <div className={styles.separator}></div>

                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th></th>
                            {enableTypes ? <th>{t('type')}</th> : null}
                            <th>{t('test')}</th>
                            <th>{t('result')}</th>
                            <th>{t('value')}</th>
                            <th>{t('norm')}</th>
                            <th>{t('dynamics_year')}</th>
                            <th />
                        </tr>
                    </thead>

                    <tbody>
                        {blocks.map((b, blockIndex) => {
                            const feature = categoryFeatures.find(f => f.id === b.value.feature_id);
                            return (
                                <tr key={blockIndex}>
                                    <td>{b.value.record_id ? null :
                                        <div className={styles.searchWrap}>
                                            <div className={styles.searchOpen}
                                                hidden={searchOpened === blockIndex}
                                                onClick={() => onSearchOpen(blockIndex)}
                                            ></div>
                                            <div className={styles.searchClose}
                                                hidden={searchOpened !== blockIndex}
                                                onClick={onSearchClose}
                                            ></div>
                                            <div className={styles.searchInput}
                                                hidden={searchOpened !== blockIndex}
                                            >
                                                <input
                                                    className={styles.plainInput}
                                                    style={{width: searchWidth + 'px'}}
                                                    name={'search' + blockIndex}
                                                    id={'search' + blockIndex}
                                                    type={'text'}
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value || undefined)}
                                                    onBlur={onSearchClose}
                                                />
                                            </div>
                                            <div className={styles.resultsWrap} hidden={searchOpened !== blockIndex}>
                                                <table className={styles.results}>
                                                    <thead>
                                                        <tr>
                                                            <td className={styles.empty}></td>
                                                            <th>{t('type')}</th>
                                                            <td className={styles.empty}></td>
                                                            <td className={styles.empty}></td>
                                                            <th>{t('test')}</th>
                                                            <td className={styles.empty}></td>
                                                        </tr>
                                                    </thead>
                                                    {Object.values(filteredFeatures).map((features, key) =>
                                                        <tbody key={key}>
                                                            {key > 0 && <tr className={styles.border}>
                                                                <td className={styles.empty}></td>
                                                                <td><div/></td>
                                                                <td className={styles.empty}></td>
                                                                <td className={styles.empty}></td>
                                                                <td><div/></td>
                                                                <td className={styles.empty}></td>
                                                            </tr>}
                                                            {features.map((feature, key) =>
                                                                <tr key={key}
                                                                    onClick={() => onTypeFeatureChange(blockIndex, feature.id)}>
                                                                    <td className={styles.empty}></td>
                                                                    <td>{t(feature.type)}</td>
                                                                    <td className={styles.empty}></td>
                                                                    <td className={styles.empty}></td>
                                                                    <td>{feature.name}</td>
                                                                    <td className={styles.empty}></td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    )}
                                                </table>
                                            </div>
                                        </div>
                                    }</td>
                                    {!enableTypes ? null :
                                        <td>
                                            <Types block={b} blockIndex={blockIndex}/>
                                        </td>
                                    }
                                    <td>
                                        <Features block={b} blockIndex={blockIndex}/>
                                    </td>
                                    <td>
                                        <input className={styles.plainInput}
                                            name={'valueInput' + blockIndex}
                                            required={true}
                                            type={feature?.type}
                                            step={'any'}
                                            value={b.value.value}
                                            disabled={!b.value.feature_id}
                                            onChange={(e) => b.value.feature_id ? onValueChange(blockIndex, {value: e.target.value}) : null}
                                        />
                                    </td>
                                    <td>
                                        <Measure feature={feature} value={b.value.measure} blockIndex={blockIndex}/>
                                    </td>
                                    <td>
                                        {getStandard(feature?.standards ?? [])}
                                    </td>
                                    <td/>
                                    <td>
                                        <span className={styles.del} onClick={() => deleteBlock(blockIndex)}/>
                                    </td>
                                </tr>
                            );
                        })}

                        {(categoryFeatures.length !== usedFeatures.length) && (
                            <tr>
                                <td colSpan={enableTypes ? 8 : 7}>
                                    <button type={'button'} className={styles.buttonAdd}
                                        onClick={() => addBlock()}>{t('add_block')}</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>}

            {!!category_id && <>
                <h2 className={`${modalStyles.title} ${styles.title}`}>{t('documents')}</h2>
                <div className={styles.noPaddingBlock}>
                    <FileUploadTable existingFiles={record?.documents} onChange={setTmpFiles}/>
                </div>
            </>}

            <div className={`${modalStyles.modalButtons} ${modalStyles.alignLeft}`}>
                <Button type={'submit'}>{t('save_close')}</Button>
                <Button type={'reset'} onClick={() => onCancel()}>{t('cancel')}</Button>
            </div>
        </form>
    );
};

export default CardAnalysisForm;
