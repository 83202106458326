import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

import {useAppSelector} from '../../../module/redux/hooks';
import {CommonCard} from '../index';

const CardDoctor = () => {
    const {t, i18n} = useTranslation();
    const {categories} = useAppSelector(state => state.cardPage);

    const columns = useMemo(() => [
        {
            Header: t('date'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
            accessor: 'record_at',
            disableSortBy: true,
            Cell: ({value}: FixType) => dayjs(value).format('ll'),
        },
        {
            Header: t('doctor'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
            accessor: 'category_id',
            disableSortBy: true,
            Cell: ({value}: FixType) => (categories.find((i) => i.id === value) || {}).name,
        },
        {
            Header: t('brief_diagnosis'),
            accessor: 'short_text',
            disableSortBy: true,
        },
    ], [categories, i18n.language]);

    return <CommonCard type={'doctor'} columns={columns}></CommonCard>;
};

export default CardDoctor;
