import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Panel from '../../Panel';
import ToggleGroup from '../../ToggleGroup';
import DateRangePicker from '../../DateRangePicker';

import { useAppDispatch, useAppSelector } from '../../../module/redux/hooks';
import { actions, init } from './slice';

import { CategoryName } from '../../../enums';
import PressurePlot from './plots/pressure';
import PulsePlot from './plots/pulse';
import FeelingPlot from './plots/feeling';
import Plot from '../../Plot';


const DiaryWidget = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.diaryWidget);

    const onChangeRange = (from: string, to: string) => {
        dispatch(actions.setDates({from, to}));
    };

    useEffect(() => {
        dispatch(init(state.from, state.to));
    }, [state.from, state.to]);

    useEffect(() => {
        dispatch(actions.setFilledCategories());
    }, [state.diary, state.categories]);

    return <Panel
        title={t('diary')}
        loading={state.loading}
        actionsAsList={true}
        actions={<DateRangePicker type={'date'} range={[state.from, state.to]} onChangeRange={onChangeRange} hasWeekMonthOptions={true} />}
    >
        <ToggleGroup
            onChange={(id) => dispatch(actions.toggleCategory(id))}
            options={state.categories.map((i) => ({
                name: t(i.name),
                value: i.id,
                active: state.activeCategories[i.id],
                filled: state.filledCategories[i.id],
            }))}
        />

        {state.categories.filter((c) => state.activeCategories[c.id]).map((c, index) => {
            switch (c.name) {
                case CategoryName.Pressure: {
                    return <PressurePlot key={index} category={c} />;
                }
                case CategoryName.Pulse: {
                    return <PulsePlot key={index} category={c} />;
                }
                case CategoryName.Feeling: {
                    return <FeelingPlot key={index} category={c} />;
                }
            }

            return <Plot key={index} category={c} />;
        })}
    </Panel>;
};

export default DiaryWidget;
