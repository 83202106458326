import styles from './style.module.css';

import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import translations from '../../translations';
import useOutsideClick from '../../hooks/useOutsideClick';

const Localization = () => {
    const { t, i18n } = useTranslation();
    const [visible, setVisible] = useState(false);

    const languages = Object.keys(translations);

    const handleClickOutside = () => setVisible(false);

    const ref = useOutsideClick(handleClickOutside);

    return (
        <div className={`${styles.localization} ${visible ? styles.visible : ''}`}
            ref={ref}
            onClick={() => setVisible(!visible)}>
            <div className={styles.localizationLanguage}>
                <div className={styles.localizationLanguageIcon}></div>
                <span>{i18n.language}</span>
            </div>
            <div className={styles.localizationLanguages}>
                {languages.map(language => {
                    return (
                        <a key={language}
                            className={`${i18n.language === language ? styles.currentLanguage : ''}`}
                            onClick={() => i18n.changeLanguage(language)}>
                            {t(language)}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default Localization;