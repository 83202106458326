import styles from './style.module.css';

import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Page from '../../../component/Page';
import Panel from '../../../component/Panel';
import Modal from '../../../component/Modal';
import SortTable from '../../../component/SortTable';
import apiClient from '../../../service/apiClient';
import PanelAction from '../../../component/PanelAction';
import {sortBy} from '../../../service/util';
import dayjs from 'dayjs';
import {actions} from '../../../component/Snackbar/slice';
import {SNACKBAR_TYPE_DANGER, SNACKBAR_TYPE_SUCCESS} from '../../../component/Snackbar';
import {useAppDispatch} from '../../../module/redux/hooks';
import CardAnamnesisForm from '../../../component/form/CardAnamnesis';
import {AnamnesisDisease} from '../../../enums';

const CardAnamnesis = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState<boolean>(true);
    const [records, setRecords] = useState<Anamnesis[]>([]);
    const [openedRecord, setOpenedRecord] = useState(undefined);
    const [selectedRecords, setSelectedRecords] = useState<Anamnesis[]>([]);

    async function fetchRecords() {
        setLoading(true);

        try {
            const { data }: AnamnesisResponse = await apiClient.getAnamnesis();

            setRecords(sortBy((data as Anamnesis[]), 'diagnosis_on', true));
        } catch (e) {
            setRecords([]);
        }

        setLoading(false);
    }

    async function saveRecord(record: Anamnesis): Promise<boolean> {
        try {
            const { data }: AnamnesisResponse = await (record.id ? apiClient.updateAnamnesis(record) : apiClient.createAnamnesis(record));

            if (record.id) {
                setRecords(records.map((i) => i.id === record.id ? (data as Anamnesis) : i));
            } else {
                setRecords(sortBy([(data as Anamnesis), ...records], 'diagnosis_on', true));
            }

            setOpenedRecord(undefined);

            dispatch(actions.showSnackbar({
                type: SNACKBAR_TYPE_SUCCESS,
                message: t(`${record.id ? 'messages.success.update' : 'messages.success.create'}`)
            }));

            return true;
        } catch (e) {
            console.log(e);
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));

            return false;
        }
    }

    async function deleteSelectedRecords(): Promise<void> {
        try {
            const ids: number[] = selectedRecords.reduce((a: number[], i) => i.id ? [...a, i.id] : a, []);
            await apiClient.deleteAnamnesis(ids);

            setRecords(records.filter((i) => !i.id || !ids.includes(i.id)));

            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t('messages.success.delete')}));
        } catch (e) {
            console.log(e);
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));
        }
    }

    async function init() {
        await fetchRecords();
    }

    useEffect(() => {
        init();
    }, []);

    const columns = useMemo(() => [
        {
            Header: t('diagnosis_date'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
            accessor: 'diagnosis_on',
            disableSortBy: true,
            Cell: ({value}: FixType) => dayjs(value).format('ll'),
        },
        {
            Header: t('disease'),
            Cell: ({row: {original}}: FixType) => {
                const { category, disease, active } = original;

                const categoryString = category ? t(category) : '';
                let activeString = '';
                if (disease === AnamnesisDisease.Smoking) {
                    activeString += t(active ? 'yes' : 'no');
                } else {
                    activeString += t(active ? 'disease_active' : 'disease_inactive');
                }

                return `${t(original.disease)} ${categoryString.toLowerCase()} - ${activeString.toLowerCase()}`;
            }
        },
        {
            Header: t('information'),
            accessor: 'note',
            Cell: ({value}: FixType) => <span className={styles.truncate}>{value || '--'}</span>
        }
    ], [records, i18n.language]);

    return (
        <>
            <Page title={t('entering_values_medical_card')}>
                <Panel
                    title={t('anamnesis')}
                    loading={loading}
                    actions={<>
                        <PanelAction type={'add'} onClick={() => setOpenedRecord({} as FixType)}/>
                        <PanelAction type={'delete'} disabled={selectedRecords.length === 0} onClick={() => confirm(t('confirm_delete')) && deleteSelectedRecords()}/>
                    </>}
                >
                    <SortTable
                        columns={columns}
                        data={records as FixType}
                        onRowClick={setOpenedRecord}
                        onRowSelect={setSelectedRecords}
                        selectable={true}
                    />
                </Panel>
            </Page>

            <Modal
                isOpen={!!openedRecord}
                onClose={() => setOpenedRecord(undefined)}
                title={t('anamnesis')}
            >
                <CardAnamnesisForm
                    record={openedRecord}
                    onCancel={() => setOpenedRecord(undefined)}
                    onSubmit={saveRecord}
                />
            </Modal>
        </>
    );
};

export default CardAnamnesis;
