import {ChangeEvent, HTMLInputTypeAttribute, ReactNode, useMemo} from 'react';
import InputWrap from '../Input';

interface ComponentProps {
    type?: HTMLInputTypeAttribute;
    label?: ReactNode;
    required?: boolean;
    value?: string | number | null;
    options?: {name: string; data: FixType}[];

    onChange(e: ChangeEvent<FixType>): void;
}

const Input = ({label, value, options, ...props}: ComponentProps) => {
    const id = useMemo(() => `${Math.random()}`, []);

    return <InputWrap label={label}>
        <>
            <input
                {...props}
                value={value ?? ''}
                list={id}
            />
            {!!options?.length && <datalist id={id}>
                {options.map((i, k) => <option key={k} value={i.name}/>)}
            </datalist>}
        </>
    </InputWrap>;
};

export default Input;
