import styles from './style.module.css';

import Submenu from '../../component/Submenu';
import ToggleGroup from '../../component/ToggleGroup';
import Page from '../../component/Page';
import DiaryWidget from '../../component/Widget/Diary';
import AnalysisWidget from '../../component/Widget/Analysis';
import DrugsWidget from '../../component/Widget/Drugs';
import DiagnosesWidget from '../../component/Widget/Diagnoses';
import {useTranslation} from 'react-i18next';
import PressureWidget from '../../component/Widget/Pressure';
import {useAppSelector, useAppDispatch} from '../../module/redux/hooks';
import {actions} from './slice';

const Dynamic = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.dynamicPage);

    return (
        <>
            <Submenu title={t('analysis_and_recommendations')}>
                <a className={`${styles.link} ${state.showDiary ? styles.linkActive : ''}`} onClick={() => dispatch(actions.showDiary())}>{t('diary')}</a>
                <a className={`${styles.link} ${state.showAnalysis ? styles.linkActive : ''}`} onClick={() => dispatch(actions.showAnalysis())}>{t('analysis')}</a>
                <a className={styles.link}>{t('diagnostic_tests')}</a>
                <a className={`${styles.link} ${state.showDiagnosis ? styles.linkActive : ''}`} onClick={() => dispatch(actions.showDiagnosis())}>{t('diagnosis')}</a>
                <a className={`${styles.link} ${state.showTreatment ? styles.linkActive : ''}`} onClick={() => dispatch(actions.showTreatment())}>{t('treatment')}</a>

                <div className={styles.filters}>
                    <h3 className={styles.filtersTitle}>{t('overview_by_disease')}</h3>

                    <ToggleGroup
                        onChange={(id) => dispatch(actions.showCondition(id))}
                        className={'insideSubmenu'}
                        options={['heart_condition', 'diabetes'].map((i) => ({
                            name: t(i),
                            value: i,
                            active: i === 'heart_condition' ? state.showPressure : false,
                            filled: false
                        }))}
                    />
                </div>
            </Submenu>

            <Page title={t('analysis_and_recommendations')}>
                {!state.filterMode && <>
                    {state.showDiagnosis && <DiagnosesWidget />}

                    {state.showTreatment && <DrugsWidget />}

                    {state.showDiary && <DiaryWidget />}

                    {state.showAnalysis && <AnalysisWidget />}
                </>}
                {state.filterMode && <>
                    {state.showPressure && <PressureWidget />}
                </>}
            </Page>
        </>
    );
};

export default Dynamic;
