import styles from './style.module.css';

import {ReactNode} from 'react';
import ReactModal from 'react-modal';

type ThisComponentProps = {
    isOpen: boolean,
    onClose(): void,
    title: string,
    children: ReactNode,
    size?: 'large' | 'extra-large'
}

const Modal = ({isOpen, onClose, title, children, size} : ThisComponentProps) => {
    return !isOpen ? null : <ReactModal
        isOpen={true}
        contentLabel="Example Modal"
        className={`${styles.modal} ${size ? styles['modal--' + size] : ''}`}
        overlayClassName={styles.overlay}
        ariaHideApp={false}
    >
        <h2 className={styles.title}>{title}</h2>
        <button className={styles.close} onClick={() => onClose()}>✕</button>
        {children}
    </ReactModal>;
};

export default Modal;