import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';

import apiClient from '../../../service/apiClient';
import modalStyles from '../../Modal/style.module.css';
import Input, {InputStyles} from '../../Input';
import Autocomplete, {ISuggestion} from '../../Input/Autocomplete';
import Button from '../../Button';
import FileUpload from '../../FileUpload';

import {SNACKBAR_TYPE_WARNING} from '../../Snackbar';
import {actions} from '../../Snackbar/slice';

type ThisComponentProps = {
    record?: DoctorRecord,
    onSubmit(record: DoctorRecord, delFiles: RecordAttachment[], newFiles: RecordAttachment[]): Promise<boolean>,
    onCancel(): void,
}

const CardDoctorForm = ({record, onSubmit, onCancel} : ThisComponentProps) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [doctorRecord, setDoctorRecord] = useState<DoctorRecord>({
        id: record?.id,
        category_id: record?.category_id || 0,
        documents: record?.documents || [],
        record_at: record?.record_at || dayjs().utc().format('YYYY-MM-DD'),
        short_text: record?.short_text || '',
        long_text: record?.long_text || '',
        provider_id: record?.provider_id || 0
    });

    const [doctors, setDoctors] = useState<KmrCategory[]>([]);
    const [providers, setProviders] = useState<KmrProvider[]>([]);
    const [tmpFiles, setTmpFiles] = useState<RecordAttachment[]>(record?.documents || []);

    const [diseaseAutocomplete, setDiseaseAutocomplete] = useState<DiseaseAutocomplete>();
    const [options, setOptions] = useState<ISuggestion[]>([]);

    const fetchDoctors = async () => {
        try {
            setDoctors(await apiClient.getDoctors());
        } catch (e) {
            setDoctors([]);
        }
    };

    const fetchProviders = async () => {
        try {
            setProviders(await apiClient.getProviders());
        } catch (e) {
            setProviders([]);
        }
    };

    const fetchDiseaseSuggestions = async (searchTerm: string) => {
        try {
            const data: DiseaseAutocomplete[] = await apiClient.getDiseasesAutocomplete(searchTerm, i18n.language);

            setOptions(
                data.map((item: DiseaseAutocomplete) => {
                    let prefix: string;
                    if (i18n.language === 'ru') {
                        prefix = item.mkb10 || item.icd10 || '';
                    } else {
                        prefix = item.icd10 || '';
                    }

                    return ({
                        id: item.id,
                        label: (prefix ? `${prefix}: ` : '') + item.name,
                        data: item as FixType
                    });
                })
            );
        } catch (e) {
            setOptions([]);
        }
    };

    const onChangeInput = (e: FixType) => {
        const { name, value } = e.target;

        setDoctorRecord({ ...doctorRecord, [name]: value });
    };

    const onChangeDisease = debounce((searchTerm: string) => {
        if (searchTerm.length >= 1) {
            fetchDiseaseSuggestions(searchTerm);
        } else {
            setDoctorRecord({...doctorRecord, short_text: ''});
            setDiseaseAutocomplete(undefined);
            setOptions([]);
        }
    }, 500);

    const onSelectDisease = (item: DiseaseAutocomplete) => {
        setDoctorRecord({...doctorRecord, short_text: item.name});
        setDiseaseAutocomplete(item);
        setOptions([]);
    };

    const handleSubmit = async (e: FixType) => {
        e.preventDefault();

        if (diseaseAutocomplete || doctorRecord.short_text) {
            const result = await onSubmit(
                doctorRecord,
                doctorRecord.documents.filter((d) => !tmpFiles.find((f) => f.id === d.id)),
                tmpFiles.filter((f) => !f.id)
            );

            console.log(result ? 'handleSubmit OK' : 'handleSubmit BAD');
        } else {
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_WARNING, message: t('messages.warning.disease_required')}));
        }
    };

    useEffect(() => {
        fetchDoctors();
        fetchProviders();
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <div className={modalStyles.modalGrid}>
                <div>
                    <Input label={t('date')}>
                        <input
                            name="record_at"
                            type={'date'}
                            required={true}
                            value={doctorRecord.record_at}
                            onChange={onChangeInput}
                        />
                    </Input>

                    <Input label={t('doctor')}>
                        <select
                            name="category_id"
                            className={InputStyles.input}
                            required={true}
                            value={doctorRecord.category_id}
                            onChange={onChangeInput}
                        >
                            <option value="" />
                            {doctors.map((i: FixType) => <option value={i.id} key={i.id}>{i.name}</option>)}
                        </select>
                    </Input>

                    <Input label={t('medical_institution')}>
                        <select
                            name="provider_id"
                            className={InputStyles.input}
                            value={doctorRecord.provider_id}
                            onChange={onChangeInput}
                        >
                            <option value="0" />
                            {providers.map((i: FixType) => <option value={i.id} key={i.id}>{i.name}</option>)}
                        </select>
                    </Input>

                    <div className={InputStyles.inputWrap}>
                        <Autocomplete
                            label={t('brief_diagnosis')}
                            defaultValue={doctorRecord.short_text}
                            suggestions={options}
                            onChange={onChangeDisease}
                            onSelect={onSelectDisease}
                        />
                    </div>

                    <Input label={t('complete_diagnosis')}>
                        <textarea
                            name="long_text"
                            className={InputStyles.big}
                            value={doctorRecord.long_text}
                            onChange={onChangeInput}
                        />
                    </Input>
                </div>

                <FileUpload
                    existingFiles={doctorRecord.documents}
                    onChange={setTmpFiles}
                />
            </div>

            <div className={modalStyles.modalButtons}>
                {/* <Button type={'button'} onClick={() => alert('may be later')}>{t('Оцифровать вручную')}</Button>
                <Button type={'button'} onClick={() => alert('may be later')}>{t('Оцифровать с помощью AI')}</Button>*/}
                <Button type={'reset'} onClick={() => onCancel()}>{t('cancel')}</Button>
                <Button type={'submit'}>{t('save_close')}</Button>
            </div>
        </form>
    );
};

export default CardDoctorForm;
