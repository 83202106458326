import styles from './style.module.css';

import {useEffect, useMemo} from 'react';
import Plot from 'react-plotly.js';
import Panel from '../../Panel';
import {useTranslation} from 'react-i18next';
import ToggleGroup from '../../ToggleGroup';
import DateRangePicker from '../../DateRangePicker';
import {useAppDispatch, useAppSelector} from '../../../module/redux/hooks';
import {actions, init} from './slice';
import {commonPlotAxis, commonPlotMargin} from '../../../service/plotChunks';
import dayjs from 'dayjs';

const DiagnosesWidget = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.diagnosesWidget);

    const onChangeRange = (from: string, to: string) => {
        dispatch(actions.setDates({from, to}));
    };

    useEffect(() => {
        dispatch(init(state.from, state.to));
    }, [state.from, state.to]);

    useEffect(() => {
        dispatch(actions.setFilledCategories());
    }, [state.data, state.categories]);

    const traces = useMemo(() => {
        return state.data
            .filter((i) => state.categories.some((d) => d.name === i.name && state.activeCategories[d.id]))
            .map((trace) => ({
                x: trace.diagnoses.map((i) => i.date),
                y: trace.diagnoses.map(() => trace.name),
                text: trace.diagnoses.map((i) => t(i.name))
            }));
    }, [state.data, state.categories, state.activeCategories, i18n.language]);

    return <Panel
        title={t('diagnosis')}
        loading={state.loading}
        actionsAsList={true}
        actions={<DateRangePicker type={'date'} range={[state.from, state.to]} onChangeRange={onChangeRange} />}
    >
        <ToggleGroup
            onChange={(id) => dispatch(actions.toggleCategory(id))}
            options={state.categories.map((i) => ({
                name: t(i.name),
                value: i.id,
                active: state.activeCategories[i.id],
                filled: state.filledCategories[i.id],
            }))}
        />

        {traces.some((i) => i.x.length > 0) ? (
            <Plot
                data={
                    traces.map((trace) => ({
                        x: trace.x,
                        y: trace.y,
                        text: t(trace.text),
                        type: 'scatter',
                        mode: 'text+lines+markers',
                        textfont : {
                            family:'Times New Roman'
                        },
                        line: {
                            width: 1,
                        },
                        textposition: 'top center',
                        marker: {
                            size: 8
                        }
                    }))
                }
                layout={{
                    showlegend: false,
                    xaxis: {
                        ...commonPlotAxis(),
                        autorange: false,
                        range: [state.from, dayjs(state.to).add(1, 'day').format('YYYY-MM-DD')] /** Add one day for visibility of last markers **/
                    },
                    yaxis: commonPlotAxis(),
                    height: 300,
                    margin: commonPlotMargin(),
                }}
                config={{displayModeBar: false}}
                useResizeHandler={true}
                className={styles.chart}
            />) : null
        }
    </Panel>;
};

export default DiagnosesWidget;
