import styles from './style.module.css';

import {ReactNode} from 'react';

type ThisComponentProps = {
    className?: string,
    label?: ReactNode,
    children: ReactNode,
}

const Input = ({className, label, children} : ThisComponentProps) => {
    return (
        <label className={`${className ? className : ''} ${styles.inputWrap}`}>
            {children}
            {label !== undefined && <span className={styles.label}>
                {label}
            </span>}
        </label>
    );
};

export default Input;

export const InputStyles = styles;
