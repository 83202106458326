import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import ApiClient from '../../../service/apiClient';
import {clone, sortBy} from '../../../service/util';

interface SliceType {
    loading: boolean;
    from: string;
    to: string;
    data: WidgetDoctor[];
    categories: KmrCategory[];
    activeCategories: {[key: number]: boolean};
    filledCategories: {[key: number]: boolean};
}

const initialState: SliceType = {
    loading: false,
    from: dayjs().subtract(1, 'y').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
    data: [],
    categories: [],
    activeCategories: {},
    filledCategories: {},
};

const slice = createSlice({
    name: 'diagnosesWidget',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setDates: (state, action: PayloadAction<{from: string, to: string}>) => {
            state.from = action.payload.from;
            state.to = action.payload.to;
        },
        setData: (state, action: PayloadAction<WidgetDoctor[]>) => {
            action.payload.forEach((i) => sortBy(i.diagnoses, 'date'));
            state.data = action.payload;
        },
        setCategories: (state, action: PayloadAction<KmrCategory[]>) => {
            state.categories = action.payload;
        },
        toggleCategory: (state, action: PayloadAction<number>) => {
            const id = action.payload;
            const tmp = clone(state.activeCategories);

            if (tmp[id]) {
                delete tmp[id];
            } else {
                tmp[id] = true;
            }

            state.activeCategories = tmp;
        },
        setFilledCategories: (state) => {
            state.filledCategories = state.categories.filter((d) => state.data.some((i) => i.name === d.name))
                .reduce((a, i) => ({...a, [i.id]: true}), {});

            if (Object.keys(state.activeCategories).length === 0) {
                state.activeCategories = clone(state.filledCategories);
            }
        },
    },
});

export const actions = slice.actions;

export const init = (from: string, to: string) => (dispatch: FixType) => {
    dispatch(actions.setLoading(true));

    const data = ApiClient.getDoctorsWidgetData(from, to)
        .then((response) => dispatch(actions.setData(response)))
        .catch(() => dispatch(actions.setData([])));

    const cats = ApiClient.getDoctors()
        .then((response) => dispatch(actions.setCategories(response)))
        .catch(() => dispatch(actions.setCategories([])));

    Promise.all([data, cats]).then(() => dispatch(actions.setLoading(false)));
};

export default slice.reducer;