import styles from './style.module.css';

import {ReactNode} from 'react';

type ThisComponentProps = {
    title: ReactNode,
    actionsAsList?: boolean,
    actions?: ReactNode,
    children?: FixType,
    isPlain?: boolean,
    isTransparentHead?: boolean,
    isForm?: boolean,
    loading?: boolean,
}

const Panel = ({children, title, actions, isPlain, isTransparentHead, isForm, actionsAsList, loading = false} : ThisComponentProps) => {
    return (
        <div className={`${styles.panel} ${isPlain ? styles.plainPanel : ''} ${isTransparentHead ? styles.transparentHeadPanel : ''} ${isForm ? styles.formPanel : ''}`}>
            <div className={`${styles.head} ${loading ? styles.loading : ''}`}>
                <div className={styles.title}>{title}</div>

                {actions && <div className={`${styles.actions} ${actionsAsList ? styles.actionsAsList : ''}`}>{actions}</div>}
            </div>

            <div className={styles.body}>
                {children}
            </div>
        </div>
    );
};

export default Panel;
