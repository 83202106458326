import dayjs from 'dayjs';
import i18n from '../i18nConfig';
import { ScatterData, BoxPlotData as BoxData, Layout, Shape } from 'plotly.js';

import { CategoryName } from '../enums';
import {
    commonPlotMargin, commonPlotAxis,
    commonBoxMargin, commonBoxAxis, commonBoxData
} from './plotChunks';
import { KeyFeelingIndicator, MIN_FEELING_INDICATOR } from '../component/InputRange';


const PLOT_COLORS: [number, number, number][] = [
    [183, 51, 51],
    [180, 198, 26],
    [237, 124, 89],
    [83, 166, 150]
];

export const genScatterDataset = (
    category: KmrCategory, diary: WidgetDiaryData[], from: string, to: string, featureNames?: string[]
): [Partial<ScatterData>[], Partial<Layout>] => {
    const data: Partial<ScatterData>[] = [];

    category.features.filter(feature => !featureNames || featureNames.includes(feature.name)).forEach(feature => {
        const x: FixType[] = [];
        const y: FixType[] = [];

        diary.forEach(d =>
            d.values.filter(v => v.feature_id === feature.id)
                .forEach(({value}) => {
                    x.push(d.datetime_at);
                    y.push(isNaN(Number(value)) ? value : Number(value));
                })
        );

        data.push({
            x,
            y,
            text: y,
            textposition: 'top center',
            mode: 'text+lines+markers',
            line: {
                // spline for Pressure and Pulse categories
                shape: [CategoryName.Pressure, CategoryName.Pulse].includes(category.name as CategoryName) ? 'spline' : 'linear',
                width: 1
            }
        });
    });

    const layout: Partial<Layout> = {
        showlegend: false,
        hovermode: 'closest',
        height: 150,
        margin: commonPlotMargin(),
        xaxis: {
            ...commonPlotAxis(),
            tickformat: '%H:%M\n%b. %d, %Y',
            /** Add one day for visibility of last markers **/
            range: [from, dayjs(to).add(1, 'day').format('YYYY-MM-DD')],
            autorange: false,
            fixedrange: true
        },
        yaxis: {
            ...commonPlotAxis(i18n.t(category.name)),
            showgrid: false,
            range: getYaxisRange(data),
            autorange: false,
            fixedrange: true
        }
    };

    return [data, layout];
};

export const genBoxDataset = (
    category: KmrCategory, diary: WidgetDiaryData[], from: string, to: string, featureNames?: string[], valueConverter?: FixType
): [Partial<BoxData>[], Partial<Layout>] => {
    const data: Partial<BoxData>[] = [];

    const daysToGroup = Math.floor(dayjs(to).diff(from, 'months', true)) + 1;
    const xPeriod = daysToGroup * 24 * 3600 * 1000;

    category.features.filter(feature => !featureNames || featureNames.includes(feature.name)).forEach(feature => {
        const boxes: Partial<BoxData>[] = [];
        diary.forEach(({datetime_at, values}) => {
            const value = (values.find(v => v.feature_id === feature.id) || {}).value;

            if (value) {
                const i = Math.floor(dayjs(datetime_at).diff(from) / xPeriod);
                if (!boxes[i]) {
                    boxes[i] = {
                        ...commonBoxData(),
                        width: xPeriod / 1.5,
                        /** whiskerwidth is missing in type BoxPlotData */
                        // eslint-disable-next-line
                        // @ts-ignore
                        whiskerwidth: .75
                    };
                }
                (boxes[i].y as Array<FixType>).push(isNaN(+value) ? (valueConverter ? valueConverter(value, datetime_at) : value) : +value);
            }
        });

        data.push(...convertBoxesToTraces(boxes, from, to, xPeriod));
    });

    const layout: Partial<Layout> = {
        showlegend: false,
        hovermode: 'x',
        height: 150,
        margin: commonBoxMargin(),
        xaxis: {
            ...commonBoxAxis(),
            range: getXaxisRange(from, to),
            type: 'date',
            ticks: '',
            tickfont: {
                color: getCssValue('--primary-clr'),
                family: 'Roboto, sans-serif',
                size: 11,
            }
        },
        yaxis: {
            ...commonBoxAxis(i18n.t(category.name)),
            range: getYaxisRange(data, 1.1)
        }
    };

    return [data, layout];
};

export const genReferenceShapes = ([range1, range2]: [number, number], reference?: [number, number]): Partial<Shape>[] => {
    const rect: Partial<Shape> = {
        type: 'rect',
        xref: 'paper',
        x0: 0, x1: 1,
        layer: 'below',
        line: { width: 0 },
        fillcolor: 'rgba(245, 216, 57, .06)'
    };

    const abs = Math.abs(range1) + Math.abs(range2);

    if (!reference) {
        return [{...rect, y0: range1 - abs, y1: range2 + abs}];
    }

    return [{ // background to top
        ...rect,
        y0: reference[1], y1: range2 + abs
    }, { // background center
        ...rect,
        y0: reference[0], y1: reference[1],
        fillcolor: 'rgba(27, 194, 0, .12)'
    }, { // background to bottom
        ...rect,
        y0: reference[0], y1: range1 - abs
    }];
};

export const getFeelingGlyphByValue = (value: number): string => {
    const feeling = getFeelingByValue(value);

    return feeling.charAt(0);
};

export const getFeelingColorByValue = (value: number, opacity?: number): string => {
    const feeling = getFeelingByValue(value);

    switch (feeling) {
        case 'Terrible': return `rgba(255, 29, 8, ${opacity ?? 1})`;
        case 'Bad': return `rgba(252, 109, 31, ${opacity ?? 1})`;
        case 'Normal': return `rgba(248, 210, 42, ${opacity ?? 1})`;
        case 'Good': return `rgba(108, 184, 79, ${opacity ?? 1})`;
        case 'Excellent': return `rgba(7, 165, 94, ${opacity ?? 1})`;
        default: return `rgba(248, 210, 42, ${opacity ?? 1})`;
    }
};

export const genAnalysisDataset = (
    feature: CategoryFeature, analysisData: WidgetAnalysisData[], from: string, to: string, color: string, standard?: [number, number]
): [Partial<ScatterData>[], Partial<Layout>] => {
    const [data, {yaxis: _yAxis}] = genScatterDataset(
        {name: feature.name, features: [feature]} as KmrCategory,
        analysisData, from, to
    );

    data.forEach(d => {
        d.mode = 'markers';
        d.marker = {
            ...d.marker,
            size: 6,
            color: `rgba(${color}, .7)`
        };
    });

    const xRange = [
        from,
        dayjs(to).add(Math.max(
            2, dayjs(to).diff(from, 'days', true) / 60
        ) *24*60*60*1000).format('YYYY-MM-DD')
    ];
    const yRange: [number, number] = [
        Math.floor((_yAxis?.range ?? [0])[0] * 10) / 10,
        Math.ceil((_yAxis?.range ?? [0, 1])[1] * 10) / 10
    ] as [number, number];

    data.push(...data.filter(d => d.x?.length).map(d => {
        return {
            ...d,
            mode: 'lines',
            hoverinfo: 'none',
            line: {
                ...d.line,
                shape: 'spline',
                color: `rgb(${color})`,
                width: 2.5
            },
            ...genPredictedLineCoords(d, xRange)
        } as typeof d;
    }));

    let dtick: number|undefined = Math.round((yRange[1] - yRange[0]) / 5 * 2) / 2;
    if (dtick > .5 && ![1, 1.5, 2, 2.5].includes(dtick)) {
        dtick = Math.round(dtick / 5) * 5;
    } else if (dtick < .5) {
        dtick = Math.round((yRange[1] - yRange[0]) / 5 * 20) / 20;
        if (![.05, .1, .15, .2, .25, .5].includes(dtick)) dtick = undefined;
    }

    const layout: Partial<Layout> = {
        showlegend: false,
        hovermode: 'closest',
        height: 150,
        margin: {...commonBoxMargin(), b: 33},
        xaxis: {
            ...commonBoxAxis(),
            range: xRange,
            type: 'date',
            tickcolor: 'transparent',
            ticklen: 2.5,
            tickfont: {
                color: getCssValue('--primary-clr'),
                family: 'Roboto, sans-serif',
                size: 11,
            }
        },
        yaxis: {
            ...commonBoxAxis(i18n.t(feature.name)),
            range: yRange,
            dtick,
            tickformat: '.1f'
        },
        shapes: genReferenceShapes(yRange, standard)
    };

    if (standard) {
        layout.shapes?.push({
            type: 'line',
            xref: 'paper',
            x0: 1, x1: 1,
            layer: 'above',
            y0: standard[0],
            y1: standard[1],
            line: {
                width: 6,
                color: 'rgb(115, 184, 142)'
            }
        });

        if (data.length) { // hack to display 'yaxis2'
            data.push({ x: [], y: [], yaxis: 'y2' });
        }

        const tick0 = Math.round((
            Math.max(standard[0], yRange[0]) + Math.min(standard[1], yRange[1])
        ) / 2 * 10) / 10;
        const dtick = (yRange[0] + yRange[1]) * 2;

        layout.yaxis2 = {
            overlaying: 'y',
            side: 'right',
            showgrid: false,
            zeroline: false,
            range: yRange,
            fixedrange: true,
            autotick: false,
            tick0,
            dtick,
            ticklen: 5,
            tickcolor: 'transparent',
            tickfont: {
                color: '#4C5252',
                family: 'Roboto, sans-serif',
                size: 10,
            },
            /** labelalias is missing in type LayoutAxis */
            // eslint-disable-next-line
            // @ts-ignore
            labelalias: {[tick0]: i18n.t('norm')}
        };
    }

    return [data, layout];
};

export const getPlotColor = (index?: number): string => PLOT_COLORS[(index ?? 0) % PLOT_COLORS.length].join(', ');


const getCssValue = (prop: string) => getComputedStyle(document.body).getPropertyValue(prop);

const convertBoxesToTraces = (
    boxes: Partial<BoxData>[], from: string, to: string, xPeriod: number
): Partial<BoxData>[] => {
    const arr = boxes.map((b, i) => {
        const start = dayjs.utc(from, 'YYYY-MM-DD').add(i * xPeriod);
        const end   = dayjs(start).add(xPeriod).subtract(1, 'day');

        b.name = start.format('DD/MM/YYYY');
        if (!start.isSame(end, 'day')) {
            b.name += end.format(' - DD/MM/YYYY');
        }
        b.x = Array(b.y?.length).fill(dayjs(start).add(xPeriod / 2).format());
        return b;
    }).filter(b => b);

    return [...arr, ...arr.map(b => {
        const median = getMedianOfArray(b.y as number[]);
        return {
            x: [(b.x ?? [0])[0]],
            y: [median],
            mode: 'markers',
            marker: {
                size: 10,
                opacity: 0
            },
            hoverinfo: 'text',
            hovertext: `<b>${b.name}</b><br>` + (Object.hasOwn(b, 'hovertext') ? `<br>${b.hovertext}` : `${i18n.t('median')} ${median}`),
            hoverlabel: {
                align: 'left',
                font: {
                    color: '#FFF',
                    family: 'Roboto, sans-serif',
                    size: 12

                },
                bgcolor: '#4C5252'
            }
        } as BoxData;
    })];
};

const getFeelingByValue = (value: number): string => {
    return (Object.keys(MIN_FEELING_INDICATOR) as KeyFeelingIndicator[]).reduce((previous, current) =>
        value >= MIN_FEELING_INDICATOR[previous] && value < MIN_FEELING_INDICATOR[current] ? previous : current
    );
};

const getXaxisRange = (from: string, to: string): [string, string] => {
    const daysToGroup = Math.floor(dayjs(to).diff(from, 'months', true)) + 1;
    const div = dayjs(to).diff(from, 'days') % daysToGroup;

    return [from, dayjs(to).add(div > 0 ? daysToGroup - div : 1, 'day').format('YYYY-MM-DD')];
};

export const getYaxisRange = (data: Partial<ScatterData|BoxData>[], k?: number): [number, number] => {
    const arr: number[] = data.flatMap(item => (item.y as string[]).filter(item => !isNaN(+item)).map(item => +item));
    if (arr.length) {
        const [min, max] = [Math.min(...arr), Math.max(...arr)];
        const [signMin, signMax] = [Math.sign(min), Math.sign(max)];
        const [absMin, absMax] = [Math.abs(min), Math.abs(max)];

        if (typeof k !== 'number') k = 1.5;

        return [(signMin > 0 ? absMin / k : absMin * k) * signMin, (signMax > 0 ? absMax * k : absMax / k) * signMax];
    }
    return [0, 1];
};

const getMedianOfArray = (arr: number[]): number => {
    const mid = Math.floor(arr.length / 2);
    const nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

const genPredictedLineCoords = (data: Partial<ScatterData>, [from, to]: string[]): {x: string[], y: number[]} => {
    from = dayjs(from).startOf('day').format();
    to = dayjs(to).endOf('day').format();

    const diff = dayjs(to).diff(from) / 10;

    const sum = {X: 0, X2: 0, X3: 0, X4: 0, Y: 0, XY: 0, X2Y: 0};

    const dataX = data.x as string[];
    const dataY = data.y as number[];

    // Hack to make the parabola straighter
    // Copy and shift start/end points to left and right by distance of 'diff'
    const x = [dayjs(dataX[0]).add(-diff).format(), ...dataX, dayjs(dataX[dataX.length - 1]).add(diff).format()];
    const y = [dataY[0], ...dataY, dataY[dataY.length - 1]];

    x.forEach((date, i) => {
        const v = dayjs(date).diff(from) / diff;

        sum.X += v;
        sum.X2 += Math.pow(v, 2);
        sum.X3 += Math.pow(v, 3);
        sum.X4 += Math.pow(v, 4);
        sum.Y += y[i];
        sum.XY += v * y[i];
        sum.X2Y += Math.pow(v, 2) * y[i];
    });

    // Least Squares Method
    const matrix = [
        [sum.X4, sum.X3, sum.X2, sum.X2Y],
        [sum.X3, sum.X2, sum.X, sum.XY],
        [sum.X2, sum.X, x.length, sum.Y],
    ];

    // Obtain the coefficients for ax^2 + bx + c = y
    // coefficients = [c, b, a];
    const coefficients = gaussianElimination(matrix).reverse();

    const predict = (date: string): number => {
        const x = dayjs(date).diff(from) / diff;
        const sum = coefficients.reduce(
            (s, c, i) => s + (c * Math.pow(x, i)),
            0
        );

        return Math.round(sum * 1000) / 1000;
    };

    const lineData: {x: string[], y: number[]} = {x: [], y: []};
    let date = dayjs(from);
    while(date.isSameOrBefore(to)) {
        lineData.x.push(date.format());
        lineData.y.push(predict(date.format()));

        date = date.add(diff);
    }

    return lineData;
};

const gaussianElimination = (matrix: number[][]): number[] => {
    const numRows = matrix.length;
    const numCols = matrix[0].length;
    const EPS = 1e-12;

    for (let col = 0; col < numCols - 1; col++) {
        // Find the maximum element in the column to improve accuracy
        let max = col;
        for (let row = col + 1; row < numRows; row++) {
            if (Math.abs(matrix[row][col]) > Math.abs(matrix[max][col])) {
                max = row;
            }
        }

        // Swap the current row with the row having the maximum element
        if (max !== col) [matrix[max], matrix[col]] = [matrix[col], matrix[max]];

        // Check for a zero diagonal element
        if (Math.abs(matrix[col][col]) < EPS) continue;

        // Normalize the current row to make the diagonal element equal to one
        const factor = matrix[col][col];
        for (let i = col; i < numCols; i++) {
            matrix[col][i] /= factor;
        }

        // Zero out the current column elements in all other rows
        for (let row = 0; row < numRows; row++) {
            if (row !== col && Math.abs(matrix[row][col]) > EPS) {
                const factor = matrix[row][col];
                for (let i = col; i < numCols; i++) {
                    matrix[row][i] -= factor * matrix[col][i];
                }
            }
        }
    }

    // Back substitution to extract the solution
    const solution = Array(numCols - 1).fill(0);
    for (let i = 0; i < numCols - 1; i++) {
        solution[i] = matrix[i][numCols - 1];
    }

    return solution;
};
