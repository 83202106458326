import styles from './style.module.css';

import {useTranslation} from 'react-i18next';
import Button from '../Button';

type ThisComponentProps = {
    type: string,
    title: string,
    text: string
}

const HomeProgram = ({type, title, text} : ThisComponentProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.wrap}>
            <div className={styles.titleWrap}>
                <div className={`${styles.icon} ${styles[type]}`}></div>
                <div className={styles.title}>{t(title)}</div>
            </div>
            <div className={styles.text}>{t(text)}</div>
            <div className={styles.buttonWrap}>
                <Button type={'submit'}>{t('home.programs.show')}</Button>
            </div>
        </div>
    );
};

export default HomeProgram;
