import styles from './style.module.css';

import {ChangeEvent, useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import apiClient from '../../service/apiClient';

type ThisComponentProps = {
    existingFiles?: RecordAttachment[],
    onChange(files: RecordAttachment[]): void,
}

const FileUpload = ({onChange, existingFiles = []}: ThisComponentProps) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<{ file: RecordAttachment, preview: string|null }[]>([]);

    const handleUploadBtnClick = () => inputRef.current?.click();

    const handleNewFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        const {files: newFiles} = e.target;

        if (newFiles?.length) {
            const _newFiles = [...files, ...(await genPreviews(newFiles))];
            setFiles(_newFiles);
            onChange(_newFiles.map(f => f.file));
        }
    };

    const removeFile = (index: number) => {
        const {file, preview} = files[index];

        if (preview) URL.revokeObjectURL(preview);

        if (file) {
            const data = files.filter((i, k) => k !== index);

            setFiles(data);
            onChange(data.map(f => f.file));
        }
    };

    const genPreviews = async (newFiles: RecordAttachment[]|FileList): Promise<typeof files> => {
        const _newFiles: typeof files = [];

        for (const file of newFiles) {
            const [type] = file.type.split('/');
            let preview = null;

            if (type === 'image') {
                try {
                    if ('fid' in file && file.fid) {
                        const response = await apiClient.downloadDocument(file.fid, true);
                        preview = URL.createObjectURL(new Blob([response], {type: file.type}));
                    } else {
                        preview = URL.createObjectURL(file as FixType);
                    }
                } catch (e) {
                    preview = null;
                }
            }

            _newFiles.push({file, preview});
        }

        return _newFiles;
    };

    const showFile = async (e: FixType, file: RecordAttachment) => {
        e.preventDefault();

        try {
            let href;

            if (file.fid) {
                const response = await apiClient.downloadDocument(file.fid);
                href = URL.createObjectURL(new Blob([response], {type: file.type}));
            } else {
                href = URL.createObjectURL(file as FixType);
            }

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        (async () => {
            if (existingFiles?.length) setFiles(await genPreviews(existingFiles));
        })();
    }, [existingFiles]);

    return (<div>
        <table className={styles.table}>
            <tbody>
                {files.map(({file, preview}, key) => {
                    const ext = file.type.split('/').pop();

                    return <tr key={key}>
                        <td>
                            <a target="_blank" rel="noreferrer" href=""
                                className={styles.file}
                                onClick={(e) => showFile(e, file)}
                            >
                                <span className={`${styles.fileIcon} ${styles[ext || '']}`}
                                    style={preview ? {backgroundImage: `url(${preview})`} : {}}
                                ></span>
                                <span className={styles.fileName}>{file.name}</span>
                            </a>
                        </td>
                        <td>
                            <button type="button" disabled={true} className={`${styles.control} ${styles.manual}`}/>
                        </td>
                        <td>
                            <button type="button" disabled={true} className={`${styles.control} ${styles.ai}`}/>
                        </td>
                        <td>
                            <button type="button"
                                disabled={files.length === 0}
                                className={`${styles.control} ${styles.del}`}
                                onClick={() => removeFile(key)}
                            />
                        </td>
                    </tr>;
                })}
                <tr>
                    <td colSpan={4}>
                        <button type="button" className={`${styles.buttonAdd}`}
                            onClick={handleUploadBtnClick}
                        >
                            {t('add_file')}
                            <input className={styles.upload}
                                type="file" multiple={true}
                                ref={inputRef}
                                onChange={handleNewFileUpload}
                            />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>);
};

export default FileUpload;