import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type SnackbarPayloadAction = {
    type: string;
    message?: string;
}

const initialState = {
    isSnackbarActive: false,
    message: '',
    type: ''
};

const slice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        showSnackbar: (state, action: PayloadAction<SnackbarPayloadAction>) => {
            state.isSnackbarActive = true;
            state.message = action.payload.message || '';
            state.type = action.payload.type;
        },
        hideSnackbar: (state) => {
            state.isSnackbarActive = false;
            state.message = '';
            state.type = '';
        }
    },
});

export const actions = slice.actions;
export const {showSnackbar, hideSnackbar} = slice.actions;

export default slice.reducer;