import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from './useAuth';

type ThisComponentProps = {
    allowedRoles?: string[],
}

const RequireAuth = ({ allowedRoles }: ThisComponentProps) => {
    const { auth } = useAuth();
    const location = useLocation();

    const allowed = auth && (!allowedRoles?.length || auth?.roles?.find((i) => allowedRoles?.includes(i)));

    return (
        allowed
            ? <Outlet />
            : auth
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default RequireAuth;