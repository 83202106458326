import {createContext, ReactNode, useState} from 'react';

interface AuthProviderProps {
    initAuth?: Null<AuthUser>;
    children: ReactNode;
}

interface AuthUser {
    user: FixType,
    roles: string[],
    accessToken: string,
}

interface AuthContextProps {
    auth: Null<AuthUser>;
    setAuth(user: Null<AuthUser>): void;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider = ({ children, initAuth = null }: AuthProviderProps) => {
    const [auth, setAuth] = useState<Null<AuthUser>>(initAuth);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;