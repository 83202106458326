import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface SliceType {
    user: KmrUser;
}

const initialState: SliceType = {
    user: {
        code: '',
        cover: '',
        dob_year: 0,
        first_name: '',
        gender: '',
        id: '',
        last_name: '',
        country: '',
        state: '',
        city: '',
        email: '',
        education: '',
        smoking: false
    },
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<KmrUser>) => {
            state.user = action.payload;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;