import styles from './style.module.css';

import {useEffect} from 'react';
import {actions} from './slice';
import {RootState} from '../../module/redux/store';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../module/redux/hooks';

export const SNACKBAR_TYPE_INFO = 'info';
export const SNACKBAR_TYPE_SUCCESS = 'success';
export const SNACKBAR_TYPE_WARNING = 'warning';
export const SNACKBAR_TYPE_DANGER = 'danger';

const Snackbar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {isSnackbarActive, message, type} = useAppSelector((state: RootState) => state.snackbar);
    const title = getTitleByType(type);

    let timer: NodeJS.Timeout;

    function getTitleByType(type: string) {
        if (type === SNACKBAR_TYPE_INFO) return t('messages.announcement_title');
        if (type === SNACKBAR_TYPE_SUCCESS) return t('messages.completed_title');
        if (type === SNACKBAR_TYPE_WARNING) return t('messages.warning_title');
        if (type === SNACKBAR_TYPE_DANGER) return t('messages.error_title');

        return '';
    }

    useEffect(() => {
        if (isSnackbarActive) {
            timer = setTimeout(() => dispatch(actions.hideSnackbar()), 5000);
            return () => clearTimeout(timer);
        }
    }, [isSnackbarActive]);

    const handleClose = () => {
        clearTimeout(timer);
        dispatch(actions.hideSnackbar());
    };

    return (
        <>
            {isSnackbarActive && (
                <div className={`${styles.snackbar} ${styles[type]}`}>
                    <div className={styles.icon}></div>
                    <div className={styles.messageWrapper}>
                        {title && <h1>{title}</h1>}
                        {message && <span>{message}</span>}
                    </div>
                    <div className={styles.close} onClick={handleClose}></div>
                </div>
            )}
        </>
    );
};

export default Snackbar;