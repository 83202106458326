import styles from './style.module.css';
import userPanelStyles from '../../../component/UserPanel/style.module.css';

import Page from '../../../component/Page';
import Panel from '../../../component/Panel';
import {useAppDispatch, useAppSelector} from '../../../module/redux/hooks';
import {useCallback, useEffect, useState} from 'react';
import {actions} from '../../../component/Snackbar/slice';
import {SNACKBAR_TYPE_SUCCESS, SNACKBAR_TYPE_WARNING} from '../../../component/Snackbar';
import {useTranslation} from 'react-i18next';
import Modal from '../../../component/Modal';
import ShareRequestModal from './ShareRequestModal';
import Button from '../../../component/Button';
import {init, reject, revoke} from './slice';

const SettingsAccess = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [user, settingsAccess] = useAppSelector((state) => [state.user.user, state.settingsAccess]);
    const [openedShareModal, setOpenedShareModal] = useState(false);
    const [shareAccess, setShareAccess] = useState(false);
    const [request, setRequest] = useState<KmrShareRecord | undefined>();
    const [otherShares, setOtherShares] = useState<KmrShareRecord[]>([]);
    const [ownShares, setOwnShares] = useState<KmrShareRecord[]>([]);

    const copyCode = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(user.code);
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t('messages.success.token')}));
        } catch (e: FixType) {
            dispatch(actions.showSnackbar({
                type: SNACKBAR_TYPE_WARNING,
                message: t('messages.warning.browser'),
            }));
        }
    }, [t, user.code]);

    const openShareModal = async (access: boolean, request?: KmrShareRecord) => {
        setRequest(request);
        setShareAccess(access);
        setOpenedShareModal(true);
    };

    useEffect(() => {
        dispatch(init());
    }, []);

    useEffect(() => {
        const own: KmrShareRecord[] = [];
        const other: KmrShareRecord[] = [];

        settingsAccess.sharedCards.forEach((i) => {
            if (i.myCard) {
                own.push(i);
            } else {
                other.push(i);
            }
        });
        console.log({own, other});
        setOtherShares(other);
        setOwnShares(own);
    }, [settingsAccess.sharedCards]);

    return (
        <>
            <Page title={t('access_control')}>
                <Panel
                    title={t('my_token')}
                >
                    <div className={styles.panelDesc}>
                        {t('send_access')}
                    </div>

                    <div className={styles.codeBlock}>
                        <div className={styles.codeCopyBlock}>
                            <button className={styles.codeCopy} onClick={copyCode}>
                                {user.code}
                            </button>
                            {t('copy_token')}
                        </div>

                        <div className={styles.codeSeparator}>{t('or')}</div>

                        <div className={styles.codeQr}/>
                        <div>
                            {t('scan_qr')}
                        </div>
                    </div>
                </Panel>

                <Panel
                    title={t('access_to_other')}
                    actionsAsList={true}
                    actions={<Button type={'submit'} onClick={() => openShareModal(true)}>{t('request_access')}</Button>}
                >
                    {otherShares.map((i, k) => <div key={k} className={styles.shareLine}>
                        <div>
                            <div className={userPanelStyles.icon} />

                            <span>
                                {i.state === 'pending' && (i.own ? i.anchor_alias : <><b>{t('access_request')}</b> {i.note}</>)}
                                {i.state !== 'pending' && i.owner_alias}
                            </span>
                        </div>

                        <div>
                            {i.state === 'pending' && i.own && <div className={styles.requestSent}>{t('request_sent')}</div>}

                            {i.state === 'pending' && !i.own && <>
                                <Button type={'reset'} onClick={() => dispatch(reject(i.uri))}>{t('reject_request')}</Button>
                                <Button type={'button'} onClick={() => openShareModal(true, i)}>{t('accept_request')}</Button>
                            </>}

                            {i.state !== 'pending' && <Button type={'reset'} onClick={() => dispatch(revoke(i.uri))}>{t('terminate_access')}</Button>}
                        </div>
                    </div>)}
                </Panel>

                <Panel
                    title={t('access_to_your_card')}
                    actionsAsList={true}
                    actions={<Button type={'submit'} onClick={() => openShareModal(false)}>{t('give_access')}</Button>}
                >
                    {ownShares.map((i, k) => <div key={k} className={styles.shareLine}>
                        <div>
                            <div className={userPanelStyles.icon} />

                            <span>
                                {i.state === 'pending' && (i.own ? i.owner_alias : <><b>{t('access_request')}</b> {i.note}</>)}
                                {i.state !== 'pending' && i.owner_alias}
                            </span>
                        </div>

                        <div>
                            {i.state === 'pending' && i.own && <div className={styles.requestSent}>{t('request_sent')}</div>}

                            {i.state === 'pending' && !i.own && <>
                                <Button type={'reset'} onClick={() => dispatch(reject(i.uri))}>{t('reject_request')}</Button>
                                <Button type={'button'} onClick={() => openShareModal(false, i)}>{t('accept_request')}</Button>
                            </>}

                            {i.state !== 'pending' && <Button type={'reset'} onClick={() => dispatch(revoke(i.uri))}>{t('terminate_access')}</Button>}
                        </div>
                    </div>)}
                </Panel>
            </Page>

            <Modal
                isOpen={openedShareModal}
                onClose={() => setOpenedShareModal(false)}
                title={t(shareAccess ? 'request_access' : 'give_access')}
            >
                <ShareRequestModal
                    request={request}
                    access={shareAccess}
                    onClose={(result: boolean) => {
                        if (result) {
                            dispatch(init());
                        }
                        setOpenedShareModal(false);
                    }}
                />
            </Modal>
        </>
    );
};

export default SettingsAccess;
