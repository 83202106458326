import styles from './style.module.css';

import Submenu from '../../component/Submenu';
import {Navigate, NavLink, Route, Routes} from 'react-router-dom';
import SettingsAccess from './Access';
import SettingsProfile from './Profile';
import SettingsSecurity from './Security';
import {useTranslation} from 'react-i18next';

const Settings = () => {
    const { t } = useTranslation();

    return (
        <>
            <Submenu title={t('settings')}>
                <NavLink className={({isActive}) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'access'}>{t('access_control')}</NavLink>
                <NavLink className={({isActive}) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'profile'}>{t('personal_data')}</NavLink>
                <NavLink className={({isActive}) => `${styles.link} ${isActive ? styles.linkActive : ''}`} to={'security'}>{t('security')}</NavLink>
            </Submenu>

            <Routes>
                <Route index element={<Navigate to="access" replace />}/>
                <Route path="access" element={<SettingsAccess/>}/>
                <Route path="profile" element={<SettingsProfile/>}/>
                <Route path="security" element={<SettingsSecurity/>}/>
            </Routes>
        </>
    );
};

export default Settings;
