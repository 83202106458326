import {useTranslation} from 'react-i18next';
import Input from '../../../../component/Input/Input';
import modalStyles from '../../../../component/Modal/style.module.css';
import Button from '../../../../component/Button';
import {useState} from 'react';
import apiClient from '../../../../service/apiClient';
import {actions} from '../../../../component/Snackbar/slice';
import {SNACKBAR_TYPE_DANGER, SNACKBAR_TYPE_SUCCESS} from '../../../../component/Snackbar';
import {useAppDispatch} from '../../../../module/redux/hooks';

type ThisComponentProps = {
    request?: KmrShareRecord,
    access: boolean,
    onClose(result?: boolean): void,
}

const ShareRequestModal = ({request, access, onClose}: ThisComponentProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [permissions] = useState<string[]>(['heart']);
    const [anchor, setAnchor] = useState<string>('');
    const [alias, setAlias] = useState<string>('');
    const [note, setNote] = useState<string>('');

    const handleSubmit = async (e: FixType) => {
        e.preventDefault();

        try {
            if (!request) {
                const payload: KmrShareRequest = {permissions, anchor, alias, note};
                await apiClient.createShareRequest(payload, access) as KmrShareRecord;

                dispatch(actions.showSnackbar({
                    type: SNACKBAR_TYPE_SUCCESS,
                    message: t('messages.success.request'),
                }));
            } else {
                const payload: KmrShareResponse = {
                    uri: request.uri,
                    note: request.note,
                    permissions: request.permissions,
                    alias
                };
                await apiClient.acceptShareRequest(payload) as KmrShareRecord;

                dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_SUCCESS}));
            }

            onClose(true);
        } catch (e) {
            dispatch(actions.showSnackbar({
                type: SNACKBAR_TYPE_DANGER,
                message: t('messages.error'),
            }));
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                {!!request && <>
                    <b>{request.note}</b>
                    <Input
                        type={'text'}
                        required={true}
                        value={alias}
                        label={t('username')}
                        onChange={(e) => setAlias(e.target.value)}
                    />
                </>}
                {!request && <>
                    <Input
                        type={'text'}
                        required={true}
                        value={anchor}
                        label={t('user_id')}
                        onChange={(e) => setAnchor(e.target.value)}
                    />

                    <Input
                        type={'text'}
                        required={true}
                        value={alias}
                        label={t('username')}
                        onChange={(e) => setAlias(e.target.value)}
                    />

                    <Input
                        type={'text'}
                        required={true}
                        value={note}
                        label={t('note')}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </>}
            </div>

            <div className={modalStyles.modalButtons}>
                <Button type={'reset'} onClick={() => onClose()}>{t('cancel')}</Button>
                <Button type={'submit'}>{t(access ? 'request_access' : 'give_access')}</Button>
            </div>
        </form>
    );
};

export default ShareRequestModal;
