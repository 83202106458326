import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

import useAuth from '../../../module/auth/useAuth';
import {useAppDispatch, useAppSelector} from '../../../module/redux/hooks';
import {genAnalysisDataset, getPlotColor} from '../../../service/plotUtils';
import {actions, init} from './slice';
import Panel from '../../Panel';
import ToggleGroup from '../../ToggleGroup';
import DateRangePicker from '../../DateRangePicker';
import Plot from '../../Plot';


interface Props {
    feature: CategoryFeature,
    colorIndex?: number
}

const getStandard = (user: FixType, standards: CategoryFeatureStandard[]): [number, number]|undefined => {
    if (user && standards.length) {
        const userAge = dayjs().year() - user.dob_year;
        const s = standards.find((i) => i.gender === user.gender && (i.age_min <= userAge && userAge <= i.age_max));

        if (s?.value_min && s.value_max) return [s.value_min, s.value_max];
    }

    return undefined;
};

const AnalysisPlot = ({feature, colorIndex}: Props) => {
    const {auth} = useAuth();
    const state = useAppSelector((state) => state.analysisWidget);

    const [data, layout] = useMemo(() => {
        return genAnalysisDataset(
            feature, state.data,
            state.from, state.to,
            getPlotColor(colorIndex),
            getStandard(auth?.user, feature.standards)
        );
    }, [state.data]);

    useEffect(() => {
        const color = getPlotColor(colorIndex);

        data.forEach(d => {
            if (d.marker) d.marker.color = `rgba(${color}, .7)`;
            if (d.line) d.line.color = `rgb(${color})`;
        });
    }, [colorIndex, data]);

    return <Plot data={data} layout={layout} />;
};

const AnalysisWidget = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.analysisWidget);

    const onChangeRange = (from: string, to: string) => {
        dispatch(actions.setDates({from, to}));
    };

    useEffect(() => {
        dispatch(init(state.from, state.to));
    }, [state.from, state.to]);

    useEffect(() => {
        dispatch(actions.setFilledFeatures());
    }, [state.data, state.features]);

    let colorIndex = -1;

    return <Panel
        title={t('analysis')}
        loading={state.loading}
        actionsAsList={true}
        actions={<DateRangePicker type={'date'} range={[state.from, state.to]}
            onChangeRange={onChangeRange} quickRangeButtons={['month', 'six_months', 'year']}
        />}
    >
        <ToggleGroup
            onChange={(id) => dispatch(actions.toggleFeature(id))}
            options={state.features.map((f) => ({
                name: t(f.name),
                value: f.id,
                active: state.activeFeatures[f.id],
                filled: state.filledFeatures[f.id],
            }))}
        />

        {state.features.map((f, i) => {
            if (i === 0) colorIndex = -1;
            if (!state.activeFeatures[f.id] || !state.filledFeatures[f.id]) return null;
            colorIndex++;
            return <AnalysisPlot key={i} feature={f} colorIndex={colorIndex}/>;
        })}
    </Panel>;
};

export default AnalysisWidget;
