import styles from './style.module.css';
import modalStyles from '../../Modal/style.module.css';

import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Input, {InputStyles} from '../../Input';
import Button from '../../Button';
import dayjs from 'dayjs';
import {AnamnesisCategory, AnamnesisDisease} from '../../../enums';
import Checkbox from '../../Checkbox';

type ThisComponentProps = {
    record?: Anamnesis,
    onSubmit(record: Anamnesis): Promise<boolean>,
    onCancel(): void,
}

type AnamnesisData = {
    name: AnamnesisDisease,
    categories?: AnamnesisCategory[]
}

const anamnesis: AnamnesisData[] = [
    {
        name: AnamnesisDisease.Diabetes,
        categories: [AnamnesisCategory.Chronic, AnamnesisCategory.Acute]
    },
    {
        name: AnamnesisDisease.Infarct,
        categories: [AnamnesisCategory.Acute]
    },
    {
        name: AnamnesisDisease.Smoking
    }
];

const CardAnamnesisForm = ({record, onSubmit, onCancel}: ThisComponentProps) => {
    const {t} = useTranslation();

    const [diagnosis_on, setDiagnosisOn] = useState<string>(record?.diagnosis_on || dayjs().utc().format('YYYY-MM-DD'));
    const [categories, setCategories] = useState<AnamnesisCategory[]>([]);
    const [disease, setDisease] = useState<AnamnesisDisease>(record?.disease || '');
    const [category, setCategory] = useState<AnamnesisCategory | ''>(record?.category || '');
    const [active, setActive] = useState<boolean>(record?.id ? record.active : true);
    const [note, setNote] = useState<string>(record?.note || '');

    useEffect(() => {
        const selectedAnamnesis = anamnesis.find(anamnesis => anamnesis.name === disease);
        if (selectedAnamnesis) {
            setCategories(selectedAnamnesis.categories?.length ? selectedAnamnesis.categories : []);
            setCategory(selectedAnamnesis.categories?.length === 1 ? selectedAnamnesis.categories[0] : '');
        }
    }, [disease]);

    async function handleSubmit(e: FixType) {
        e.preventDefault();

        const data: Anamnesis = {
            id: record?.id,
            disease,
            active,
            diagnosis_on,
            note,
            local_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        if (category) data.category = category;

        const result = await onSubmit(data);

        console.log(result ? 'handleSubmit OK' : 'handleSubmit BAD');
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className={modalStyles.modalGrid}>
                <div>
                    <Input label={t('diagnosis_date')}>
                        <input
                            type={'date'}
                            required={true}
                            value={diagnosis_on}
                            onChange={(e) => setDiagnosisOn(e.target.value)}
                        />
                    </Input>

                    <Input label={t('disease')}>
                        <select
                            className={InputStyles.input}
                            required={true}
                            value={disease}
                            onChange={(evt) => setDisease(evt.target.value as AnamnesisDisease)}
                            disabled={!!record?.id}
                        >
                            <option value=""/>
                            {anamnesis.map(({ name }: AnamnesisData) => <option value={name} key={name}>{t(name)}</option>)}
                        </select>
                    </Input>

                    {disease && (
                        <>
                            {!!categories.length && (
                                <Input label={t('category')}>
                                    <select
                                        className={InputStyles.input}
                                        value={category}
                                        onChange={(evt) => setCategory(evt.target.value as AnamnesisCategory)}
                                        disabled={!!record?.id}
                                    >
                                        <option value=""/>
                                        {categories.map((category: AnamnesisCategory) => <option value={category} key={category}>{t(category)}</option>)}
                                    </select>
                                </Input>
                            )}

                            <div className={styles.checkboxWrapper}>
                                <Checkbox
                                    label={{
                                        smoking: t(active ? 'yes' : 'no'),
                                        diabetes: t(active ? 'disease_active' : 'disease_inactive'),
                                        infarct: t(active ? 'disease_active' : 'disease_inactive')
                                    }[disease]}
                                    type={'toggle'}
                                    value={active}
                                    checkedValue={true}
                                    uncheckedValue={false}
                                    onChange={() => setActive(!active)}
                                />
                            </div>
                        </>
                    )}

                    <Input label={t('note')}>
                        <textarea value={note} onChange={e => setNote(e.target.value)}/>
                    </Input>
                </div>

            </div>

            <div className={modalStyles.modalButtons}>
                <Button type={'reset'} onClick={() => onCancel()}>{t('cancel')}</Button>
                <Button type={'submit'}>{t('save_close')}</Button>
            </div>
        </form>
    );
};

export default CardAnamnesisForm;