import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../module/redux/store';
import ApiClient from '../../service/apiClient';
import {sortBy, treeToList} from '../../service/util';

export type CardType = 'analysis' | 'diary' | 'doctor';

interface SliceType {
    loading: boolean;
    type?: CardType;
    categories: KmrCategory[];
    features: {[key: number]: CategoryFeature};
    records: CommonRecord[];
}

const initialState: SliceType = {
    loading: false,
    categories: [],
    features: {},
    records: []
};

const getType = (api: {getState: FixType}) => {
    const state = api.getState() as RootState;
    return state.cardPage.type as string;
};

const sortRecords = (records: CommonRecord[]) => {
    if (records[0]?.datetime_at) return sortBy(records, 'datetime_at', true);
    if (records[0]?.record_at) return sortBy(records, 'record_at', true);
    return records;
};

export const fetchCategories = createAsyncThunk('fetch/card/categories', async (_, api) => {
    return await ApiClient.fetchCategory(getType(api));
});

export const fetchRecords = createAsyncThunk('fetch/card/records', async (args: {from?: string, to?: string}|undefined,  api) => {
    return await ApiClient.getRecords(getType(api), args?.from, args?.to);
});

export const saveRecord = createAsyncThunk('save/card/records', async (args: {record: CommonRecord, delFiles?: RecordAttachment[], newFiles?: RecordAttachment[]}, api) => {
    const type = getType(api);
    const {record, delFiles, newFiles} = args;

    const data = await ApiClient[record.id ? 'updateRecord' : 'createRecord'](type, record);

    if (type === 'analysis') {
        let docs = data.documents ?? [];

        const delFileIds = (delFiles?.filter(f => f.id).map(f => f.id) ?? []) as number[];
        if (delFileIds.length) {
            try {
                await ApiClient.deleteDocuments(delFileIds);
                docs = docs.filter(d => !d.id || !delFileIds.includes(d.id));
            } catch (e) {
                console.log('failed to delete file', e);
            }
        }

        for (const file of (newFiles ?? [])) {
            try {
                if (data.id) {
                    const doc = await ApiClient.uploadDocument(data.id, file);
                    docs.push(doc);
                }
            } catch (e) {
                console.log('failed to upload file', e);
            }
        }

        data.documents = docs;
    }

    return {id: record.id, data};
});

export const deleteRecords = createAsyncThunk('delete/card/records', async (ids: number[],  api) => {
    await ApiClient.deleteRecords(getType(api), ids);
    return ids;
});

const slice = createSlice({
    name: 'cardPage',
    initialState,
    reducers: {
        setType: (state, action: PayloadAction<SliceType['type']>) => {
            state.type = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        resetCard: (state) => {
            delete state.type;
            state.categories = [];
            state.features = {};
            state.records = [];
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.categories = action.payload;
                state.features = {};
                state.categories.flatMap(c => treeToList(c)).filter(c => c.features.length).forEach(c =>
                    c.features.forEach(f => state.features[f.id] = f)
                );
            })
            .addCase(fetchRecords.fulfilled, (state, action) => {
                state.records = sortRecords(action.payload);
            })
            .addCase(saveRecord.fulfilled, (state, action) => {
                if (action.payload.id) {
                    state.records = sortRecords(state.records.map(r => r.id === action.payload.id ? action.payload.data : r));
                } else {
                    state.records = sortRecords([action.payload.data, ...state.records]);
                }
            })
            .addCase(deleteRecords.fulfilled, (state, action) => {
                state.records = state.records.filter((i) => !i.id || !action.payload.includes(i.id));
            })
            .addMatcher(
                action => action.type.endsWith('/pending'),
                state => { state.loading = true; }
            )
            .addMatcher(
                action => action.type.endsWith('/fulfilled'),
                state => { state.loading = false; }
            )
            .addMatcher(
                action => action.type.endsWith('/rejected'),
                state => { state.loading = false; }
            );
    }
});

export const {setType, setLoading, resetCard} = slice.actions;

export default slice.reducer;