import { configureStore } from '@reduxjs/toolkit';
import dynamicPageReducer from '../../page/Dynamic/slice';
import cardPageReducer from '../../page/Card/slice';
import pressureWidgetReducer from '../../component/Widget/Pressure/slice';
import drugsWidgetReducer from '../../component/Widget/Drugs/slice';
import diaryWidgetReducer from '../../component/Widget/Diary/slice';
import diagnosesWidgetReducer from '../../component/Widget/Diagnoses/slice';
import analysisWidgetReducer from '../../component/Widget/Analysis/slice';
import userReducer from '../../page/Settings/Profile/slice';
import snackbarReducer from '../../component/Snackbar/slice';
import settingsAccessReducer from '../../page/Settings/Access/slice';
import contactsPageReducer from '../../page/MyOffice/Contacts/slice';

const store = configureStore({
    reducer: {
        dynamicPage: dynamicPageReducer,
        cardPage: cardPageReducer,
        pressureWidget: pressureWidgetReducer,
        drugsWidget: drugsWidgetReducer,
        diaryWidget: diaryWidgetReducer,
        diagnosesWidget: diagnosesWidgetReducer,
        analysisWidget: analysisWidgetReducer,
        user: userReducer,
        snackbar: snackbarReducer,
        settingsAccess: settingsAccessReducer,
        contactsPage: contactsPageReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;