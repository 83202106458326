import modalStyles from '../../Modal/style.module.css';

import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Input, {InputStyles} from '../../Input';
import Button from '../../Button';
import ToggleGroup from '../../ToggleGroup';
import apiClient from '../../../service/apiClient';
import {clone, treeToList} from '../../../service/util';
import dayjs from 'dayjs';
import Checkbox from '../../Checkbox';
import FeelingInputRange from '../../InputRange';

type ThisComponentProps = {
    record?: DiaryRecord,
    onSubmit(record: DiaryRecord): Promise<boolean>,
    onCancel(): void,
}

const PRESSURE_FEATURE_SYST_ID = 3;
const PRESSURE_FEATURE_DIAST_ID = 4;
const FEELING_FEATURE_ID = 17;
const FEELING_NOTE_FEATURE_ID = 18;

const CardDiaryForm = ({record, onSubmit, onCancel} : ThisComponentProps) => {
    const { t } = useTranslation();

    const [record_category_id, setRecordCategoryId] = useState<number>(record?.category_id || 0);
    const [categories, setCategories] = useState<KmrCategory[]>([]);
    const [categoriesList, setCategoriesList] = useState<KmrCategory[]>([]);
    const [category_id, setCategoryId] = useState<number>(0);
    const [subcategories, setSubcategories] = useState<KmrCategory[]>([]);
    const [subcategory_id, setSubcategoryId] = useState<number>(record?.category_id || 0);
    const [features, setFeatures] = useState<CategoryFeature[]>([]);
    const [record_at, setRecordAt] = useState<string>(record?.record_at || dayjs().utc().format('YYYY-MM-DD'));
    const [time, setTime] = useState<string>(dayjs(record?.datetime_at).format('HH:mm'));
    const [values, setValues] = useState<RecordValue[]>(clone(record?.values || []));

    async function fetchCategories() {
        try {
            const data = await apiClient.getDiaries();

            if (record_category_id === 0) {
                setRecordCategoryId(data[0].id);
            }

            setCategoriesList(treeToList(data[0]));

            setCategories(data[0].children);
        } catch (e) {
            setRecordCategoryId(0);
            setCategories([]);
        }
    }

    async function handleSubmit(e: FixType) {
        e.preventDefault();

        const [hours, minutes] = time.split(':').map((i) => +i);

        const result = await onSubmit({
            id: record?.id,
            category_id: record_category_id,
            values: values.filter((i) => i.value !== undefined),
            record_at,
            datetime_at: dayjs(record_at).set('hour', hours).set('minute', minutes).utc().format('YYYY-MM-DDTHH:mm:ss'),
            local_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        if (result) {
            console.log('handleSubmit OK');
        } else {
            console.log('handleSubmit BAD');
        }
    }

    const populateFeatures = (features: CategoryFeature[]) => {
        const tmpValues = values;

        for (const f of features) {
            const value = tmpValues.find((i) => i.feature_id === f.id);

            if (!value) {
                let value = undefined;
                if (f.type === 'boolean') value = 'false';
                else if (f.id === FEELING_FEATURE_ID) value = 0;
                else if (f.id === FEELING_NOTE_FEATURE_ID) value = '';

                tmpValues.push({
                    feature_id: f.id,
                    value,
                    measure: null
                });
            }
        }

        setFeatures(features);
        setValues(tmpValues);
    };

    const onCategoryChange = (id: number) => {
        if (id === category_id) {
            setCategoryId(0);
            setFeatures([]);
            setSubcategories([]);
            setSubcategoryId(0);
        } else {
            const category = categoriesList.find((i) => i.id === id);

            if (category) {
                populateFeatures(category.features);

                setSubcategoryId(0);
                setSubcategories(category.children);
                setCategoryId(id);
            }
        }
    };

    const onSubcategoryChange = (id: number) => {
        const category = categoriesList.find((i) => i.id === id);

        if (category) {
            populateFeatures(category.features);
            setSubcategoryId(id);
        }
    };

    const onValueChange = (id: number, e: FixType) => {
        const value = e.target.value === '' ? undefined : e.target.value;
        const data = values.map((i) => i.feature_id !== id ? i : {...i, value});

        setValues(data);
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className={modalStyles.modalGrid}>
                <div className={InputStyles.inlineInputs}>
                    <Input label={t('date')}>
                        <input
                            type={'date'}
                            required={true}
                            value={record_at}
                            onChange={(e) => setRecordAt(e.target.value)}
                        />
                    </Input>

                    <Input label={t('time')}>
                        <input
                            type={'time'}
                            required={true}
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                        />
                    </Input>
                </div>

                <ToggleGroup
                    onChange={(id) => onCategoryChange(id)}
                    className={'insideCardDiary'}
                    options={categories.map((i) => {
                        const isFilled = values
                            .filter((v) => i.features.map((f) => f.id).includes(v.feature_id))
                            .some((v) => v.value !== undefined);

                        return {
                            name: t(i.name),
                            value: i.id,
                            active: category_id === i.id,
                            filled: isFilled
                        };
                    })}
                />

                {subcategories.length > 0 && categories.filter((i) => i.id === category_id)
                    .map((i) => <div key={i.id}>
                        <Input label={t(i.name)}>
                            <select
                                className={InputStyles.input}
                                required={true}
                                value={subcategory_id}
                                onChange={(e) => onSubcategoryChange(+e.target.value)}
                            >
                                <option value="" />
                                {subcategories.map((i) => <optgroup key={i.id} label={t(i.name)}>
                                    {i.children.map((i) => <option key={i.id} value={i.id}>{t(i.name)}</option>)}
                                </optgroup>)}
                            </select>
                        </Input>
                    </div>)
                }

                {features.length > 0 && features.map((f) => {
                    const value = values.find((v) => v.feature_id === f.id);

                    if (value) {
                        let placeholder = '';
                        if (f.id === PRESSURE_FEATURE_SYST_ID) placeholder = '120';
                        if (f.id === PRESSURE_FEATURE_DIAST_ID) placeholder = '80';

                        let measure = value.measure ?? f.measure;
                        if (f.id === FEELING_NOTE_FEATURE_ID) measure = '';

                        if (f.id === FEELING_FEATURE_ID) {
                            return <FeelingInputRange key={f.id} value={value.value ? +value.value : 0} onChange={(e) => onValueChange(f.id, e)}/>;
                        }

                        if (f.type === 'boolean') {
                            return <Checkbox
                                key={f.id}
                                label={t('arrhythmia')}
                                type="toggle"
                                value={`${value.value}`.toLowerCase()}
                                uncheckedValue={'false'}
                                checkedValue={'true'}
                                onChange={(e) => onValueChange(f.id, e)}
                            />;
                        }

                        if (f.type === 'enum') {
                            return (
                                <Input key={f.id} label={`${t(f.name)}${measure ? ` (${t(measure)})` : ''}`}>
                                    <select
                                        className={InputStyles.input}
                                        required={true}
                                        value={value.value ?? ''}
                                        onChange={(e) => onValueChange(f.id, e)}
                                    >
                                        <option value={''}></option>
                                        {f.values?.map((v: string, i) => (<option key={i} value={v}>{t(v)}</option>))}
                                    </select>
                                </Input>
                            );
                        }

                        return (
                            <Input key={f.id} label={`${t(f.name)}${measure ? ` (${t(measure)})` : ''}`}>
                                <input
                                    type={f.type}
                                    required={f.id !== FEELING_NOTE_FEATURE_ID}
                                    placeholder={placeholder}
                                    value={value.value ?? ''}
                                    onChange={(e) => onValueChange(f.id, e)}
                                />
                            </Input>
                        );
                    }

                    return null;
                })}
            </div>

            <div className={modalStyles.modalButtons}>
                <Button type={'reset'} onClick={() => onCancel()}>{t('cancel')}</Button>
                <Button type={'submit'}>{t('save_close')}</Button>
            </div>
        </form>
    );
};

export default CardDiaryForm;
