import styles from './style.module.css';

import {ReactNode} from 'react';

type ThisComponentProps = {
    type: 'submit' | 'reset' | 'button',
    isSelected?: boolean,
    children: ReactNode,
    onClick?(): void,
}

const Button = ({type = 'submit', isSelected, children, onClick} : ThisComponentProps) => {
    return (
        <button type={type} className={`${styles.button} ${styles[type]} ${isSelected ? styles.submit : ''}`} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;
