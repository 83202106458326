import i18n from 'i18next';
import Detector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import translations from './translations';
import config from './config';
import intervalPlural from 'i18next-intervalplural-postprocessor';

// declare custom type options so the return is always a string.
declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false
    }
}

i18n
    .use(Detector)
    .use(ChainedBackend)
    .use(intervalPlural)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        supportedLngs: Object.keys(translations),
        fallbackLng: 'en', // use en if detected lng is not available
        ns: ['translation', 'localTranslation'],
        defaultNS: 'translation',
        fallbackNS: 'localTranslation',
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        backend: {
            backends: [
                HttpBackend,
                resourcesToBackend(translations)
            ],
            backendOptions: [{
                loadPath: `${config.api.host}${config.api.port ? `:${config.api.port}` : ''}${config.api.path ?? ''}/locales/{{lng}}/{{ns}}`
            }]
        }
    });

export default i18n;