import styles from './style.module.css';

type ThisComponentProps = {
    type: 'add' | 'delete',
    disabled?: boolean,
    onClick(): FixType,
}

const PanelAction = ({type, disabled = false, onClick} : ThisComponentProps) => {
    return (
        <button className={`${styles.self} ${styles[type]}`} disabled={disabled} onClick={onClick} />
    );
};

export default PanelAction;
