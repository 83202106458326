import dayjs from 'dayjs';
import i18n from '../../../../i18nConfig';
import { useMemo } from 'react';
import { Data as PlotData, ScatterData, Layout, Shape, PlotHoverEvent, PlotMouseEvent } from 'plotly.js';
import { Figure } from 'react-plotly.js';

import { useAppSelector } from '../../../../module/redux/hooks';
import { genScatterDataset, genBoxDataset, getFeelingColorByValue, getFeelingGlyphByValue } from '../../../../service/plotUtils';
import { FeatureName } from '../../../../enums';
import Plot from '../../../Plot';


const Y_RANGE = [-1.7, 1.7];
const Y_RANGE_BOX = [-1.2, 1.2];

const FeelingPlot = ({category}: {category: KmrCategory}) => {
    const state = useAppSelector((state) => state.diaryWidget);

    const [data, layout] = useMemo(() => {
        let data;
        let layout;

        if (state.mode === 'box') {
            [data, layout] = genBoxDataset(category, state.diary, state.from, state.to, [FeatureName.Feeling]);
            const [notes] = genBoxDataset(category, state.diary, state.from, state.to, [FeatureName.FeelingNote], noteValueConverter);
            modifyBoxDataset(data, layout, notes);
        } else {
            [data, layout] = genScatterDataset(category, state.diary, state.from, state.to, [FeatureName.Feeling]);
            const [notes] = genScatterDataset(category, state.diary, state.from, state.to, [FeatureName.FeelingNote]);
            modifyScatterDataset(data, layout, notes);
        }

        return [data, layout];
    }, [state.diary]);

    return <Plot data={data} layout={layout}
        advProps={{onInitialized, onHover, onUnhover}}
    />;
};

export default FeelingPlot;

const modifyScatterDataset = (data: Partial<ScatterData>[], layout: Partial<Layout>, notes: Partial<ScatterData>[]): void => {
    const xRangeInDays = layout.xaxis?.range ? dayjs(layout.xaxis?.range[1]).diff(layout.xaxis?.range[0], 'day') : 8;

    if (!layout.shapes) layout.shapes = [];

    /** Add smiles */
    data.forEach((d, i) => {
        data[i] = {
            ...d,
            mode: 'text',
            hoverinfo: 'none',
            textposition: 'middle center',
            textfont: {
                family: 'Glyphter',
                color: (d.y as number[])?.map(v => getFeelingColorByValue(v)),
                size: 14
            },
            text: (d.y as number[]).map(v => getFeelingGlyphByValue(v)),
            y: (d.y as number[])?.map(v => v - .07)
        };

        const shadows: Partial<Shape>[] = [];

        (d.x as string[])?.forEach((x, i) => {
            const y = d.y ? Number(d.y[i]) : 0;
            const color = getFeelingColorByValue(y);
            const shadowColor = getFeelingColorByValue(y, .1);

            /** Line to zero */
            layout.shapes?.push({ // Line to zero
                type: 'line',
                x0: x,
                x1: x,
                y0: 0,
                y1: y - Math.sign(y) * .14, // reduce height so that line does not overlap Smile
                line: { color, width: 2 }
            });

            /** Shadow */
            shadows.push({
                type: 'rect',
                x0: dayjs(x).add(-.7 * xRangeInDays, 'h').format(),
                x1: dayjs(x).add( .7 * xRangeInDays, 'h').format(),
                y0: Y_RANGE[0],
                y1: Y_RANGE[1],
                layer: 'below',
                fillcolor: shadowColor,
                line: { width: 0 }
            });
        });

        if (shadows.length) {
            shadows.reduce((prev, curr) => {
                const prevDate = dayjs(prev.x1);
                const currDate = dayjs(curr.x0);
                if (dayjs(prevDate).isAfter(currDate)) {
                    const centerDate = dayjs((+prevDate + +currDate) / 2);
                    prev.x1 = centerDate.format();
                    curr.x0 = centerDate.format();
                }
                return curr;
            });
            layout.shapes?.unshift(...shadows);
        }
    });

    /** Add note icon */
    notes.forEach((n) => {
        data.push({
            mode: 'text',
            name: '',
            customdata: n.y as string[],
            /** ignore because custom field */
            // eslint-disable-next-line
            // @ts-ignore
            customTooltip: null,
            text: '   C',
            textposition: 'middle right',
            textfont: {
                family: 'Glyphter',
                color: (n.x as string[])?.map(v => {
                    const i = data[0].x?.findIndex(x => x === v);
                    return getFeelingColorByValue(i !== undefined && data[0].y ? Number(data[0].y[i]) : 0);
                }),
                size: 14
            },
            hoverinfo: 'none',
            x: n.x,
            y: Array(n.x?.length || 0).fill(1.27)
        });
    });

    layout.xaxis = {
        ...layout.xaxis,
        showgrid: false,
        ticks: 'inside',
        ticklen: 6,
        tickcolor: '#4C5252',
    };

    layout.yaxis = {
        ...layout.yaxis,
        range: Y_RANGE,
        zeroline: true,
        zerolinecolor: '#B9B9B9',
        ticklen: 5,
        tickcolor: 'transparent',
        /** labelalias is missing in type LayoutAxis */
        // eslint-disable-next-line
        // @ts-ignore
        labelalias: {'1': i18n.t('tick_good'), '0': '', '−1': i18n.t('tick_bad')}
    };
};

const modifyBoxDataset = (data: Partial<PlotData>[], layout: Partial<Layout>, notes: Partial<PlotData>[]): void => {
    /** Remove median info from hovertext
     *  Add Notes to hovertext */
    data.filter((d: FixType) => d.mode === 'markers').forEach((d: FixType) => {
        if (typeof d.hovertext === 'string' && d.hovertext) {
            d.hovertext = d.hovertext.replace(/<\/b><br>.+\s-?[\d.]+$/, '</b><br><br>');

            const note: FixType = notes.find((n: PlotData) => n.type === 'box' && n.x && n.x[0] === d.x[0]);
            if (note) d.hovertext += note.y.join('<br>');
        }
    });

    if (!layout.shapes) layout.shapes = [];

    /** Background shapes */
    const bg: Partial<Shape> = { type: 'rect', xref: 'paper', x0: 0, x1: 1, layer: 'below', line: { width: 0 } };
    layout.shapes.push({ // green bg
        ...bg,
        y0: Y_RANGE_BOX[1] / 3,
        y1: Y_RANGE_BOX[1],
        fillcolor: 'rgba(7, 165, 94, .12)'
    });
    layout.shapes.push({ // yellow bg
        ...bg,
        y0: Y_RANGE_BOX[0] / 3,
        y1: Y_RANGE_BOX[1] / 3,
        fillcolor: 'rgba(248, 211, 58, .12)'
    });
    layout.shapes.push({ // red bg
        ...bg,
        y0: Y_RANGE_BOX[0] / 3,
        y1: Y_RANGE_BOX[0],
        fillcolor: 'rgba(255, 29, 8, .12)'
    });

    /** Lines on the right side */
    const line: Partial<Shape> = { type: 'line', xref: 'paper', x0: 1, x1: 1, layer: 'below' };
    layout.shapes.push({ // green
        ...line,
        y0: Y_RANGE_BOX[1] / 3,
        y1: Y_RANGE_BOX[1],
        line: { width: 6, color: 'rgb(7, 165, 94)' }
    });
    layout.shapes.push({ // yellow
        ...line,
        y0: Y_RANGE_BOX[0] / 3,
        y1: Y_RANGE_BOX[1] / 3,
        line: { width: 6, color: 'rgb(248, 211, 58)' }
    });
    layout.shapes.push({ // red
        ...line,
        y0: Y_RANGE_BOX[0] / 3,
        y1: Y_RANGE_BOX[0],
        line: { width: 6, color: 'rgb(255, 29, 8)' }
    });

    if (data.length) { // hack to display 'yaxis2'
        data.push({ x: [], y: [], yaxis: 'y2' });
    }

    const y0tick0 = Math.floor(Y_RANGE_BOX[1] / 2 * 10) / 10;
    const y2dtick = Math.floor(Y_RANGE_BOX[1] * 3 / 4 * 10) / 10;
    
    layout.yaxis = {
        ...layout.yaxis,
        showgrid: false,
        zeroline: false,
        range: Y_RANGE_BOX,
        autotick: false,
        tick0: -y0tick0, // -0.5
        dtick: y0tick0 * 2, // 1.1
        ticklen: 5,
        tickcolor: 'transparent',
        /** labelalias is missing in type LayoutAxis */
        // eslint-disable-next-line
        // @ts-ignore
        labelalias: {['−' + y0tick0]: i18n.t('tick_bad'), [y0tick0]: i18n.t('tick_good')}
    };

    layout.yaxis2 = {
        overlaying: 'y',
        side: 'right',
        showgrid: false,
        zeroline: false,
        autorange: false,
        range: Y_RANGE_BOX,
        fixedrange: true,
        autotick: false,
        tick0: 0,
        dtick: y2dtick,
        ticklen: 8,
        tickcolor: 'transparent',
        tickfont: {
            family: 'Glyphter',
            color: ['rgb(255, 29, 8)', 'blue', 'green'],
            size: 14
        },
        /** labelalias is missing in type LayoutAxis */
        // eslint-disable-next-line
        // @ts-ignore
        labelalias: {
            [y2dtick]: '<span style="color: rgb(7, 165, 94);">E</span>',
            '0': '<span style="color: rgb(248, 211, 58);">N</span>',
            ['−' + y2dtick]: '<span style="color: rgb(255, 29, 8);">T</span>',
        }
    };
};

const onInitialized = (figure: Readonly<Figure>, graphDiv: Readonly<HTMLElement>) => {
    (figure.data as Array<FixType>).forEach((f) => {
        if (Object.hasOwn(f, 'customTooltip') && !f.customTooltip) {
            f.customTooltip = document.createElement('div');
            f.customTooltip.classList.add('custom-tooltip');

            f.customTooltip.style.position = 'absolute';
            f.customTooltip.style.display = 'none';
            f.customTooltip.style.top = '20px';
            f.customTooltip.style.maxWidth = '33rem';
            f.customTooltip.style.padding = '.9rem 1.8rem';
            f.customTooltip.style.background = '#4C5252';
            f.customTooltip.style.color = '#FFF';
            f.customTooltip.style.fontSize = '1.2rem';
            f.customTooltip.style.lineHeight = '1.6rem';
            f.customTooltip.style.borderRadius = '0 10px 10px 10px';

            graphDiv.appendChild(f.customTooltip);
        }
    });
};

const onHover = (event: Readonly<PlotHoverEvent>) => {
    event.points.forEach((p: FixType) => {
        if (Object.hasOwn(p.data, 'customTooltip') && !p.data.customTooltip) {
            let el = event.event.target as HTMLElement;
            while(!el.classList.contains('js-plotly-plot')) {
                el = el.parentElement as HTMLElement;
            }
            p.data.customTooltip = el.querySelector('.custom-tooltip');
        }

        if (p.data.customTooltip) {
            const t = p.data.customTooltip;
            t.style.display = '';
            t.innerHTML = p.customdata;
            t.style.top  = +p.yaxis.l2p(p.y) + p.yaxis._offset + 10 + 'px';
            t.style.left = +p.xaxis.d2p(p.x) + p.xaxis._offset + 10 + 'px';
        }
    });
};

const onUnhover = (event: Readonly<PlotMouseEvent>) => {
    event.points.forEach((p: FixType) => {
        if (p.data.customTooltip) {
            const t = p.data.customTooltip;
            t.style.display = 'none';
            t.innerHTML = '';
        }
    });
};

const noteValueConverter = (value: FixType, datetime_at: string) => {
    return `<b>${dayjs(datetime_at).format('DD/MM HH:mm')}</b> - ${value.slice(0, 120) + (value.length > 120 ? '...' : '')}`;
};