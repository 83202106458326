import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import i18n from '../../../i18nConfig';
import ApiClient from '../../../service/apiClient';
import {clone, sortBy, treeToList} from '../../../service/util';
import {CategoryName} from '../../../enums';

interface SliceType {
    loading: boolean;
    from: string;
    to: string;
    data: WidgetAnalysisData[];
    features: CategoryFeature[];
    activeFeatures: {[key: number]: boolean};
    filledFeatures: {[key: number]: boolean};
}

const initialState: SliceType = {
    loading: false,
    from: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
    data: [],
    features: [],
    activeFeatures: {},
    filledFeatures: {}

};

const slice = createSlice({
    name: 'analysisWidget',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setDates: (state, action: PayloadAction<{from: string, to: string}>) => {
            state.from = action.payload.from;
            state.to = action.payload.to;
        },
        setData: (state, action: PayloadAction<WidgetAnalysisData[]>) => {
            state.data = sortBy(action.payload, 'datetime_at');
        },
        setFeatures: (state, action: PayloadAction<KmrCategory[]>) => {
            state.features = action.payload.filter(c => c.name === CategoryName.Blood)
                .map(c => treeToList(c).map(c => c.features))
                .flat(2).sort((a, b) => {
                    const aName = i18n.t(a.name).toLowerCase();
                    const bName = i18n.t(b.name).toLowerCase();

                    if (aName < bName) return -1;
                    if (aName > bName) return 1;
                    return 0;
                });
        },
        toggleFeature: (state, action: PayloadAction<number>) => {
            const id = action.payload;
            const tmp = clone(state.activeFeatures);

            if (tmp[id]) {
                delete tmp[id];
            } else {
                tmp[id] = true;
            }

            state.activeFeatures = tmp;
        },
        setFilledFeatures: (state) => {
            state.filledFeatures = state.features.filter(f =>
                state.data.some(d =>
                    d.values.some(v =>
                        v.feature_id === f.id))
            ).reduce((r, f) => ({...r, [f.id]: true}), {});

            if (Object.keys(state.activeFeatures).length === 0) {
                state.activeFeatures = clone(state.filledFeatures);
            }
        },
    },
});

export const actions = slice.actions;

export const init = (from: string, to: string) => (dispatch: FixType) => {
    dispatch(actions.setLoading(true));

    const data = ApiClient.getAnalysisWidgetData(from, to)
        .then((response) => dispatch(actions.setData(response)))
        .catch(() => dispatch(actions.setData([])));

    const cats = ApiClient.getTests()
        .then((response) => dispatch(actions.setFeatures(response)))
        .catch(() => dispatch(actions.setFeatures([])));

    Promise.all([data, cats]).then(() => dispatch(actions.setLoading(false)));
};

export default slice.reducer;