import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import apiClient from '../../../service/apiClient';

interface SliceType {
    loading: boolean;
    sharedCards: KmrShareRecord[];
}

const initialState: SliceType = {
    loading: false,
    sharedCards: [],
};

const slice = createSlice({
    name: 'settingsAccess',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setSharedCards: (state, action: PayloadAction<KmrShareRecord[]>) => {
            state.sharedCards = action.payload;
        },
        updateCard: (state, action: PayloadAction<KmrShareRecord>) => {
            const included = state.sharedCards.some((i) => i.uri === action.payload.uri);

            if (included) {
                state.sharedCards = [action.payload, ...state.sharedCards.filter((i) => i.uri !== action.payload.uri)];
            } else {
                state.sharedCards = [action.payload, ...state.sharedCards];
            }
        },
    },
});

export const actions = slice.actions;

export const init = () => (dispatch: FixType) => {
    dispatch(actions.setLoading(true));

    Promise.all([
        apiClient.getAccessibleCards(),
        apiClient.getRequestedCards(),
        apiClient.getSharedCards(),
    ]).then(([accessible, requested, shared]) => {
        accessible.forEach((i) => i.myCard = false);
        shared.forEach((i) => i.myCard = true);
        requested.forEach((i) => i.myCard = i.access && !i.own);
        dispatch(actions.setSharedCards([...accessible, ...requested, ...shared]));
    }).finally(() => dispatch(actions.setLoading(false)));
};

export const reject = (uri: string) => (dispatch: FixType) => {
    apiClient.rejectShareRequest(uri).then(() => dispatch(init()));
};

export const revoke = (uri: string) => (dispatch: FixType) => {
    apiClient.revokeShareRequest(uri).then(() => dispatch(init()));
};

export default slice.reducer;