import styles from './style.module.css';

interface ToggleGroupOption {
    name: string;
    value: FixType;
    filled: boolean;
    active: boolean;
}

type ThisComponentProps = {
    options: ToggleGroupOption[];
    className?: string;
    onChange(value: FixType): FixType;
}

const ToggleGroup = ({options, onChange, className} : ThisComponentProps) => {
    return <div className={`${styles.wrap} ${className ? styles[className] : ''}`}>
        {options.map((i) => {
            return <button type="button" key={i.value}
                className={`${styles.toggle} ${i.filled ? styles.filled : ''} ${i.active ? styles.active : ''}`}
                onClick={() => onChange(i.value)}
            >{i.name}</button>;
        })}
    </div>;
};

export default ToggleGroup;
