import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Page from '../../../component/Page';
import Panel from '../../../component/Panel';
import Modal from '../../../component/Modal';
import SortTable from '../../../component/SortTable';
import apiClient from '../../../service/apiClient';
import PanelAction from '../../../component/PanelAction';
import CardDrugForm from '../../../component/form/CardDrug';
import dayjs from 'dayjs';
import {sortBy} from '../../../service/util';
import {actions} from '../../../component/Snackbar/slice';
import {SNACKBAR_TYPE_DANGER, SNACKBAR_TYPE_SUCCESS} from '../../../component/Snackbar';
import {useAppDispatch} from '../../../module/redux/hooks';

const CardDrug = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState<boolean>(true);
    const [records, setRecords] = useState<KmrDrug[]>([]);
    const [openedRecord, setOpenedRecord] = useState(undefined);
    const [selectedRecords, setSelectedRecords] = useState<KmrDrug[]>([]);

    async function fetchRecords() {
        setLoading(true);

        try {
            const records = await apiClient.getDrugs();

            setRecords(sortBy(records, 'record_in', true));
        } catch (e) {
            setRecords([]);
        }

        setLoading(false);
    }

    async function saveRecord(record: KmrDrug): Promise<boolean> {
        try {
            let data: KmrDrug;

            if (record.id) {
                data = await apiClient.updateDrug(record) as KmrDrug;
            } else {
                data = await apiClient.createDrug(record) as KmrDrug;
            }

            if (record.id) {
                setRecords(records.map((i) => i.id === record.id ? data : i));
            } else {
                setRecords(sortBy([data, ...records], 'record_in', true));
            }

            setOpenedRecord(undefined);

            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t(`${record.id ? 'messages.success.update' : 'messages.success.create'}`)}));

            return true;
        } catch (e) {
            console.log(e);
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));

            return false;
        }
    }

    async function deleteSelectedRecords(): Promise<void> {
        try {
            const ids: number[] = selectedRecords.reduce((a: number[], i) => i.id ? [...a, i.id] : a, []);
            await apiClient.deleteDrugs(ids);

            setRecords(records.filter((i) => !i.id || !ids.includes(i.id)));

            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_SUCCESS, message: t('messages.success.delete')}));
        } catch (e) {
            console.log(e);
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));
        }
    }

    async function init() {
        await fetchRecords();
    }

    useEffect(() => {
        init();
    }, []);

    const columns = useMemo(() => [
        {
            Header: t('date'),
            accessor: 'record_in',
            disableSortBy: true,
            style: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
            Cell: ({value}: FixType) => dayjs(value).format('ll'),
        },
        {
            Header: t('time'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
            Cell: ({row: {original: {record_in}}}: FixType) => dayjs(record_in).format('HH:mm'),
        },
        {
            Header: t('medications'),
            Cell: ({row: {original: record}}: FixType) =>
                `${record.name} - ${record.dose} ${t(record.measure)} - ${record.value} (${t(record.form + '_interval', { postProcess: 'interval', count: record.value})})`,
        },
    ], [records]);

    const groupedRecords: FixType = useMemo(() => {
        let isEven = true;
        let prev = '';

        return records.map((v) => ({
            ...v,
            isEven: prev === (prev = v.record_in) ? isEven : (isEven = !isEven),
        }));
    }, [records]);

    return (
        <>
            <Page title={t('entering_values_medical_card')}>
                <Panel
                    title={t('medication')}
                    loading={loading}
                    actions={<>
                        <PanelAction type={'add'} onClick={() => setOpenedRecord({} as FixType)} />
                        <PanelAction type={'delete'} disabled={selectedRecords.length === 0} onClick={() => confirm(t('confirm_delete')) && deleteSelectedRecords()} />
                    </>}
                >
                    <SortTable
                        columns={columns}
                        data={groupedRecords as FixType}
                        onRowClick={setOpenedRecord}
                        onRowSelect={setSelectedRecords}
                        selectable={true}
                    />
                </Panel>
            </Page>

            <Modal
                isOpen={!!openedRecord}
                onClose={() => setOpenedRecord(undefined)}
                title={t('medication')}
            >
                <CardDrugForm
                    record={openedRecord}
                    onCancel={() => setOpenedRecord(undefined)}
                    onSubmit={saveRecord}
                />
            </Modal>
        </>
    );
};

export default CardDrug;
