import styles from './style.module.css';
import modalStyles from '../../Modal/style.module.css';

import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import InputWrap, {InputStyles} from '../../Input';
import Button from '../../Button';
import dayjs from 'dayjs';
import apiClient from '../../../service/apiClient';
import Autocomplete, {ISuggestion} from '../../Input/Autocomplete';
import {actions} from '../../Snackbar/slice';
import {SNACKBAR_TYPE_WARNING} from '../../Snackbar';
import {useDispatch} from 'react-redux';

type ThisComponentProps = {
    record: KmrDrug | undefined,
    onSubmit(drug: KmrDrug): Promise<boolean>,
    onCancel(): void,
}

const CardDrugForm = ({record, onSubmit, onCancel} : ThisComponentProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const initialDrug = {
        id: record?.id,
        name: record?.name || '',
        value: record?.value || 1,
        dose: record?.dose || '',
        form: record?.form || '',
        measure: record?.measure || '',
        data: record?.data || '',
        dosage_id: record?.id || 0,
        local_zone: '',
        record_in: ''
    };

    const [record_at, setRecordAt] = useState<string>(dayjs(record?.record_in).format('YYYY-MM-DD'));
    const [time, setTime] = useState<string>(dayjs(record?.record_in).format('HH:mm'));
    const [drugAutocomplete, setDrugAutocomplete] = useState<DrugAutocomplete>();
    const [options, setOptions] = useState<ISuggestion[]>([]);
    const [drug, setDrug] = useState<KmrDrug>(initialDrug);

    useEffect(() => {
        if (drugAutocomplete) {
            setDrug({
                ...drug,
                name: drugAutocomplete.name,
                dosage_id: drugAutocomplete.dosage.id,
                dose: drugAutocomplete.dosage.strength,
                form: drugAutocomplete.dosage.form,
                measure: drugAutocomplete.dosage.measure,
                data: JSON.stringify(drugAutocomplete)
            });
        } else {
            setDrug(initialDrug);
        }
    }, [drugAutocomplete]);

    async function handleSubmit(e: FixType) {
        e.preventDefault();

        if (drugAutocomplete) {
            const [hours, minutes] = time.split(':').map((i) => +i);

            const result = await onSubmit({
                ...drug,
                record_in: dayjs(record_at).set('hour', hours).set('minute', minutes).utc().format('YYYY-MM-DDTHH:mm:ss'),
                local_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
            });

            console.log(result ? 'handleSubmit OK' : 'handleSubmit BAD');
        } else {
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_WARNING, message: t('messages.warning.drug_required')}));
        }
    }

    async function fetchDrugSuggestions(searchTerm: string) {
        try {
            const data: DrugAutocomplete[] = await apiClient.getDrugsAutocomplete(searchTerm);
            setOptions(
                data.map((item: DrugAutocomplete) => ({
                    id: `${item.id}${item.dosage.id}`,
                    label: `${item.name} (${item.dosage.ingredients.map(ingredient => `${ingredient.drug.name} ${ingredient.strength} ${t(ingredient.measure)}`).join(', ')}) - ${t(item.dosage.form)}`,
                    data: item as FixType
                }))
            );
        } catch (e) {
            setOptions([]);
        }
    }

    const onDrugChange = (searchTerm: string) => {
        if (searchTerm.length >= 1) {
            fetchDrugSuggestions(searchTerm);
        } else {
            setOptions([]);
            setDrugAutocomplete(undefined);
        }
    };

    const onDrugSelect = (item: DrugAutocomplete) => {
        setDrugAutocomplete(item);
    };

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className={modalStyles.modalGrid}>
                <div className={InputStyles.inlineInputs}>
                    <InputWrap label={t('date')}>
                        <input
                            type={'date'}
                            required={true}
                            value={record_at}
                            onChange={(e) => setRecordAt(e.target.value)}
                        />
                    </InputWrap>

                    <InputWrap label={t('time')}>
                        <input
                            type={'time'}
                            required={true}
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                        />
                    </InputWrap>
                </div>

                <div className={`${styles.row} ${InputStyles.inlineInputs}`}>
                    <div className={styles.searchBox}>
                        <Autocomplete
                            label={t('medicine')}
                            defaultValue={drug.name}
                            suggestions={options}
                            onChange={onDrugChange}
                            onSelect={onDrugSelect}
                        />
                        {drugAutocomplete && (
                            <div className={styles.smallText}>
                                {drugAutocomplete.dosage.ingredients.map(ingredient =>
                                    <span key={ingredient.id}>{`${t('active_ingredient')}: ${ingredient.drug.name} - `}<b>{`${ingredient.strength} ${t(ingredient.measure)}`}</b></span>
                                )}
                            </div>
                        )}
                    </div>

                    <InputWrap label={t('quantity')}>
                        <input
                            type={'number'}
                            name={'value'}
                            required={true}
                            value={drug.value}
                            onChange={(e) => setDrug({...drug, value: +e.target.value})}
                        />
                    </InputWrap>
                </div>
            </div>

            <div className={modalStyles.modalButtons}>
                <Button type={'reset'} onClick={() => onCancel()}>{t('cancel')}</Button>
                <Button type={'submit'}>{t('save_close')}</Button>
            </div>
        </form>
    );
};

export default CardDrugForm;
