import styles from './style.module.css';

type ThisComponentProps = {
    card: KmrShareRecord,
    isSelected: boolean,
    onClick(card: KmrShareRecord): void,
}

const Patient = ({card, isSelected, onClick} : ThisComponentProps) => {
    return (
        <div className={`${styles.card} ${isSelected ? styles.selected : ''}`} onClick={() => onClick(card)}>
            <div className={styles.top}>
                <i className={styles.icon} />
                <div className={styles.name}>
                    <b>{card.owner_alias}</b>
                </div>
            </div>
        </div>
    );
};

export default Patient;