import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

import {useAppSelector} from '../../../module/redux/hooks';
import {CommonCard} from '../index';

const CardAnalysis = () => {
    const { t, i18n } = useTranslation();
    const {categories, features, records} = useAppSelector(state => state.cardPage);

    const filteredRecords = useMemo(() => {
        return records.map(p =>
            ({...p, values: p.values?.filter(v => features[v.feature_id])})
        ).filter(p => p.values?.length);
    }, [features, records]);

    const columns = useMemo(() => [
        {
            Header: t('date'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%'
            },
            accessor: 'datetime_at',
            disableSortBy: true,
            Cell: ({value}: FixType) => dayjs(value).format('ll')
        }, {
            Header: t('time'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%'
            },
            Cell: ({row: {original: {datetime_at}}}: FixType) =>
                dayjs(datetime_at).format('HH:mm')
        }, {
            Header: t('type'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%'
            },
            accessor: 'category_id',
            disableSortBy: true,
            Cell: ({value}: FixType) =>
                t(categories.find(c => c.id === value)?.name ?? '')
        }, {
            Header: t('indicators'),
            accessor: 'values',
            disableSortBy: true,
            Cell: ({value}: FixType) =>
                value?.map((i: RecordValue) =>
                    `${t((features[i.feature_id] || {}).name)}: ${i.value} (${t(i.measure ?? features[i.feature_id]?.measure ?? 'mg_dl')})`
                ).join(', ')
        }
    ], [categories, features, i18n.language]);

    return <CommonCard type={'analysis'} columns={columns}
        modalSize={'large'}
        filteredRecords={filteredRecords}
    />;
};

export default CardAnalysis;
