import { LayoutAxis, Margin, BoxPlotData } from 'plotly.js';

export const commonBoxData = (): Partial<BoxPlotData> => ({
    type: 'box',
    x: [], y: [],
    boxpoints: false,
    line: {
        width: 1,
        color: getComputedStyle(document.body).getPropertyValue('--primary-clr')
    },
    fillcolor: getComputedStyle(document.body).getPropertyValue('--primary-clr--translucent'),
    hoverinfo: 'none'
});

export const commonPlotAxis = (title?: string): Partial<LayoutAxis> => ({
    linecolor: '#B9B9B9',
    tickcolor: '#4C5252',
    ticks: 'outside',
    ticklen: 10,
    tickfont: {
        color: '#4C5252',
        family: 'Roboto, sans-serif',
        size: 10,
    },
    ...(title ? {title: {
        text: `<b>${title}</b>`,
        standoff: 100,
    }} : {}),
    titlefont: {
        color: getComputedStyle(document.body).getPropertyValue('--primary-clr--bright'),
        family: 'Roboto, sans-serif',
        size: 12,
    },
    autorange: true
});

export const commonBoxAxis = (title?: string): Partial<LayoutAxis> => ({
    ...commonPlotAxis(title),
    linecolor: '#4C5252',
    showgrid: true,
    gridcolor: 'rgba(185, 185, 185, .2)',
    autorange: false,
    fixedrange: true
});

export const commonPlotMargin = (): Partial<Margin> => ({
    l: 100,
    r: 0,
    b: 40,
    t: 0,
    pad: 4
});

export const commonBoxMargin = (): Partial<Margin> => ({
    l: 100,
    r: 50,
    b: 30,
    t: 0,
    pad: 0
});
