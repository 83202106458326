import Page from '../../../component/Page';
import {useTranslation} from 'react-i18next';

const Recognition = () => {
    const { t } = useTranslation();

    return (
        <Page title={t('digitization')}>
        </Page>
    );
};

export default Recognition;
