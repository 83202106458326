import i18n from '../../../../i18nConfig';
import { useMemo } from 'react';
import { Data as PlotData, ScatterData, Layout, Shape } from 'plotly.js';

import { useAppSelector } from '../../../../module/redux/hooks';
import {genScatterDataset, genBoxDataset, genReferenceShapes, getYaxisRange} from '../../../../service/plotUtils';
import Plot from '../../../Plot';


const Y_RANGE: [number, number] = [50, 200];
const REFERENCE: [number, number] = [80, 120];

const PressurePlot = ({category}: {category: KmrCategory}) => {
    const state = useAppSelector((state) => state.diaryWidget);

    const [data, layout] = useMemo(() => {
        let data: Partial<PlotData>[];
        let layout: Partial<Layout>;

        if (state.mode === 'box') {
            [data, layout] = genBoxDataset(category, state.diary, state.from, state.to);
            modifyBoxDataset(data, layout);
        } else {
            [data, layout] = genScatterDataset(category, state.diary, state.from, state.to);
            modifyScatterDataset(data, layout);
        }

        layout.shapes = [
            ...genReferenceShapes(Y_RANGE, REFERENCE),
            ...(layout.shapes ?? [])
        ];

        return [data, layout];
    }, [state.diary]);

    return <Plot data={data} layout={layout} />;
};

export default PressurePlot;

const modifyScatterDataset = (data: Partial<PlotData>[], layout: Partial<Layout>) => {
    const range = getYaxisRange(data as Partial<ScatterData>[], 1.15);
    layout.yaxis = {
        ...layout.yaxis,
        range,
        ...(range[1] - range[0] < 150 ? {
            tick0: 80,
            dtick: 20,
            minor: {
                tick0: 90,
                dtick: 20,
                ticklen: 5
            }
        } : {})
    };
};

const modifyBoxDataset = (data: Partial<PlotData>[], layout: Partial<Layout>) => {
    const yRange = layout.yaxis?.range || Y_RANGE;

    if (!layout.shapes) layout.shapes = [];

    /** Lines on the right side */
    const line: Partial<Shape> = { type: 'line', xref: 'paper', x0: 1, x1: 1, layer: 'below' };
    layout.shapes.push({ // from 100 to top
        ...line,
        y0: 100,
        y1: yRange[1],
        line: { width: 6, color: 'rgb(180, 198, 26)' }
    });
    layout.shapes.push({ // from 100 to down
        ...line,
        y0: yRange[0],
        y1: 100,
        line: { width: 6, color: 'rgb(26, 198, 198)' }
    });

    if (data.length) { // hack to display 'yaxis2'
        data.push({ x: [], y: [], yaxis: 'y2' });
    }

    const tick0 = Math.floor((yRange[0] + 100) / 2);
    const dtick = Math.floor((yRange[1] + 100) / 2) - tick0;

    layout.yaxis2 = {
        overlaying: 'y',
        side: 'right',
        showgrid: false,
        zeroline: false,
        range: yRange,
        fixedrange: true,
        autotick: false,
        tick0,
        dtick,
        ticklen: 5,
        tickcolor: 'transparent',
        tickfont: {
            color: '#4C5252',
            family: 'Roboto, sans-serif',
            size: 10,
        },
        /** labelalias is missing in type LayoutAxis */
        // eslint-disable-next-line
        // @ts-ignore
        labelalias: {[tick0]: i18n.t('tick_diastolic'), [tick0 + dtick]: i18n.t('tick_systolic')}
    };
};