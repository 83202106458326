import styles from './style.module.css';
import {ChangeEvent} from 'react';

type ThisComponentProps = {
    label?: string;
    type?: 'checkbox' | 'radio' | 'toggle';
    size?: 'small' | 'medium' | 'big';
    value: FixType;
    uncheckedValue?: FixType;
    checkedValue?: FixType;
    onChange(e: ChangeEvent<FixType>): void;
}

const Checkbox = ({label, value, type, size, uncheckedValue, checkedValue, onChange} : ThisComponentProps) => {
    return <label className={`${styles.main} ${styles[type || '']} ${styles[size || '']}`}>
        <input
            type="checkbox"
            checked={value === checkedValue}
            value={value === checkedValue ? uncheckedValue : checkedValue}
            onChange={onChange}
        />
        <span>{label}</span>
    </label>;
};

export default Checkbox;