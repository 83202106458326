import styles from './style.module.css';

import {useEffect, useMemo} from 'react';
import Panel from '../../Panel';
import {useTranslation} from 'react-i18next';
import DateRangePicker from '../../DateRangePicker';
import Plot from 'react-plotly.js';
import {useAppDispatch, useAppSelector} from '../../../module/redux/hooks';
import {actions, init} from './slice';
import {commonPlotAxis, commonPlotMargin} from '../../../service/plotChunks';
import {Data as Traces, Shape} from 'plotly.js';
import dayjs from 'dayjs';

const PressureWidget = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.pressureWidget);

    const onChangeRange = (from: string, to: string) => {
        dispatch(actions.setDates({from, to}));
    };

    useEffect(() => {
        dispatch(init(state.from, state.to));
    }, [state.from, state.to]);

    const {traces, shapes}: {traces: Traces[], shapes: Partial<Shape>[]} = useMemo(() => {
        const groupedDrugs = state.drugs.reduce<Record<string, WidgetDrugsData[]>>((a, i) => {
            a[`${i.name}`] ? a[`${i.name}`].push(i) : a[`${i.name}`] = [i];

            return a;
        }, {});

        const traces: Traces[] = Object.values(groupedDrugs).map((trace) => ({
            x: trace.map((i) => i.date),
            y: trace.map((i) => i.name),
            text: trace.map((i) => `${i.value}x${i.dose}${i.measure || ''}`),
            type: 'scatter',
            mode: 'text+markers',
            textfont : {
                family: 'Roboto, sans-serif',
                size: 10,

            },
            line: {
                width: 1,
            },
            textposition: 'top center',
            marker: {
                size: 5
            }
        }));

        const shapes: Partial<Shape>[] = [];

        if (state.trends?.prediction && state.trends?.original) {
            const {pulse, pressure: {systolic, diastolic}} = state.trends.prediction;
            const originalHearData: WidgetHeartData[] = state.trends.original;

            originalHearData.forEach((i) => i.arrhythmia && shapes.push({
                type: 'line',
                x0: i.date,
                x1: i.date,
                y0: 0,
                y1: .75,
                xref: 'x',
                yref: 'y2',
                line:{
                    color: '#B73333',
                    width: 2,
                }
            }));

            traces.push({
                name: t('arrhythmia'),
                y: [0, 1],
                xaxis: 'x',
                yaxis: 'y2',
                marker: {
                    color: 'rgb(87, 204, 169)'
                }
            });

            traces.push({
                name: t('pulse'),
                x: originalHearData.map(data => data.date),
                y: originalHearData.map(data => data.pulse ?? null),
                mode: 'lines+markers',
                xaxis: 'x',
                yaxis: 'y3',
                line: {shape: 'spline'},
                marker: {
                    color: 'rgb(87, 204, 169)'
                }
            });

            traces.push({
                name: t('pulse_trend'),
                x: pulse.dates,
                y: pulse.trend,
                mode: 'lines',
                xaxis: 'x',
                yaxis: 'y3',
                line: {
                    dash: 'dot',
                    width: 1
                },
                hoverinfo: 'none'
            });

            traces.push({
                name: t('systolic'),
                x: originalHearData.map(data => data.date),
                y: originalHearData.map(data => data.syst ?? null),
                xaxis: 'x',
                yaxis: 'y4',
                mode: 'lines+markers',
                line: {shape: 'spline'},
                marker: {
                    color: 'rgb(180, 198, 26)'
                }
            });

            traces.push({
                name: t('systolic_trend'),
                x: systolic.dates,
                y: systolic.trend,
                mode: 'lines',
                xaxis: 'x',
                yaxis: 'y4',
                line: {
                    dash: 'dot',
                    width: 1
                },
                hoverinfo: 'none'
            });

            traces.push({
                name: t('diastolic'),
                x: originalHearData.map(data => data.date),
                y: originalHearData.map(data => data.dist ?? null),
                xaxis: 'x',
                yaxis: 'y4',
                mode: 'lines+markers',
                line: {shape: 'spline'},
                marker: {
                    color: 'rgb(237, 124, 89)'
                }
            });

            traces.push({
                name: t('diastolic_trend'),
                x: diastolic.dates,
                y: diastolic.trend,
                mode: 'lines',
                xaxis: 'x',
                yaxis: 'y4',
                line: {
                    dash: 'dot',
                    width: 1
                },
                hoverinfo: 'none'
            });
        }

        if (state.moods.length) {
            const moods = state.moods;

            shapes.push({
                type: 'line',
                xref: 'paper',
                x0: 0,
                y0: 0.5,
                x1: 1,
                y1: 0.5,
                yref: 'y5',
                line:{
                    color: '#B9B9B9',
                    width: 1,
                }
            });

            const good: FixType = {
                mode : 'markers',
                x: [],
                y: [],
                xaxis: 'x',
                yaxis: 'y5',
                marker: {
                    size: 10,
                    color: '#B4C61A'
                }
            };

            const bad: FixType = {
                mode : 'markers',
                x: [],
                y: [],
                xaxis: 'x',
                yaxis: 'y5',
                marker: {
                    size: 10,
                    color: '#B73333'
                }
            };

            moods.forEach((item: KmrMoodData) => {
                if (item.mood === 'good') {
                    good.x.push(item.record_at);
                    good.y.push(t('good'));

                    shapes.push({
                        type: 'line',
                        x0: item.record_at,
                        x1: item.record_at,
                        y0: 0.5,
                        y1: t('good'),
                        xref: 'x',
                        yref: 'y5',
                        line:{
                            color: '#B4C61A',
                            width: 2,
                        }
                    });
                }

                if (item.mood === 'bad') {
                    bad.x.push(item.record_at);
                    bad.y.push(t('bad'));

                    shapes.push({
                        type: 'line',
                        x0: item.record_at,
                        x1: item.record_at,
                        y0: 0.5,
                        y1: t('bad'),
                        xref: 'x',
                        yref: 'y5',
                        line:{
                            color: '#B73333',
                            width: 2,
                        }
                    });
                }
            });

            traces.push(bad, good);
        }

        return {traces, shapes};
    }, [state.trends, state.drugs]);

    return <Panel
        title={t('heart_condition')}
        loading={state.loading}
        actionsAsList={true}
        actions={<DateRangePicker type={'date'} range={[state.from, state.to]} onChangeRange={onChangeRange} hasWeekMonthOptions={true} />}
    >
        <div className={styles.charts}>
            {traces.some((i: FixType) => i.x?.length > 0) ? (<Plot
                data={traces}
                layout={{
                    hovermode: 'x unified',
                    showlegend: false,
                    grid: {
                        rows: 5,
                        columns: 1,
                        subplots:['xy', 'xy3', 'xy4', 'xy5'],
                        roworder: 'top to bottom'
                    },
                    xaxis: {
                        ...commonPlotAxis(),
                        autorange: false,
                        range: [state.from, dayjs(state.to).add(1, 'day').format('YYYY-MM-DD')] /** Add one day for visibility of last markers **/
                    },
                    yaxis: {
                        ...commonPlotAxis(t('medication')),
                        showgrid: false,
                        domain: [.750, 1.000]
                    },
                    yaxis2: {
                        ...commonPlotAxis(t('arrhythmia')),
                        showgrid: false,
                        showticklabels: false,
                        ticklen: 0,
                        domain: [.600, .725]
                    },
                    yaxis3: {
                        ...commonPlotAxis(t('pulse')),
                        showgrid: false,
                        domain: [.375, .575]
                    },
                    yaxis4: {
                        ...commonPlotAxis(t('pressure')),
                        showgrid: false,
                        domain: [.150, .350]
                    },
                    yaxis5: {
                        ...commonPlotAxis(t('mood')),
                        showgrid: false,
                        domain: [0, .125]
                    },
                    margin: commonPlotMargin(),
                    shapes
                }}
                config={{
                    displayModeBar: false,
                    scrollZoom: true
                }}
                useResizeHandler={true}
                className={styles.chart}
            />) : <div>{t('no_data')}</div>}
        </div>
    </Panel>;
};

export default PressureWidget;
