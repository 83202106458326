import styles from './style.module.css';

import {ReactNode} from 'react';

type ThisComponentProps = {
    title: ReactNode,
    children: ReactNode,
}

const Submenu = ({title, children} : ThisComponentProps) => {
    return (
        <aside className={styles.menu}>
            <a className={styles.logo} href={'/'} />

            <div className={styles.title}>
                {title}
            </div>

            {children}
        </aside>
    );
};

export default Submenu;
