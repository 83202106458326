import styles from './style.module.css';

import {ChangeEvent, KeyboardEvent, useEffect, useState} from 'react';
import InputWrap from '../Input';
import useOutsideClick from '../../hooks/useOutsideClick';
import {useTranslation} from 'react-i18next';

interface ThisTypeProps {
    label: string;
    suggestions: FixType[];
    defaultValue?: string;
    onChange(searchTerm: string): void;
    onSelect(item: FixType): void;
}

export interface ISuggestion {
    id: number | string;
    label: string;
    data: FixType[];
}

const KEYS = {
    ARROW_UP: [38, 'ArrowUp'],
    ARROW_DOWN: [40, 'ArrowDown'],
    ENTER: [13, 'Enter']
};

const Autocomplete = ({label, defaultValue, suggestions, onChange, onSelect}: ThisTypeProps) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState<string>(defaultValue || '');
    const [suggestionsActive, setSuggestionsActive] = useState<boolean>(false);
    const [suggestionIndex, setSuggestionIndex] = useState<number>(0);

    useEffect(() => setSearchTerm(defaultValue || ''), [defaultValue]);

    const handleClickOutside = () => setSuggestionsActive(false);
    const ref = useOutsideClick(handleClickOutside);

    const onChangeHandler = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.value;

        setSuggestionsActive(!!value.length);
        setSearchTerm(value);
        setSuggestionIndex(0);
        onChange(value);
    };

    const onKeyDownHandler = (evt: KeyboardEvent<HTMLInputElement>) => {
        const key = evt.key || evt.keyCode;

        if (KEYS.ARROW_UP.includes(key)) {
            if (suggestionIndex !== 0) setSuggestionIndex(suggestionIndex - 1);
        } else if (KEYS.ARROW_DOWN.includes(key)) {
            if (suggestionIndex + 1 !== suggestions.length) setSuggestionIndex(suggestionIndex + 1);
        } else if (KEYS.ENTER.includes(key)) {
            evt.preventDefault();
            onSelectHandler(suggestions[suggestionIndex]);
        }
    };

    const onSelectHandler = (suggestion: ISuggestion) => {
        onSelect(suggestion.data);
        setSuggestionsActive(false);
    };

    return (
        <div ref={ref}>
            <InputWrap label={label} className={`${styles.autocompleteWrapper} ${suggestionsActive && !!suggestions.length ? styles.autocompleteActive : ''}`}>
                <input
                    required
                    type={'search'}
                    value={searchTerm}
                    onChange={onChangeHandler}
                    onKeyDown={onKeyDownHandler}
                    onClick={() => suggestions.length && !suggestionsActive ? setSuggestionsActive(true) : null}
                />
                {suggestionsActive && (
                    <ul className={styles.autocompleteSuggestions}>
                        {!!suggestions.length && suggestions.map((suggestion, index) =>
                            <li
                                className={index === suggestionIndex ? styles.active : ''}
                                key={suggestion.id}
                                onClick={(evt) => {
                                    evt.preventDefault();
                                    setSuggestionIndex(index);
                                    onSelectHandler(suggestion);
                                }}
                            >
                                {suggestion.label}
                            </li>
                        )}
                        {!suggestions.length && !!searchTerm.length && (
                            <li>{t('no_results')}</li>
                        )}
                    </ul>
                )}
            </InputWrap>
        </div>
    );
};

export default Autocomplete;