import styles from '../../module/login/styles.module.css';

import Input from '../../component/Input';
import Button from '../../component/Button';
import Panel from '../../component/Panel';
import {useState} from 'react';
import apiClient from '../../service/apiClient';
import Page from '../../component/Page';
import LoginSidePanel from '../../component/LoginSidePanel';
import {useTranslation} from 'react-i18next';
import {actions} from '../../component/Snackbar/slice';
import {SNACKBAR_TYPE_DANGER} from '../../component/Snackbar';
import {useAppDispatch} from '../../module/redux/hooks';


const ResetPassword = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

    const handleSubmit = async (e: FixType) => {
        e.preventDefault();

        try {
            await apiClient.resetPassword(email); // TODO handle response by success/errors
            setIsSuccessfullySubmitted(true);
        } catch (err) {
            console.log(err);
            dispatch(actions.showSnackbar({type: SNACKBAR_TYPE_DANGER, message: t('messages.error')}));
        }
    };

    return (
        <Page title={''}>
            <div className={styles.container}>
                <div className={styles.row}>
                    <LoginSidePanel />

                    {isSuccessfullySubmitted
                        ? <Panel title={t('success')}>
                            <p className={styles.info}>{t('new_password_info')}</p>
                        </Panel>
                        : <Panel title={t('reset_password')} isForm={true}>
                            <form onSubmit={handleSubmit}>
                                <Input className={`${styles.inputWrap} ${styles.inputWrapEmail}`} label={t('email')}>
                                    <input
                                        type="email"
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Input>

                                <p className={styles.info}>{t('new_password_info_secondary')}</p>

                                <div className={styles.actions}>
                                    <Button type="submit">{t('reset')}</Button>
                                </div>
                            </form>
                        </Panel>
                    }
                </div>
            </div>

        </Page>

    );
};

export default ResetPassword;