import styles from './style.module.css';

import {forwardRef, useEffect, useRef} from 'react';
import {useTable, useRowSelect, useSortBy} from 'react-table';

interface ThisComponentProps {
    id?: string,
    columns: FixType[],
    data: Record<string, string|number>[],
    onRowClick?(...numbers: FixType[]): FixType,
    onRowSelect?(something: FixType): FixType,
    selectable?: boolean,
}

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }: FixType, ref) => {
        const defaultRef = useRef<HTMLInputElement>();
        const resolvedRef: FixType = ref || defaultRef;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return <input type="checkbox" ref={resolvedRef} onClick={(e) => e.stopPropagation()} {...rest} />;
    }
);

const SortTable = ({id, data, columns, onRowClick, onRowSelect, selectable = false}: ThisComponentProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        useRowSelect,
        !selectable ? () => {/* dummy */} : (hooks) => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    style: {
                        whiteSpace: 'nowrap',
                        width: '1%',
                    },
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }: FixType) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ]);
        }
    );

    useEffect(
        () => onRowSelect && onRowSelect(selectedFlatRows.map((d: FixType) => d.original)),
        [onRowSelect, selectedFlatRows],
    );

    return (
        <table id={id} className={styles.sortTable} {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            // eslint-disable-next-line react/jsx-key
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} style={(column as FixType).style}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(
                    (row) => {
                        prepareRow(row);
                        return (
                            // eslint-disable-next-line react/jsx-key
                            <tr {...row.getRowProps()} onClick={() => onRowClick && onRowClick(row.original)}
                                className={(row.original as FixType).isEven === true ? styles.isEven : ''}
                            >
                                {row.cells.map((cell) => {
                                    // eslint-disable-next-line react/jsx-key
                                    return <td {...cell.getCellProps()} style={(cell.column as FixType).style}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );}
                )}
            </tbody>
        </table>
    );
};

export default SortTable;
