import './App.css';

import {useEffect, Suspense} from 'react';
import {Outlet, Route, Routes} from 'react-router-dom';

import Menu from './component/Menu';
import HomePage from './page/Home';
import CardPage from './page/Card';
import DynamicPage from './page/Dynamic';
import SettingsPage from './page/Settings';
import LoginPage from './page/Login';
import RegisterPage from './page/Register';
import ResetPasswordPage from './page/ResetPassword';
import ReportPage from './page/Report';
import {PrivateRoute, useAuth} from './module/auth';
import UserPanel from './component/UserPanel';
import apiClient from './service/apiClient';
import {useAppDispatch} from './module/redux/hooks';
import {actions} from './page/Settings/Profile/slice';
import MyOffice from './page/MyOffice';
import Loader from './component/Loader';

function App() {
    const { auth, setAuth } = useAuth();
    const dispatch = useAppDispatch();

    const fetchUser = async () => {
        const user = await apiClient.getUser();

        dispatch(actions.setUser(user));

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setAuth({...auth!, user});
    };

    useEffect(() => {
        if (auth && !auth.user) {
            fetchUser();
        }
    }, [auth?.user]);

    const AppLayout = () => {
        return (
            <div className="App">
                <Menu/>
                <UserPanel/>
                <Outlet />
            </div>
        );
    };

    return (
        <Suspense fallback={<Loader/>}>
            <Routes>
                <Route path="*" element={<AppLayout/>}/>

                <Route element={<AppLayout />}>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/register" element={<RegisterPage/>}/>
                    <Route path="/reset-password" element={<ResetPasswordPage/>}/>

                    <Route element={<PrivateRoute allowedRoles={[]}/>}>
                        <Route index element={<HomePage/>}/>

                        <Route path="/card/*" element={<CardPage/>}/>

                        <Route path="/dynamic" element={<DynamicPage/>}/>

                        <Route path="/my-office/*" element={<MyOffice/>}/>

                        <Route path="/settings/*" element={<SettingsPage/>}/>
                    </Route>
                </Route>

                <Route path="/report/:uri" element={<ReportPage />}/>
            </Routes>
        </Suspense>
    );
}

export default App;
