import styles from './style.module.css';

import {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface ComponentProps {
    value: number;
    onChange(e: ChangeEvent<FixType>): void;
}

export const MIN_FEELING_INDICATOR = {
    Terrible: -1,
    Bad: -0.6,
    Normal: -0.2,
    Good: 0.2,
    Excellent: 0.65
};

export type KeyFeelingIndicator = keyof typeof MIN_FEELING_INDICATOR;

const RANGE_STEP = 0.05;
const RANGE_MIN = -1;
const RANGE_MAX = 1;

const FeelingInputRange = ({value, onChange}: ComponentProps) => {
    const { t } = useTranslation();
    const [className, setClassName] = useState<string>();

    useEffect(() => {
        const newFeeling = (Object.keys(MIN_FEELING_INDICATOR) as KeyFeelingIndicator[]).reduce((previousValue: KeyFeelingIndicator, currentValue: KeyFeelingIndicator) =>
            value >= MIN_FEELING_INDICATOR[previousValue] && value < MIN_FEELING_INDICATOR[currentValue] ? previousValue : currentValue
        );

        setClassName(newFeeling);
    }, [value]);

    return (
        <label className={styles.rangeWrapper}>
            <span className={styles.label}>{t('feeling')}</span>
            <input type={'range'} min={RANGE_MIN} max={RANGE_MAX} step={RANGE_STEP} value={value} onChange={onChange} className={`${styles.range} ${className ? styles[className] : null}`}/>
            <div className={styles.rangeLabels}>
                <span>{t('feeling.bad')}</span>
                <span>{t('feeling.normal')}</span>
                <span>{t('feeling.excellent')}</span>
            </div>
        </label>
    );
};

export default FeelingInputRange;