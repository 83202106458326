import styles from './style.module.css';

import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const LoginSidePanel = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.panel}>
            <div>
                <div className={styles.logoWrapper} title={t('kmr_title')} />

                <h1 className={styles.title}>{t('Какой-нибудь мотивирующий текст lorem ipsum')}</h1>
                <p className={styles.subtitle}>{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.')}</p>
            </div>
            <div>
                <ul className={styles.socials}>
                    <li>
                        <a href="#" target={'_blank'} className={`${styles.socialsLink} ${styles.google}`} />
                    </li>
                    <li>
                        <a href="#" target={'_blank'} className={`${styles.socialsLink} ${styles.facebook}`} />
                    </li>
                    <li>
                        <a href="#" target={'_blank'} className={`${styles.socialsLink} ${styles.linkedin}`} />
                    </li>
                </ul>

                <nav className={styles.nav}>
                    <NavLink className={styles.navLink} to={'/contacts'}>{t('contacts')}</NavLink>
                    <NavLink className={styles.navLink} to={'/about'}>{t('about')}</NavLink>
                    <NavLink className={styles.navLink} to={'/faq'}>{t('faq')}</NavLink>
                </nav>
            </div>
        </div>
    );
};

export default LoginSidePanel;
