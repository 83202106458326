import styles from './style.module.css';

import {Fragment, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {genDates} from '../../service/util';
import dayjs from 'dayjs';

type ThisComponentProps = {
    data: WidgetDrugsData[];
    from: string;
    to: string;
}

const DrugsGridCell = ({records} : {records: WidgetDrugsData[]}) => {
    const m: WidgetDrugsData[] = [];
    const d: WidgetDrugsData[] = [];
    const e: WidgetDrugsData[] = [];

    for (const record of records) {
        const time = dayjs(record.date).format('HH:mm');

        if (time >= '04:00' && time < '12:00') {
            m.push(record);
        } else if (time >= '12:00' && time < '20:00') {
            d.push(record);
        } else {
            e.push(record);
        }
    }

    return <>
        <td>
            {m.reduce((a, i) => a + i.value, 0) || ''}
        </td>
        <td>
            {d.reduce((a, i) => a + i.value, 0) || ''}
        </td>
        <td>
            {e.reduce((a, i) => a + i.value, 0) || ''}
        </td>
    </>;
};

const DrugsGrid = ({data, from = '2021-01-01', to = '2022-01-01'} : ThisComponentProps) => {
    const { t } = useTranslation();

    const dates: string[] = useMemo(() => genDates(from, to), [from, to]);
    const drugs: string[] = [...Array.from(new Set(data.map((i) => `${i.name}, ${t(i.measure)}`)))];

    return <div className={styles.wrap}>
        <div className={styles.label}>{t('medication')}</div>
        <div className={styles.tableWrap}>
            <table className={styles.table}>
                <tbody>
                    {drugs.map((drug) => <tr key={drug}>
                        <th>{drug}</th>
                        {dates.map((date, i) => <DrugsGridCell key={i} records={data.filter((i) => `${i.name}, ${t('mg')}` === drug && date === i.date.split('T')[0])} />)}
                        <td />
                    </tr>)}
                </tbody>
                <tfoot>
                    <tr>
                        <th />
                        {dates.map((date, i) => <Fragment key={i}>
                            <td><div>{t('morning')}</div><span>{date}</span></td>
                            <td><div>{t('afternoon')}</div></td>
                            <td><div>{t('evening')}</div></td>
                        </Fragment>)}
                        <td />
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>;
};

export default DrugsGrid;
