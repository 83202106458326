import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

import {useAppSelector} from '../../../module/redux/hooks';
import {getFeelingColorByValue, getFeelingGlyphByValue} from '../../../service/plotUtils';
import {CommonCard} from '../index';

const PRESSURE_FEATURE_SYST_ID = 3;
const PRESSURE_FEATURE_DIAST_ID = 4;
const FEELING_FEATURE_ID = 17;

const CardDiary = () => {
    const {t, i18n} = useTranslation();
    const {features} = useAppSelector(state => state.cardPage);

    const columns = useMemo(() => [
        {
            Header: t('date'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
            accessor: 'datetime_at',
            disableSortBy: true,
            Cell: ({value}: FixType) => dayjs(value).format('ll'),
        },
        {
            Header: t('time'),
            style: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
            Cell: ({row: {original: {datetime_at}}}: FixType) => {
                return dayjs(datetime_at).format('HH:mm');
            },
        },
        {
            Header: t('indicators'),
            accessor: 'values',
            disableSortBy: true,
            Cell: ({value}: FixType) => {
                if (!value) return '';

                const systRecordValue = value.find((i: RecordValue) => PRESSURE_FEATURE_SYST_ID === i.feature_id);
                const diastRecordValue = value.find((i: RecordValue) => PRESSURE_FEATURE_DIAST_ID === i.feature_id);

                const standardValues = value.filter((i: RecordValue) => ![PRESSURE_FEATURE_SYST_ID, PRESSURE_FEATURE_DIAST_ID].includes(i.feature_id))
                    .map((i: RecordValue) => {
                        const f = features[i.feature_id] || {};
                        const measure = i.measure ?? f.measure;

                        if (f.type === 'text' && i.value === '')
                            return null;

                        if (f.type !== 'boolean') {
                            let value = f.type === 'enum' ? t(i.value as string) : i.value;
                            if (i.feature_id === FEELING_FEATURE_ID) {
                                /** It works more stable than Math.round(x * 100) / 100 */
                                value = +(Math.round(+`${value ?? 0}e2`) + 'e-2');
                                const smile = <span style={{fontFamily: 'Glyphter', color: getFeelingColorByValue(value)}}>{getFeelingGlyphByValue(value)}</span>;
                                return <span key={i.id} style={{display: 'inline-flex', gap: '5px'}}>{t(f.name)}: {value} {smile}</span>;
                            } else {
                                return `${t(f.name)}: ${value}${measure !== '---' ? t(measure) : ''}`;
                            }
                        } else if (`${i.value}`.toLowerCase() === 'true') {
                            return t(f.name);
                        }

                        return null;
                    })
                    .filter(Boolean);

                if (systRecordValue && diastRecordValue) {
                    standardValues.unshift(`${t('pressure')}: ${systRecordValue.value} / ${diastRecordValue.value}`);
                }

                return standardValues.reduce((prev: FixType, curr: FixType) => {
                    if (typeof prev === 'string' && typeof curr === 'string') return [prev, curr].join(', ');
                    return [prev, ', ', curr];
                });
            },
        },
    ], [features, i18n.language]);

    return <CommonCard type={'diary'} columns={columns}></CommonCard>;
};

export default CardDiary;
